import { MDBIcon } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'

const HistoryFilter = ({ onFilter = () => {} }) => {
    const [filter, setFilter] = useState({
        fromDate: window.COMMON_DATE.addDaysFromNow(-365 * 2),
        toDate: new Date()
    })

    const handleChangeDateFilter = (valueDate, name) => {
        setFilter((prev) => ({ ...prev, [name]: valueDate }))
    }

    useEffect(() => {
        const filterMode = {
            fromDate: window.CONSTANT.FROM_FILTER_DATE,
            toDate: window.CONSTANT.TO_FILTER_DATE
        }
        onFilter({
            fromDate: window.COMMON_DATE.getStrDateFilter(filter.fromDate, filterMode.fromDate),
            toDate: window.COMMON_DATE.getStrDateFilter(filter.toDate, filterMode.toDate)
        })
    }, [filter])

    return (
        <div className="mt-2 p-3 bg-white rounded-lg wow fadeIn" data-wow-delay="0.3s">
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-2">
                    <h6 className="m-0" style={{ fontSize: '0.9rem', fontWeight: 700 }}>
                        <MDBIcon fa="true" icon="history" className="fa-fw" /> {window.I18N('deleted_history')}
                    </h6>
                </div>
                <div className="w-50 d-flex align-items-center gap-2">
                    <div className="flex-1 d-flex align-items-center px-2" style={{ gap: '16px' }}>
                        <div className="w-50 my-2">
                            <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                {`${window.I18N('from_date')}*`}
                            </h6>
                            <div className="input-group input-group-transparent">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                    </span>
                                </div>
                                <ReactDatePicker
                                    name="fromDate"
                                    placeholderText={window.I18N('from_date') + ' *'}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    selected={filter.fromDate}
                                    maxDate={new Date(filter.toDate)}
                                    onChange={(event) => handleChangeDateFilter(event, 'fromDate')}
                                    showYearDropdown
                                    showMonthDropdown
                                    required
                                />
                            </div>
                        </div>

                        <div className="w-50 my-2">
                            <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                {`${window.I18N('to_date')}*`}
                            </h6>
                            <div className="input-group input-group-transparent">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                    </span>
                                </div>
                                <ReactDatePicker
                                    name="toDate"
                                    placeholderText={window.I18N('to_date') + ' *'}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    selected={filter.toDate}
                                    minDate={new Date(filter.fromDate)}
                                    onChange={(event) => handleChangeDateFilter(event, 'toDate')}
                                    showYearDropdown
                                    showMonthDropdown
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HistoryFilter
