import React, { useLayoutEffect } from 'react';
import XLSX from 'xlsx';

import InputFile from './InputFile';

const ImportExcel = React.memo(function ImportExcel(props) {
  const data = [
    {
      key: 'IMPORT_USER',
      startRow : 5,
      rows : -1,
      startCol : 1,
      cols : 9,
      sheetIndex : 0,
      attrs: ['name', 'phone', 'email', 'gender', 'birthday', 'address', 'taxCode', 'bio', 'password']
    },
    {
      key: 'IMPORT_REWARD',
      startRow : 2,
      rows : -1,
      startCol : 1,
      cols : 3,
      sheetIndex : 0,
      attrs: ['rewardCode', 'code', 'seri']
    },
    {
      key: 'IMPORT_QUESTION',
      startRow : 2,
      rows : -1,
      startCol : 1,
      cols : 5,
      sheetIndex : 0,
      attrs: ['code', 'introduction', 'question', 'type', 'languageCode']
    },
    {
      key: 'IMPORT_ANSWER_QUESTION',
      startRow : 2,
      rows : -1,
      startCol : 1,
      cols : 5,
      sheetIndex : 0,
      attrs: ['questionCode', 'code', 'content', 'parentCode', 'languageCode']
    },
    {
      key: 'IMPORT_LANGUAGE',
      startRow : 2,
      rows : -1,
      startCol : 1,
      cols : 1,
      sheetIndex : 0,
      attrs: ['key']
    },
    {
      key: 'IMPORT_LOCATION',
      startRow : 2,
      rows : -1,
      startCol : 1,
      cols : 3,
      sheetIndex : 0,
      attrs: ['parent', 'code', 'name']
    },
    {
      key: 'IMPORT_QUESTION_LEARNING',
      startRow : 2,
      rows : -1,
      startCol : 1,
      cols : 6,
      sheetIndex : 0,
      attrs: ['code', 'question', 'answer', 'correctAnswer', 'description', 'languageCode']
    },
    {
      key: 'IMPORT_QUESTION_SURVEY',
      startRow : 2,
      rows : -1,
      startCol : 1,
      cols : 3,
      sheetIndex : 0,
      attrs: ['code', 'content', 'languageCode']
    },
    {
      key: 'IMPORT_ANSWER_SURVEY',
      startRow : 2,
      rows : -1,
      startCol : 1,
      cols : 5,
      sheetIndex : 0,
      attrs: ['questionCode', 'code', 'content', 'point', 'languageCode']
    },
    {
      key: 'IMPORT_RECOMMEND_SURVEY',
      startRow : 2,
      rows : -1,
      startCol : 1,
      cols : 6,
      sheetIndex : 0,
      attrs: ['code', 'content', 'description', 'min', 'max', 'languageCode']
    },
    {
      key: 'IMPORT_SUB_METADATA',
      startRow : 2,
      rows : -1,
      startCol : 1,
      cols : 4,
      sheetIndex : 0,
      attrs: ['code', 'value', 'description', 'languageCode']
    },
    {
      key: 'IMPORT_GROUP_QUESTION_SURVEY',
      startRow : 1,
      rows : -1,
      startCol : 0,
      cols : 9,
      sheetIndex : 0,
      attrs: ['category_title', 'categoryCode', 'questionCode', 'introduction', 'question', 'answers', 'scores', 'questionType', 'languageCode']
    },
    {
      key: 'IMPORT_ASSOCIATION_DATA',
      startRow : 0,
      rows : -1,
      startCol : 0,
      cols : props.configCols,
      sheetIndex : 0,
      attrs: props.configAttributes
    },
    {
      key: 'IMPORT_ASSOCIATION_DATA_CONFIG',
      startRow : 0,
      rows : -1,
      startCol : 0,
      cols : 2,
      sheetIndex : 0,
      attrs: ['key', 'data']
    },
  ];

  useLayoutEffect(() => {
    if (window.COMMON.checkValue(props.attrs) && props.attrs.length > 0 && props.dataKey === 'IMPORT_LANGUAGE') {
      const config = window.COMMON.getObjectInArrs(props.dataKey, data, 'key');
      props.attrs.forEach(item => {
        config.attrs.push(item);
        config.cols = props.attrs.length + 1;
      });
    }
  }, [props.attrs, props.dataKey, data]);

  const handleFile = (file) => {
    try {
      window.showLoading();
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = async function(e) {
        const bstr = e.target.result;
        const workbook = XLSX.read(bstr, {
          type: rABS ? 'binary' : 'array'
        });
        const config = window.COMMON.getObjectInArrs(props.dataKey, data, 'key');
        const sheetNames = workbook.SheetNames[config.sheetIndex];
        const result = {
          config : readHeader(workbook.Sheets[sheetNames], config),
          data: readData(workbook.Sheets[sheetNames], config),
          file
        };
        await props.handleFile(result);
      };
      if (rABS) {
        reader.readAsBinaryString(file);
      } else { 
        reader.readAsArrayBuffer(file); 
      }
      window.hideLoading();
    } catch (error) {
      window.COMMON.showErrorLogs('Excel.handleFile');
      window.hideLoading();
    }
  };

  const readHeader = (sheet, config) => {
    const result = [];
    try {
      const range = XLSX.utils.decode_range(sheet['!ref']);
      let cols = config.cols;
      if (cols === -1) {
        cols = range.e.c;
      }

      result.push(window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'));
      for (let i = 0; i < cols; i++) {
        const cell = sheet[XLSX.utils.encode_cell({ c: i + config.startCol, r: config.startRow })];
        let value = '';
        if (cell !== undefined) {
          value = cell.v.toString().trim();
        }
        if (config.attrs && config.attrs[i] !== '-MERGE-') {
          result.push(window.COMMON.createCol(value, 'auto', '', '', config.attrs[i], 'TEXT'));
        }
      }
      result.push(window.COMMON.createCol(window.I18N('action'), '5%', '', '', '-1', 'BUTTON'));
    } catch (error) {
      window.COMMON.showErrorLogs('Excel.readHeader');
    }
    return result;
  };

  const readData = (sheet, config) => {
    const result = [];
    try {
      const range = XLSX.utils.decode_range(sheet['!ref']);
      let rows = config.rows;
      if (rows === -1) {
        rows = range.e.r;
      }
      let cols = config.cols;
      if (cols === -1) {
        cols = range.e.c;
      }

      for (let i = 1; i <= rows; i++) {
        const obj = {
          isExisted: false
        };
        let index = 0;
        let check = false;
        for (let j = 0; j < cols; j++) {
          const cell = sheet[XLSX.utils.encode_cell({ c: j + config.startCol, r: i + config.startRow })];
          if (cell !== undefined) {
            if (cell.v.toString().trim()) {
              check = true;
            }
            obj[config.attrs[j]] = cell.v.toString().trim();
          } else if (config.attrs && config.attrs[j] !== '-MERGE-') {
            obj[config.attrs[j]] = '';
          } else {
            index++;
          }
        }
        if (index < cols && check) {
          result.push(obj);
        } else {
          if (config.rows === -1) {
            console.log('');
          }
        }
      }
    } catch (error) {
      window.COMMON.showErrorLogs('Excel.readData');
    }
    return result;
  };

  return (
    <InputFile id={props.id} color="" className={window.COMMON.getValue(props.classNameButton)} label={window.COMMON.getValue(props.label)} icon="upload" handleFile={handleFile.bind(this)} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" disabled={props.disabled}></InputFile>
  );
});

export default ImportExcel;