import React, { useState, useEffect } from 'react';
import { MDBCard, MDBCardBody } from 'mdbreact';
import AccountGRAPHQL from '../../../../graphql/Account';
import ItemPost from '../../../components/ItemPost';
const PostCard = ({ post }) => {
    const [account, setAccount] = useState(null);

    useEffect(() => {
        async function fetchAccount() {
            try {
                const variables = { id: post.createdBy._id };
                const res = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_ID, variables);
                if (res && res.data && res.data.findAccountById) {
                    setAccount(res.data.findAccountById);
                }
            } catch (error) {
                console.error('Error fetching account data', error);
            }
        }
        fetchAccount();
    }, [post.createdBy._id]);

    return (
        <MDBCard className="mb-3" key={post._id}>
            <MDBCardBody className="p-0">
                <ItemPost
                    item={{
                        ...post,
                        createdBy: {
                            ...post.createdBy,
                            avatar: account ? account.avatar : null,
                            name: account ? account.name : 'Loading...'
                        }
                    }}
                    key={post._id}
                    isView={true}
                    attr_id="_id"
                    attr_content="text"
                    attr_image="image"
                    attr_avatar="createdBy.avatar"
                    attr_name="createdBy.name"
                    attr_time="createdDate"
                />
            </MDBCardBody>
        </MDBCard>
    );
};

export default PostCard;
