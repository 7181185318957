import React, { useEffect, useRef } from 'react'
import RadioBox from '../../components/RadioBox'
import { useState } from 'react'
import AssociationData from './components/AssociationData'
import AssociationConfigs from './components/AssociationConfigs'

import ExportExcel from '../../components/ExportExcel'
import Modal from '../../components/Modal'
import Table from '../../components/Table'
import ImportReport from './components/ImportReport'
import { MDBBtn, MDBIcon } from 'mdbreact'
import AssociationHistory from './components/AssociationHistory/AssociationHistory'
import Pagination from '../../components/Pagination'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const ASSOCIATION_CONFIG_TAB = 0
const ASSOCIATION_DATA_TAB = 1
const ASSOCIATION_HISTORY_TAB = 2

const AssociationManagementPage = () => {
    const navigate = useHistory()
    const ACCOUNT_TYPE = localStorage.getItem(window.CONSTANT.ACCOUNT_TYPE)

    const DATA_TABS = [
        { value: ASSOCIATION_CONFIG_TAB, label: window.I18N('configs'), icon: 'cogs' },
        { value: ASSOCIATION_DATA_TAB, label: window.I18N('association_data_management'), icon: 'database' },
        { value: ASSOCIATION_HISTORY_TAB, label: window.I18N('deleted_history'), icon: 'history' }
    ]
    const [tab, setTab] = useState(ASSOCIATION_CONFIG_TAB)
    const handleChangeTab = (value) => {
        setTab(value)
        setKeysForDependencies([])
    }
    const [dataExport, setDataExport] = useState([])
    const [filters, setFilters] = useState({})
    const [paginationImport, setPaginationImport] = useState({
        page: 1,
        pageSize: 6,
        totalPages: 0,
        totalReports: 0,
        currentPage: 0
    })
    const [dataImport, setDataImport] = useState({})
    const [dataImportWithPagination, setDataImportWithPagination] = useState([])
    const [dataKeys, setDataKeys] = useState({})
    const [keysForDependencies, setKeysForDependencies] = useState([])
    const [allDependencies, setAllDependencies] = useState([])

    const associationDataRef = useRef()
    const associationConfigsRef = useRef()
    const exportRef = useRef()
    const importReportRef = useRef()

    //Change page
    const handleChangePage = (index) => {
        setPaginationImport((prev) => ({ ...prev, page: prev.currentPage + 1, currentPage: index }))
    }

    // Get list of dependencies
    const getListDependencies = async ({ category }) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category-deps/get-list-with-children`,
                method: 'GET',
                input: {
                    params: {
                        category
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationConfigs.getListDependencies')
                }
            })
            window.hideLoading()
            return result
        } catch (error) {
            console.error('🚀 ~ getListDependencies ~ error:', error)
        }
    }

    const handleGetFilterForExport = (filters) => {
        // Get filter for export
        setFilters(filters)
    }

    const getDataExportReports = async () => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report/get-reports`,
                method: 'GET',
                input: {
                    params: {
                        page: 1,
                        pageSize: 99999999999,
                        isExport: true,
                        ...filters
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationManagementPage.getDataExportReports')
                }
            })
            window.hideLoading()
            const { status, metadata } = result
            if (status === 200) {
                if (window.COMMON.isEmpty(metadata.reports)) {
                    window.COMMON.showMessage('warning', 'MSG_CODE_023', window.I18N('MSG_CODE_023'))
                    return
                }
                const modifiedDataReports = metadata.reports?.map((item) => ({
                    ...item,
                    date: window.COMMON_DATE.formatStringToDate(item.date),
                    ...item.mappingFields
                }))
                setDataExport(modifiedDataReports)
            }
            return result
        } catch (error) {
            console.error('🚀 ~ getDataExportReports ~ error:', error)
        }
    }

    useEffect(() => {
        if (!window.COMMON.isEmpty(dataExport)) {
            exportRef.current.handleClick()
        }
    }, [dataExport])

    useEffect(() => {
        if (tab === ASSOCIATION_DATA_TAB) {
            getListKeysReportCategory()
        }
    }, [tab])

    useEffect(() => {
        if (!window.COMMON.isEmpty(keysForDependencies) && tab === ASSOCIATION_DATA_TAB) {
            const getAllListDependencies = async () => {
                const requestsDependencies = keysForDependencies.map((item) => {
                    const [category] = item.dataId.split('-')
                    return getListDependencies({
                        category
                    })
                })
                const results = await Promise.all(requestsDependencies)
                const modifiedResults = results.map((item) => {
                    const data = item.metadata
                    if (!window.COMMON.isEmpty(data)) {
                        const configs = {
                            ref: data[0].children[0].category,
                            parent: data[0].category,
                            data: data.map((parent) => {
                                return {
                                    [parent.code]: parent.children.map((child) => {
                                        return child.code
                                    })
                                }
                            })
                        }
                        return {
                            ...configs,
                            data: configs.data.reduce((acc, curr) => {
                                return { ...acc, ...curr }
                            }, {})
                        }
                    }
                })
                setAllDependencies(modifiedResults.filter((item) => item))
            }
            getAllListDependencies()
        }
    }, [keysForDependencies, tab])

    const getListKeysReportCategory = async () => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category/find-dependencies`,
                method: 'GET',
                input: {
                    params: {}
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationConfigs.getListKeysReportCategory')
                }
            })
            window.hideLoading()
            if (result.status === 200) {
                const modifiedKeys = result.metadata
                setKeysForDependencies(modifiedKeys)
            }
        } catch (error) {
            console.error('🚀 ~ getListKeysReportCategory ~ error:', error)
        }
    }

    const fetchDataKeysReport = async () => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report/get-keys`,
                method: 'GET',
                input: {},
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AddKeysForm.fetchDataKeysReport')
                }
            })
            window.hideLoading()
            if (result.status === 200) {
                setDataKeys(result.metadata)
            }
            return result
        } catch (error) {
            console.error('🚀 ~ fetchDataKeysReport ~ error:', error)
        }
    }

    useEffect(() => {
        fetchDataKeysReport()
    }, [])

    const filterAndConvertFields = (obj) => {
        // Danh sách field mặc định
        const defaultFields = dataKeys?.reportDefaultKeys

        // Danh sách field cần convert sang number
        const fieldsToConvert = [
            'quantity',
            'unitPrice',
            'value',
            'vndExchangeRate',
            'valueInVnd',
            'usdExchangeRate',
            'valueInUsd',
            'importExportTaxBase',
            'importExportTax',
            'specialConsumptionTaxBase',
            'specialConsumptionTax',
            'vatBase',
            'vat',
            'surcharge',
            'taxExemption'
        ]

        // Object chứa các field hợp lệ và đã được convert
        const convertedObj = {}
        // Object chứa các field không nằm trong danh sách
        const mappingFields = {}

        // Duyệt qua tất cả các field trong object đầu vào
        Object.entries(obj).forEach(([key, value]) => {
            // Nếu field có trong danh sách mặc định
            if (defaultFields.hasOwnProperty(key)) {
                // Nếu field thuộc danh sách cần convert sang number
                if (fieldsToConvert.includes(key)) {
                    const convertedValue = Number(value)
                    // Chỉ gán nếu convert thành công (không phải NaN)
                    convertedObj[key] = !isNaN(convertedValue) ? convertedValue : value
                } else {
                    // Giữ nguyên nếu không cần convert
                    convertedObj[key] = value
                }
            } else {
                // Nếu field không thuộc danh sách mặc định => Đưa vào mappingFields
                if (key !== 'isExisted') {
                    mappingFields[key] = value === undefined ? '' : value
                }
            }
        })

        // Trả về object đã xử lý và thêm field mappingFields
        return {
            ...convertedObj,
            date: window.COMMON_DATE.convertToISOString(convertedObj.date),
            mappingFields
        }
    }

    const importReport = async () => {
        const dataForImportDB = dataImport.data.map((item) => filterAndConvertFields(item))
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report/import-report`,
                method: 'POST',
                input: {
                    data: { dataImport: dataForImportDB }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AddKeysForm.fetchDataKeysReport')
                }
            })
            window.hideLoading()
            if (result.status === 201) {
                window.COMMON.hideModal('#modal-import-report')
                window.COMMON.showMessage('success', 'MSG_CODE_016', window.I18N('MSG_CODE_016'))
                associationDataRef.current.refetchReports()
            }
            return result
        } catch (error) {
            console.error('🚀 ~ importReport ~ error:', error)
        }
    }

    const handleConfirmImportReport = () => {
        window.COMMON.showModal('#modal-save')
        window.saveMethod = importReport
    }

    const handleConfigExportReport = () => {
        const dependenciesConfigExport = allDependencies.reduce(
            (acc, dependency) => {
                const {
                    ref: { dataKey: refKey, name: refName },
                    parent: { dataKey: parentKey, name: parentName }
                } = dependency
                acc.headers.push(parentName, refName)
                acc.attrs.push(parentKey, refKey)
                return acc
            },
            {
                headers: [],
                attrs: []
            }
        )
        const headersConfig = Object.values(dataKeys?.reportDefaultKeys || {})
        const attrsConfig = Object.keys(dataKeys?.reportDefaultKeys || [])
        return {
            headers: [...headersConfig.slice(0, 12), ...dependenciesConfigExport.headers, ...headersConfig.slice(12)],
            attrs: [...attrsConfig.slice(0, 12), ...dependenciesConfigExport.attrs, ...attrsConfig.slice(12)]
        }
    }

    const handleExport = async () => {
        await getDataExportReports()
    }

    const handleDeleteDataImport = (index) => {
        const cloneDataImport = window.COMMON.deepCopyArray(dataImport.data)
        cloneDataImport.splice(index, 1)
        setDataImport((prev) => ({ ...prev, data: cloneDataImport }))
    }

    const handleUpdateReportWithNewDeps = (data, callback) => {
        importReportRef.current.handleUpdateDataWithNewDeps(data, callback)
    }

    const splitDataImportWithPagination = ({ page = 1, pageSize = 7 }) => {
        if (window.COMMON.isEmpty(dataImport?.data)) return []
        const cloneDataImport = window.COMMON.deepCopyArray(dataImport.data)
        const dataSliced = cloneDataImport.slice((page - 1) * pageSize, page * pageSize)
        setDataImportWithPagination(dataSliced)
    }

    useEffect(() => {
        if (!window.COMMON.isEmpty(dataImport?.data)) {
            splitDataImportWithPagination({
                page: paginationImport.currentPage + 1,
                pageSize: paginationImport.pageSize
            })
            setPaginationImport((prev) => ({
                ...prev,
                totalPages: Math.ceil(dataImport.data.length / prev.pageSize),
                totalReports: dataImport.data.length
            }))
        }
    }, [dataImport])

    useEffect(() => {
        if (!window.COMMON.isEmpty(dataImport?.data)) {
            const { currentPage, pageSize } = paginationImport
            splitDataImportWithPagination({ page: currentPage + 1, pageSize })
        }
    }, [paginationImport.currentPage])

    if (
        [
            window.CONSTANT.SUPER_ADMIN,
            window.CONSTANT.ADMIN,
            window.CONSTANT.COMPANY,
            window.CONSTANT.UNION,
            window.CONSTANT.GOPY_INFLUENCER,
            window.CONSTANT.INDIVIDUAL
        ].includes(ACCOUNT_TYPE)
    ) {
        navigate.push('/404')
        return null
    }

    return (
        <div>
            <div className="p-3 bg-white rounded-lg d-flex align-items-center justify-content-between">
                <RadioBox className="m-0" data={DATA_TABS} value={tab} handleChange={handleChangeTab} />
                {tab === ASSOCIATION_DATA_TAB && !window.COMMON.isEmpty(dataKeys) ? (
                    <div className="d-flex align-items-center justify-content-center" style={{ gap: '8px' }}>
                        <ImportReport
                            ref={importReportRef}
                            dataKeys={dataKeys}
                            setDataImport={setDataImport}
                            allDependencies={allDependencies}
                        />
                        <MDBBtn
                            color="primary"
                            className={`btn-sm m-0`}
                            onClick={handleExport}
                            style={{ padding: '8px 16px' }}
                        >
                            <MDBIcon fa="true" icon="download" className="fa-fw" /> {window.I18N('export')}
                        </MDBBtn>
                        <ExportExcel
                            ref={exportRef}
                            label={'Export Excel'}
                            className="btn-sm m-0"
                            data={dataExport}
                            isTemplate={false}
                            dataKey="ASSOCIATION_EXPORT"
                            configHeaders={handleConfigExportReport()?.headers}
                            configAttributes={handleConfigExportReport()?.attrs}
                            handleClick
                            style={{
                                display: 'none'
                            }}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
            {tab === ASSOCIATION_DATA_TAB && (
                <AssociationData
                    ref={associationDataRef}
                    dataKeys={dataKeys}
                    allDependencies={allDependencies}
                    onGetFilterReports={handleGetFilterForExport}
                    onUpdateReport={handleUpdateReportWithNewDeps}
                />
            )}
            {tab === ASSOCIATION_CONFIG_TAB && <AssociationConfigs ref={associationConfigsRef} />}
            {tab === ASSOCIATION_HISTORY_TAB && <AssociationHistory dataKeys={dataKeys} />}

            <Modal
                id="modal-import-report"
                className="modal-xl"
                title={window.I18N('import_data')}
                saveEvent={handleConfirmImportReport}
                saveBtnProps={{
                    disabled: false
                }}
                styleBody={{
                    position: 'relative'
                }}
            >
                <>
                    <div style={{ width: '800%' }}>
                        <Table
                            id="table-import-report"
                            className="table"
                            config={dataImport.config}
                            data={dataImportWithPagination}
                            handleRowDelClick={handleDeleteDataImport}
                            isImported={true}
                        />
                    </div>
                    <div
                        className="w-full mt-2 p-3 bg-white rounded-lg wow fadeIn"
                        data-wow-delay="0.3s"
                        style={{
                            position: 'sticky',
                            bottom: 0,
                            left: '20px'
                        }}
                    >
                        <Pagination
                            total={paginationImport.totalReports}
                            pageSize={paginationImport.pageSize}
                            currentPage={paginationImport.currentPage}
                            changePage={handleChangePage}
                        />
                    </div>
                </>
            </Modal>
        </div>
    )
}

export default AssociationManagementPage
