import gql from 'graphql-tag';

const QUERY_SEARCH = gql`
  query($text: String!, $pageSize: Int, $page: Int, $company: String) {
    search(
      text: $text
      pageSize: $pageSize
      page: $page
      company: $company
    ) {
      type
      post {
        _id
        text
        image
        video
        like { _id }
        dislike { _id }
        bookmark { _id }
        answer { _id }
        answerUser { _id }
        gopy
        status
        statusReview
        type
        comment { _id }
        isPublic
        isPublicMedia
        people { _id }
        emotion { _id }
        background { _id }
        tag { _id }
        extractURL { url }
        postParent { _id }
        fanpage { _id }
        createdDate
        createdBy { _id }
        updatedDate
        reviewDate
        updatedBy { _id }
        reviewBy { _id }
      }
      account {
        _id
        name
        nickname
        gopy
        avatar
        email
        phone
        accountType
        union { _id }
        company { _id }
        influencer { _id }
        companies { _id }
        unions { _id }
        companyType { _id }
        companySize { _id }
        companyIndustry { _id }
        mainProducts { _id }
        mainIndustries { _id }
        mainCustomers { _id }
        markets { _id }
        establishedDate
        vaccine { _id }
        medical { _id }
        color
        risk
        address
        lat
        lng
        taxCode
        organizationCode
        bio
        birthday
        gender
        zipcode
        website
        representative
        representativeEmail
        representativePhone
        representativePosition
        nation { _id }
        state { _id }
        city { _id }
        relationship
        status
        friendStatus
        receiver
        createdDate
        createdBy
        active
        role { _id }
        package { _id }
        maritalStatus
        educationBackground
        income
        university
        universityMajor
        highSchool
        highSchoolPeriod
        secondarySchool
        secondarySchoolPeriod
      }
      groupQuestion {
        _id
        code
        type
        finalConclusion { __typename }
        name { text }
        description { text }
        image
        hashtag
        isShared
        company { _id }
        countSchedule
        createdDate
        createdBy { _id }
        standardOfConclusion { __typename }
        isPublic
        publicViewCount
        publicDoneCount
      }
      survey {
        _id
        code
        content { text }
        description { text }
        status
        isMulti
        createdDate
        createdBy { _id }
      }
    }
  }
`;

const SearchGRAPHQL = {
    QUERY_SEARCH,
};

export default SearchGRAPHQL;
