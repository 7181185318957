import React, { useImperativeHandle } from 'react'
import levenshtein from 'fast-levenshtein'
import ImportExcel from '../../../components/ImportExcel'

const ImportReport = ({ dataKeys = {}, setDataImport = () => {}, allDependencies }, ref) => {
    function similarityPercentage(str1, str2) {
        const maxLength = Math.max(str1.length, str2.length)
        const distance = levenshtein.get(str1, str2) // Dùng fast-levenshtein
        return ((maxLength - distance) / maxLength) * 100
    }

    function cleanString(str) {
        return str.replace(/[-\s]/g, '').toLowerCase() // Xóa khoảng trắng, dấu gạch ngang
    }

    function findBestMatch(longStr, shortStr, threshold = 60) {
        longStr = cleanString(longStr)
        shortStr = cleanString(shortStr)

        let bestMatch = ''
        let bestScore = 0
        let bestMatchLength = 0
        // Chỉ quét các đoạn có độ dài tương đương với shortStr
        for (let i = 0; i <= longStr.length - shortStr.length; i++) {
            const shortLength = shortStr.length
            const subStr = longStr.substring(i, i + shortLength)
            const score = similarityPercentage(subStr, shortStr)
            if (score > bestScore || shortStr.includes(subStr)) {
                bestScore = score
                bestMatch = subStr
                bestMatchLength = shortLength
            }
        }

        return { bestMatch, bestScore, bestMatchLength, isMatch: bestScore >= threshold }
    }

    function convertToTon(unit, value) {
        // Chuyển đổi về chữ thường để tránh lỗi khi truyền vào chữ hoa
        unit = unit.toLowerCase()
        // Định nghĩa tỷ lệ chuyển đổi
        const conversionRates = {
            g: 1000000,
            kg: 1000,
            pound: 2204.62
        }

        // Kiểm tra đơn vị có thuộc danh sách cần chuyển đổi không
        if (conversionRates[unit]) {
            return value / conversionRates[unit]
        }

        // Nếu không thuộc, trả về giá trị cũ
        return value
    }

    const convertExcelDateToJSDate = (excelSerialDate) => {
        const EXCEL_EPOCH = new Date(Date.UTC(1899, 11, 30)) // 30-12-1899
        const ONE_DAY = 24 * 60 * 60 * 1000
        let days = excelSerialDate

        const jsDate = new Date(EXCEL_EPOCH.getTime() + days * ONE_DAY)

        // Điều chỉnh lệch múi giờ
        const timezoneOffset = jsDate.getTimezoneOffset()
        jsDate.setMinutes(jsDate.getMinutes() + timezoneOffset)

        return jsDate.toISOString()
    }

    const handleFoundCode = ({ text = '', referenceList }) => {
        const cache = new Map() // Dùng Map để tránh tính toán trùng lặp

        const cleanedText = cleanString(text)
        const listCodeMatched = []

        for (const category in referenceList) {
            for (const refCode of referenceList[category]) {
                const cleanedRefCode = cleanString(refCode)

                const cacheKey = `${cleanedText}_${cleanedRefCode}`
                if (cache.has(cacheKey)) {
                    if (cache.get(cacheKey).isMatch) {
                        listCodeMatched.push({ ...cache.get(cacheKey), code: refCode })
                    }
                    continue // Nếu đã tính toán rồi thì bỏ qua
                }

                const foundCode = findBestMatch(cleanedText, cleanedRefCode)
                cache.set(cacheKey, foundCode)

                if (foundCode.isMatch) {
                    listCodeMatched.push({ ...foundCode, code: refCode, category })
                }
            }
        }

        return listCodeMatched.sort((a, b) => {
            if (b.bestScore !== a.bestScore) {
                return b.bestScore - a.bestScore
            }
            return b.bestMatchLength - a.bestMatchLength
        })
    }

    const handleFileImport = (dataImport, callback = () => {}) => {
        window.showLoading()
        const keysConfig = allDependencies.map((dependency) => {
            const {
                ref: { dataKey: refKey, name: refName },
                parent: { dataKey: parentKey, name: parentName }
            } = dependency
            return [
                {
                    alignment: '',
                    attr: parentKey,
                    className: '',
                    colName: parentName,
                    type: 'TEXT',
                    width: 'auto'
                },
                {
                    alignment: '',
                    attr: refKey,
                    className: '',
                    colName: refName,
                    type: 'TEXT',
                    width: 'auto'
                }
            ]
        })

        const handleMappingFileImport = () => {
            const modifiedDataImport = dataImport.data.map((item) => {
                if (window.COMMON.isEmpty(allDependencies)) {
                    return {
                        ...item,
                        date: window.COMMON_DATE.formatStringToDate(convertExcelDateToJSDate(+item.date)),
                        enterpriseName: window.COMMON.formatTitleCase(item.enterpriseName),
                        quantity: convertToTon(item.unitOfMeasurement, item.quantity),
                        unitOfMeasurement: ['g', 'kg', 'Pound'].includes(item.unitOfMeasurement)
                            ? 'tấn'
                            : item.unitOfMeasurement
                    }
                }
                const foundCodeWithDeps = allDependencies.map((deps) => {
                    const {
                        ref: { refReportField, dataKey: refKey },
                        parent: { dataKey: parentKey },
                        data: referenceList
                    } = deps
                    const text = item[refReportField]
                    const foundCode = handleFoundCode({
                        text,
                        referenceList
                    }).sort((a, b) => b.bestScore - a.bestScore)
                    const matchCode = foundCode.find((grade) => grade.bestScore >= 85)
                    return {
                        ...item,
                        date: window.COMMON_DATE.formatStringToDate(convertExcelDateToJSDate(+item.date)),
                        enterpriseName: window.COMMON.formatTitleCase(item.enterpriseName),
                        quantity: convertToTon(item.unitOfMeasurement, item.quantity),
                        unitOfMeasurement: ['g', 'kg', 'Pound'].includes(item.unitOfMeasurement)
                            ? 'tấn'
                            : item.unitOfMeasurement,
                        [parentKey]: matchCode?.category,
                        [refKey]: matchCode?.code
                    }
                })
                const resolutionFoundCodes = foundCodeWithDeps.reduce((acc, curr) => {
                    return {
                        ...acc,
                        ...curr
                    }
                }, {})
                return resolutionFoundCodes
            })

            return modifiedDataImport
        }

        const handleAsyncMappingDataImport = window.COMMON.toAsyncFunction(handleMappingFileImport)

        handleAsyncMappingDataImport().then((mappingDataImport) => {
            if (!window.COMMON.isEmptyFunction(callback)) {
                const keysDependencies = keysConfig.flat().map((item) => item.attr)
                const dataForUpdate = mappingDataImport.map((item) => ({
                    _id: item._id,
                    mappingFields: keysDependencies.reduce((acc, key) => {
                        return { ...acc, [key]: item[key] === undefined ? '' : item[key] }
                    }, {})
                }))
                callback({ rootData: mappingDataImport, dataForUpdate, keysConfigTableDeps: keysConfig.flat() })
                window.hideLoading()
                return
            }
            setDataImport({
                ...dataImport,
                data: mappingDataImport,
                config: [...dataImport.config.slice(0, 12), ...keysConfig.flat(), ...dataImport.config.slice(12)]
            })
            window.COMMON.showModal('#modal-import-report')
            window.hideLoading()
        })
    }

    const handleConfigImportExcel = () => {
        // TODO: Implement config import excel
        return {
            configCols: dataKeys?.quantityKeys,
            configAttributes: Object.keys(dataKeys?.reportDefaultKeys || {})
        }
    }

    useImperativeHandle(ref, () => ({
        handleUpdateDataWithNewDeps: handleFileImport
    }))

    return (
        <ImportExcel
            label={window.I18N('btn_import')}
            classNameButton="mr-0"
            id="file-import"
            dataKey={'IMPORT_ASSOCIATION_DATA'}
            configCols={handleConfigImportExcel()?.configCols}
            configAttributes={handleConfigImportExcel()?.configAttributes}
            handleFile={handleFileImport}
        />
    )
}

export default React.forwardRef(ImportReport)
