
import React, { useState, useRef, useCallback, useLayoutEffect } from 'react';
import Select from 'react-select';
import { MDBInputGroup, MDBBtn, MDBIcon, MDBCol, MDBRow, MDBCardImage, MDBInput } from 'mdbreact';

import Modal from '../../components/Modal';
import RadioBox from '../../components/RadioBox';
import Pagination from '../../components/Pagination';

import CompanyGRAPHQL from '../../../graphql/Company';
import AccountGRAPHQL from '../../../graphql/Account';
import eventName from '../../../common/events';
import { useTracking } from 'react-tracking';

export default function RequestAccountPage(props) {
  const { trackEvent } = useTracking();

  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const COMPANY_ID = localStorage.getItem(window.CONSTANT.COMPANY_ID);
  const FULL_NAME = localStorage.getItem(window.CONSTANT.FULL_NAME);

  const types = [
    { value: 'INDIVIDUAL', label: window.I18N('individual') },
    { value: 'INFLUENCER', label: window.I18N('influencer') },
    { value: 'FRIEND', label: window.I18N('friends') }
  ];
  const status = [
    { value: 'OPEN', label: window.I18N('open') },
    { value: 'ACCEPTED', label: window.I18N('accepted') },
    { value: 'PENDING', label: window.I18N('pending') }
  ];

  const dataInfluencer = useRef([]);
  const [filter, setFilter] = useState({ text: '', company: null, type: window.CONSTANT.INDIVIDUAL, status: { value: 'OPEN', label: window.I18N('open') } });
  const [object, setObject] = useState({ index: -1, item: null });
  const [data, setData] = useState([]);
  const [dataSave, setDataSave] = useState({});
  const [options, setOptions] = useState({ company: [] });
  const [pagination, setPagination] = useState({ total: 0, pageSize: 10, currentPage: 0 });

  useLayoutEffect(() => {
    setTimeout(() => {
      getDataCompany();
      getDataInfluencer();
    }, 100);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line
  }, []);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      document.getElementById('btn-search').click();
    }
  };

  const handleFilterChange = (event) => {
    filter.text = event.target.value;
    setFilter({ ...filter });
  };

  const handleFilterSelect = (event, attr) => {
    filter[attr] = event;
    if (attr === 'company') {
      pagination.currentPage = 0;
      pagination.total = 0;
      setPagination(pagination);
    }
    setFilter({ ...filter });
    if (filter.type === 'INDIVIDUAL') {
      getDataIndividualRequest();
    } else if (filter.type === 'INFLUENCER') {
      getDataInfluencerAndRelationship();
    } else {
      getDataFriend();
    }
  };

  const doSearch = () => {
    pagination.currentPage = 0;
    pagination.total = 0;
    setPagination(pagination);
    getDataInfluencerAndRelationship();
  }

  const handleRadio = useCallback((value) => {
    filter.type = value;
    filter.text = '';
    pagination.currentPage = 0;
    pagination.total = 0;
    setPagination(pagination);
    setFilter({ ...filter });
    if (filter.type === 'INDIVIDUAL') {
      getDataIndividualRequest();
    } else if (filter.type === 'INFLUENCER') {
      getDataInfluencerAndRelationship();
    } else {
      getDataFriend();
    }
    // eslint-disable-next-line
  }, [filter]);
  

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      dataSave[event.target.name] = parseInt(event.target.value);
    } else {
      dataSave[event.target.name] = event.target.value;
    }
    setDataSave({ ...dataSave });
  };

  const changePage = useCallback((index) => {
      pagination.currentPage = index;
      setPagination({ ...pagination });
      // getDataCompanyAndRelationship();
      // eslint-disable-next-line
    }, [object, pagination, filter]);

  const getDataCompany = async () => {
    try {
      if (!window.COMMON.checkRoleIsSystem()) {
        filter.company = { value: COMPANY_ID, label: FULL_NAME, code: COMPANY_TYPE };
        setFilter({ ...filter });
        getDataIndividualRequest();
        return;
      }
      await window.COMMON.getDataCompany();
      const dataCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
      options.company = window.COMMON.createDataSelectWithGroup(dataCompany, 'id', 'name', 'accountType', 'accountType');
      filter.company = window.COMMON.getValueFromAttr(options.company, '0.options.0', null);  
      setOptions(options);
      setFilter({ ...filter }); 
      getDataIndividualRequest();
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.getDataCompany');
    }
  };

  const getDataInfluencer = async () => {
    try {
      const params = {
        type: window.CONSTANT.INFLUENCER
      };
      const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_COMPANY, params);
      if (result && result.data) {
        dataInfluencer.current = result.data.getCompanies;
      } 
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.getDataInfluencer');
    }
  };

  const getPackageIdOfCompany = (paramId) => {
    const allCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_COMPANY));
    return allCompany.find(el => el.company?._id === paramId)?.package._id
  }

  // REQUEST COMPANY
  const getDataIndividualRequest = async () => {
    try {
      if (filter.company !== null) {
        const params = {
          company: filter.company.value,
          type: filter.type,
          status: ['PENDING'],
          pageSize: pagination.pageSize,
          page: pagination.currentPage + 1
        };
        const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_RELATION_COMPANY_WITH_PAGINATION, params);
        let data = [];
        if (result && result.data && result.data.findRelationshipCompanyWithPagination) {
          data = window.COMMON.formatMultiDate(result.data.findRelationshipCompanyWithPagination.data, 'createdDate');
          data.forEach(item => {
            item.user.createdDate = window.COMMON_DATE.formatDate(item.createdDate);
            item.user.birthday = window.COMMON_DATE.formatDate(item.user.birthday);
          });
          pagination.total = result.data.findRelationshipCompanyWithPagination.total;
        }
        setPagination(pagination);
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.getDataIndividualRequest');
    }
  };

  const showItemDetails = useCallback((event, item) => {
    event.stopPropagation();
    object.item = item;
    setObject({ ...object });
    window.COMMON.showModal('#modal-detail');
  }, [object]);

  const acceptAccount = async (event, index) => {
    try {
      event.stopPropagation();
      const params = {
        input: {
          company: filter.company.value,
          companyName: filter.company.label,
          companyType: filter.company.code,
          type: filter.type,
          users: [window.COMMON.getValueIdFromAttr(data[index], 'user._id') || window.COMMON.getValueIdFromAttr(data[index], '_id')]
        }
      };
      const result = await window.COMMON.mutation(CompanyGRAPHQL.MUTATION_ACCEPT_COMPANY_RELATIONSHIP, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_APPROVE)) {
        if (params.input.type === 'INDIVIDUAL'){
          const paramPackage = {input: {organizationIds : params.input.company, packageId:""}}
          if (window.COMMON.checkRoleIsSystem()){
            paramPackage.input.packageId = getPackageIdOfCompany(params.input.company)
          } else {
            paramPackage.input.packageId = localStorage.getItem(window.CONSTANT.PACKAGE_ID)
          } 
          const resultSetPackage = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_SET_PACKAGE, paramPackage)
          if ( window.COMMON.checkResultData(resultSetPackage, window.CONSTANT.MODE_UPDATE)){
            createTrackingEvent(eventName.SET_PACKAGE);
          }
        }
        getDataIndividualRequest();
        createTrackingEvent(eventName.ACCEPT_COMPANY_RELATIONSHIP);
      }  
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.acceptAccount');
    }
  };

  const declineAccount = async (event, index) => {
    try {
      event.stopPropagation();
      const params = {
        input: {
          company: filter.company.value,
          companyName: filter.company.label,
          companyType: filter.company.code,
          type: filter.type,
          users: [window.COMMON.getValueIdFromAttr(data[index], 'user._id')]
        }
      };
      const result = await window.COMMON.mutation(CompanyGRAPHQL.MUTATION_DECLINE_COMPANY_RELATIONSHIP, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DECLINE)) {
        getDataIndividualRequest();
        createTrackingEvent(eventName.DECLINE_COMPANY_RELATIONSHIP);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.declineAccount');
    }
  };

  // REQUEST INFLUENCER
  const checkRequestFromCompany = (relationshipCompany, id) => {
    let relationId = null;
    let isRequestFromCompany = false;
    const arrs = relationshipCompany.filter(item => item.user._id === id)
    if (arrs && arrs.length > 0) {
      relationId = arrs[0]._id
      isRequestFromCompany = arrs[0].type === 'COMPANY'
    }
    return {
      relationId,
      isRequestFromCompany
    }
  }

  const getDataInfluencerAndRelationship = async () => {
    try {
      const params = {
        company: window.COMMON.getDataSelect(filter.company, false),
        status: window.COMMON.getDataSelect(filter.status, false),
        text: filter.text.trim(),
        pageSize: pagination.pageSize,
        page: pagination.currentPage + 1
      };
      const result = await window.COMMON.query(CompanyGRAPHQL.QUERY_INFLUENCER_AND_REALTIONSHIP, params);
      let data = [];
      let relationshipCompany = [];
      if (result && result.data && result.data.getInfluencersAndRelationshipCompany) {
        data = window.COMMON.formatMultiDate(result.data.getInfluencersAndRelationshipCompany.data, 'createdDate');
        relationshipCompany = result.data.getInfluencersAndRelationshipCompany.relationshipCompany;
        pagination.total = result.data.getInfluencersAndRelationshipCompany.total;
      }
      const modifiedData = data.map(item => ({
        ...item, 
        status: params.status,
        ...checkRequestFromCompany(relationshipCompany, item._id) }))
      setPagination(pagination);
      setData(modifiedData);
    } catch (error) {
      window.COMMON.showErrorLogs(`RequestAccountPage.getDataInfluencerAndRelationship ${error}`);
    }
  };

  const openSendRequestDialog = (event, index) => {
    event.stopPropagation();
    object.index = index;
    const obj = data[index];
    setObject(object);
    if (obj.status === 'PENDING' && !obj.isRequestFromCompany) {
      window.COMMON.showModal('#modal-save');
      window.saveMethod = () => acceptAccount(event, index);
      return
    }
    const dataSave = {
      gopy: 0
    };
    const influencer = obj?.influencer || {}
    if (influencer.gopy > 0 || influencer.subQuestion1 || influencer.subQuestion2 || influencer.subQuestion3) {
      dataSave.gopy = window.COMMON.getValueFromAttr(influencer, 'gopy', 0);
      dataSave.subQuestion1 = window.COMMON.getValueFromAttr(influencer, 'subQuestion1', '');
      dataSave.subQuestion2 = window.COMMON.getValueFromAttr(influencer, 'subQuestion2', '');
      dataSave.subQuestion3 = window.COMMON.getValueFromAttr(influencer, 'subQuestion3', '');
      dataSave.subAnswer1 = '';
      dataSave.subAnswer2 = '';
      dataSave.subAnswer3 = '';
      setDataSave({ ...dataSave });
      window.COMMON.showModal('#modal-update-gopy-and-sub-question');
    } else {
      setDataSave({ ...dataSave });
      window.COMMON.showModal('#modal-save');
      window.saveMethod = sendRequestInfluencerJoinCompany;
    }
  };

  const openDeleteRequestDialog = (event, index) => {
    event.stopPropagation();
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteInfluencerJoinCompany;
  };

  const sendRequestInfluencerJoinCompany = async () => {
    try {
      const obj = data[object.index];
      const params = {
        influencer: obj.influencer._id,
        company: window.COMMON.getDataSelect(filter.company, false),
        isInfluencer: false
      };
      const influencer = obj?.influencer || {}
      if (influencer.gopy > 0 || influencer.subQuestion1 || influencer.subQuestion2 || influencer.subQuestion3) {
        params.input = dataSave;
      }
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_REQUEST_INFLUENCER_JOIN_COMPANY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_APPROVE)) {
        getDataInfluencerAndRelationship();
        createTrackingEvent(eventName.REQUEST_INFLUENCER_JOIN_COMPANY);
        window.COMMON.hideModal('#modal-update-gopy-and-sub-question');
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.acceptAccount');
    }
  };

  const deleteInfluencerJoinCompany = async () => {
    try {
      const params = {
        id: data[object.index].relationId,
        companyType: filter.company && filter.company.code
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_DELETE_INFLUENCER_JOIN_COMPANY, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DECLINE)) {
        getDataInfluencerAndRelationship();
        createTrackingEvent(eventName.DELETE_INFLUENCER_JOIN_COMPANY);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.declineAccount');
    }
  };

  // REQUEST FRIEND
  const getDataFriend = async () => {
    try {
      const params = {
        pageSize: pagination.pageSize,
        page: pagination.currentPage + 1
      };
      const result = await window.COMMON.query(AccountGRAPHQL.QUERY_PENDING_FRIEND_BY_USER_WITH_FILTER, params);
      let data = [];
      if (result && result.data && result.data.findPendingFriendsByUserWithFilter) {
        data = window.COMMON.formatMultiDate(result.data.findPendingFriendsByUserWithFilter.data, 'createdDate');
        data.forEach(item => {
          item.createdBy.createdDate = window.COMMON_DATE.formatDate(item.createdDate);
          item.createdBy.birthday = window.COMMON_DATE.formatDate(item.createdBy.birthday);
        });
        pagination.total = result.data.findPendingFriendsByUserWithFilter.total;
      }
      setPagination(pagination);
      setData(data);
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.getDataFriend');
    }
  };

  const acceptFriend = async (event, index) => {
    try {
      event.stopPropagation();
      const params = {
        input: {
          id: window.COMMON.getValueIdFromAttr(data[index], 'createdBy._id'),
          name: localStorage.getItem(window.CONSTANT.FULL_NAME)
        }
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_ACCEPT_FRIEND, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        getDataFriend();
        createTrackingEvent(eventName.ACCEPT_FRIEND);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.acceptFriend');
    }
  };

  const declineFriend = async (event, index) => {
    try {
      event.stopPropagation();
      const params = {
        accountId: window.COMMON.getValueIdFromAttr(data[index], 'createdBy._id')
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_DECLINE_FRIEND, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataFriend();
        createTrackingEvent(eventName.DECLINE_FRIEND);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RequestAccountPage.declineFriend');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <MDBRow className="wow fadeIn animated" data-wow-delay="1s">
        <MDBCol>
          <ul className="list-group">
            <li className="list-group-item">
              <div className="d-flex align-items-center justify-content-end">
                {
                  !window.COMMON.checkRoleIsSystem() ? <div></div> : <div className="bd-highlight" style={{ width: '20%' }}>
                    <div className="input-group input-group-transparent" style={{ flexWrap: 'nowrap' }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MDBIcon fa="true" icon="building" className="fa-fw" />
                        </span>
                      </div>
                      <Select className="md-form m-0" value={filter.company} options={options.company} onChange={(event) => handleFilterSelect(event, 'company')} isSearchable/>
                    </div>
                  </div>
                }
                {
                  filter.type !== 'INFLUENCER' ? <div className="ml-auto"></div> : <div className="bd-highlight ml-auto mr-3" style={{ width: '30%' }}>
                    <MDBInputGroup type="text" value={filter.text} hint={window.I18N('search_name')} onChange={handleFilterChange} maxLength="100" pattern="\S(.*\S)?" required
                      append={
                        <span className="input-group-text cursor-pointer" id="btn-search" onClick={doSearch}>
                          <MDBIcon fa="true" icon="search" className="fa-fw"></MDBIcon>
                        </span>
                      }
                    />
                  </div>
                }
                {
                  filter.type !== 'INFLUENCER' ? <></> : <div className="mr-3" style={{ width: '20%' }}>
                    <div className="input-group input-group-transparent" style={{ flexWrap: 'nowrap' }}>
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <MDBIcon fa="true" icon="filter" className="fa-fw" />
                        </span>
                      </div>
                      <Select className="md-form m-0" value={filter.status} options={status} onChange={(event) => handleFilterSelect(event, 'status')} isSearchable/>
                    </div>
                  </div>
                }
                <div className="d-inline-block mr-0">
                  <RadioBox data={types} value={filter.type} className="m-0" handleChange={handleRadio}></RadioBox>
                </div>
              </div>
            </li>
            {
              filter.type !== 'INDIVIDUAL' ? <></> : data.map((item, i) => <li className="list-group-item" key={i}>
                <div className="d-flex align-items-center w-100">
                {
                  <div className="d-flex align-items-center w-100 pl-2 pr-2" onClick={(event) => showItemDetails(event, item.user)}>
                    <div className="w-100 mr-auto">
                      <MDBRow>
                        <MDBCol className="col-3">
                          <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'user.avatar'))} className="image-icon rounded-circle mr-2"/>
                          <span>{window.COMMON.getValueFromAttr(item, 'user.nickname')}</span>
                        </MDBCol>
                        <MDBCol className="col-3">
                          <span><MDBIcon className="fa-fw mr-1" fa="true" icon="transgender-alt"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'user.gender') === 0 ? window.I18N('female') : window.I18N('male')}</span><br></br>
                          <span><MDBIcon className="fa-fw mr-1" fa="true" icon="birthday-cake"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'user.birthday')}</span><br></br>
                        </MDBCol>
                        <MDBCol className="col-3">
                          <span className="text-muted">{window.I18N('request_date')}: {window.COMMON.getValueFromAttr(item, 'createdDate')}</span>
                        </MDBCol>
                        <MDBCol className="col-3">
                          <MDBBtn className="btn btn-sm mr-0 btn-list" type="button" color="danger" onClick= {(event) => declineAccount(event, i)}>
                            <MDBIcon fa="true" icon="times" className="fa-fw"></MDBIcon> {window.I18N('cancel')}
                          </MDBBtn>
                          <MDBBtn className="btn btn-sm mr-0 btn-list" type="button" color="success" onClick= {(event) => acceptAccount(event, i)}>
                            <MDBIcon fa="true" icon="check" className="fa-fw"></MDBIcon> {window.I18N('accept')}
                          </MDBBtn> 
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </div>
                }
                </div>
              </li>)
            }
            {
              filter.type !== 'INFLUENCER' ? <></> : data.map((item, i) => <li className="list-group-item" key={i}>
                <div className="d-flex align-items-center w-100">
                  <div className="w-100 pl-2 pr-2" onClick={(event) => showItemDetails(event, item)}>
                    <MDBRow>
                      <MDBCol className="col-3">
                        <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'avatar'))} className="image-icon rounded-circle mr-2"/>
                        <span>{window.COMMON.getValueFromAttr(item, 'name')}</span>
                      </MDBCol>
                      <MDBCol className="col-3">
                        <p className="mt-2 mb-0"><MDBIcon className="fa-fw mr-1" fa="true" icon="envelope"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'email')}</p>
                        <p className="mt-2 mb-2"><MDBIcon className="fa-fw mr-1" fa="true" icon="phone"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'phone')}</p>
                      </MDBCol>
                      <MDBCol className="col-4">
                        <p className="mt-2 mb-0"><i>{window.COMMON.getValueFromAttr(item, 'address')}</i></p>
                        <p className="mt-2 mb-2 text-muted">{window.I18N('start_date')}: {window.COMMON.getValueFromAttr(item, 'createdDate')}</p>
                      </MDBCol>
                      <MDBCol className="col-2 text-right">
                      {
                        window.COMMON.getDataSelect(filter.status, false) === 'PENDING' ? <>
                          {
                            !item.isRequestFromCompany ? <>
                              <MDBBtn className="btn btn-sm mt-2 mb-0 mr-0 btn-list" type="button" color="success" onClick= {(event) => openSendRequestDialog(event, i)}>
                                <MDBIcon fa="true" icon="check" className="fa-fw"></MDBIcon> {window.I18N('accept')}
                              </MDBBtn>
                              <MDBBtn className="btn btn-sm mt-2 mb-0 mr-0 btn-list" type="button" color="danger" onClick= {(event) => openDeleteRequestDialog(event, i)}>
                                <MDBIcon fa="true" icon="ban" className="fa-fw"></MDBIcon> {window.I18N('reject')}
                              </MDBBtn> 
                            </> : <MDBBtn className="btn btn-sm mt-2 mb-0 mr-0 btn-list" type="button" color="warning" onClick= {(event) => openDeleteRequestDialog(event, i)}>
                            <MDBIcon fa="true" icon="ban" className="fa-fw"></MDBIcon> {window.I18N('cancel')}
                          </MDBBtn> 
                          }
                        </>
                        : window.COMMON.getDataSelect(filter.status, false) === 'ACCEPTED' 
                          ? <MDBBtn className="btn btn-sm mt-2 mb-0 mr-0 btn-list" type="button" color="danger" onClick= {(event) => openDeleteRequestDialog(event, i)}>
                            <MDBIcon fa="true" icon="trash-alt" className="fa-fw"></MDBIcon> {window.I18N('delete')}
                          </MDBBtn>
                          : <MDBBtn className="btn btn-sm mt-2 mb-0 mr-0 btn-list" type="button" color="primary" onClick= {(event) => openSendRequestDialog(event, i)}>
                            <MDBIcon fa="true" icon="paper-plane" className="fa-fw"></MDBIcon> {window.I18N('request')}
                          </MDBBtn>
                      }
                      </MDBCol>
                    </MDBRow>
                  </div>
                </div>
              </li>)
            }
            {
              filter.type !== 'FRIEND' ? <></> : data.map((item, i) => <li className="list-group-item" key={i}>
                <div className="d-flex align-items-center w-100">
                {
                  <div className="d-flex align-items-center w-100 pl-2 pr-2" onClick={(event) => showItemDetails(event, item.createdBy)}>
                    <div className="w-100 mr-auto">
                      <MDBRow>
                        <MDBCol className="col-3">
                          <MDBCardImage zoom cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(item, 'createdBy.avatar'))} className="image-icon rounded-circle mr-2"/>
                          <span>{window.COMMON.getValueFromAttr(item, 'createdBy.nickname')}</span>
                        </MDBCol>
                        <MDBCol className="col-3">
                          <span><MDBIcon className="fa-fw mr-1" fa="true" icon="transgender-alt"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'createdBy.gender') === 0 ? window.I18N('female') : window.I18N('male')}</span><br></br>
                          <span><MDBIcon className="fa-fw mr-1" fa="true" icon="birthday-cake"></MDBIcon>{window.COMMON.getValueFromAttr(item, 'createdBy.birthday')}</span><br></br>
                        </MDBCol>
                        <MDBCol className="col-3">
                          <span className="text-muted">{window.I18N('request_date')}: {window.COMMON.getValueFromAttr(item, 'createdDate')}</span>
                        </MDBCol>
                        <MDBCol className="col-3">
                          <MDBBtn className="btn btn-sm mr-0 btn-list" type="button" color="danger" onClick= {(event) => declineFriend(event, i)}>
                            <MDBIcon fa="true" icon="times" className="fa-fw"></MDBIcon> {window.I18N('cancel')}
                          </MDBBtn>
                          <MDBBtn className="btn btn-sm mr-0 btn-list" type="button" color="success" onClick= {(event) => acceptFriend(event, i)}>
                            <MDBIcon fa="true" icon="check" className="fa-fw"></MDBIcon> {window.I18N('accept')}
                          </MDBBtn> 
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </div>
                }
                </div>
              </li>)
            }
            <li className="list-group-item pt-3">
              <Pagination total={pagination.total} pageSize={pagination.pageSize} currentPage={pagination.currentPage} changePage={changePage}></Pagination>
            </li>
          </ul>
        </MDBCol>
      </MDBRow>

      <Modal id="modal-detail" className="modal-lg" title={window.COMMON.getValueFromAttr(object.item, 'name')} hideSave={true}>
        <MDBRow>
          <MDBCol md="3" sm="4" xs="12" className="text-center">
            <MDBCardImage cascade waves src={window.COMMON.setValueImage(window.COMMON.getValueFromAttr(object.item, 'avatar'))} className="image-logo"/>
          </MDBCol>
          <MDBCol md="9" sm="8" xs="12">
            <table className="table table-view mb-0">
              {
                !object.item ? <tbody></tbody> : <tbody>
                  <tr>
                    <td width="30%">{window.I18N('name')}</td>
                    <td width="70%">{object.item.name}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('nickname')}</td>
                    <td>{object.item.nickname}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('email')}</td>
                    <td>{object.item.email}</td>
                  </tr>
                  <tr>
                    <td>{window.I18N('phone_number')}</td>
                    <td>{object.item.phone}</td>
                  </tr>
                  {
                    filter.type === window.CONSTANT.INFLUENCER ? <></> : <>
                      <tr>
                        <td>{window.I18N('gender')}</td>
                        <td>{object.item.gender === 0 ? window.I18N('female') : window.I18N('male')}</td>
                      </tr>
                      <tr>
                        <td>{window.I18N('birthday')}</td>
                        <td>{object.item.birthday}</td>
                      </tr>
                    </>
                  }
                </tbody>
              }
            </table>
          </MDBCol>
        </MDBRow>
      </Modal>

      <Modal id="modal-update-gopy-and-sub-question" className="modal-lg" title={window.I18N('create_update_data')} saveBtn={window.I18N('send')} saveEvent={sendRequestInfluencerJoinCompany}>
        <form id="form-update-gopy-and-sub-question" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <p className="mb-3"><strong>{window.I18N('need_gopies')}: </strong> <span className="font-weight-bold">{dataSave.gopy}</span> Gopies</p>
              {(dataSave.subQuestion1 || dataSave.subQuestion2 || dataSave.subQuestion3) &&
              <p className="mb-4">{window.I18N("question_privacy")}</p>
              }
              {
                dataSave.subQuestion1 ? <>
                  <p className="mb-2"><strong>{window.I18N('question')}: </strong> {dataSave.subQuestion1}</p>
                  <MDBInput outline containerClass="mt-0" value={dataSave.subAnswer1} name="subAnswer1" onChange={handleChange} type="textarea" label={window.I18N('answer')} maxLength="1000" pattern="\S(.*\S)?">
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                  </MDBInput>
                </> : <></>
              }
              {
                dataSave.subQuestion2 ? <>
                  <p className="mb-2"><strong>{window.I18N('question')}: </strong> {dataSave.subQuestion2}</p>
                  <MDBInput outline containerClass="mt-0" value={dataSave.subAnswer2} name="subAnswer2" onChange={handleChange} type="textarea" label={window.I18N('answer')} maxLength="1000" pattern="\S(.*\S)?">
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                  </MDBInput>
                </> : <></>
              }
              {
                dataSave.subQuestion3 ? <>
                <p className="mb-2"><strong>{window.I18N('question')}: </strong> {dataSave.subQuestion3}</p>
                  <MDBInput outline containerClass="mt-0 mb-0" value={dataSave.subAnswer3} name="subAnswer3" onChange={handleChange} type="textarea" label={window.I18N('answer')} maxLength="1000" pattern="\S(.*\S)?">
                    <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                  </MDBInput>
                </> : <></>
              }
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>
    </div>
  );
}