import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBIcon,
} from 'mdbreact';
import SearchGRAPHQL from '../../../graphql/Search';
import PostCard from './components/PostCard';
import AccountCard from './components/AccountCard';
import GroupQuestionCard from './components/GroupQuestionCard';
import SurveyCard from './components/SurveyCard';

const SearchResultsPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query') || '';

    const [results, setResults] = useState([]);
    const [activeCategory, setActiveCategory] = useState('post');

    useEffect(() => {
        async function fetchData() {
            try {
                const variables = { text: query, pageSize: 30, page: 1, company: null };
                const res = await window.COMMON.query(SearchGRAPHQL.QUERY_SEARCH, variables);
                if (res && res.data && res.data.search) {
                    setResults(res.data.search);
                }
            } catch (error) {
                console.error('Error fetching search data', error);
            }
        }
        if (query) {
            fetchData();
        }
    }, [query]);

    const filterResults = (type) => {
        return results.filter((item) => {
            if (type === 'post') return item.type === 'post';
            if (type === 'account') return item.type === 'account';
            if (type === 'groupQuestion') return item.type === 'group_question';
            if (type === 'survey') return item.type === 'survey';
            return false;
        });
    };

    const filtered = filterResults(activeCategory);

    return (
        <div className="p-3">
            <MDBRow>
                {/* Categories */}
                <MDBCol md="3">
                    <MDBCard className="mb-4">
                        <MDBCardBody>
                            <MDBCardTitle className="mb-3">Categories</MDBCardTitle>
                            <ul className="list-unstyled mb-0">
                                <li
                                    onClick={() => setActiveCategory('post')}
                                    style={{ cursor: 'pointer' }}
                                    className={`d-flex align-items-center p-2 mb-1 ${activeCategory === 'post'
                                        ? 'font-weight-bold text-dark'
                                        : 'text-muted'
                                        }`}
                                >
                                    <div style={{ width: '20px', textAlign: 'center', marginRight: '3px' }}>
                                        <MDBIcon icon="newspaper" />
                                    </div>
                                    <span>Posts</span>
                                </li>
                                <li
                                    onClick={() => setActiveCategory('account')}
                                    style={{ cursor: 'pointer' }}
                                    className={`d-flex align-items-center p-2 mb-1 ${activeCategory === 'account'
                                        ? 'font-weight-bold text-dark'
                                        : 'text-muted'
                                        }`}
                                >
                                    <div style={{ width: '20px', textAlign: 'center', marginRight: '3px' }}>
                                        <MDBIcon icon="user" />
                                    </div>
                                    <span>Accounts</span>
                                </li>
                                <li
                                    onClick={() => setActiveCategory('groupQuestion')}
                                    style={{ cursor: 'pointer' }}
                                    className={`d-flex align-items-center p-2 mb-1 ${activeCategory === 'groupQuestion'
                                        ? 'font-weight-bold text-dark'
                                        : 'text-muted'
                                        }`}
                                >
                                    <div style={{ width: '20px', textAlign: 'center', marginRight: '3px' }}>
                                        <MDBIcon icon="comments" />
                                    </div>
                                    <span>Group Questions</span>
                                </li>
                                <li
                                    onClick={() => setActiveCategory('survey')}
                                    style={{ cursor: 'pointer' }}
                                    className={`d-flex align-items-center p-2 mb-1 ${activeCategory === 'survey'
                                        ? 'font-weight-bold text-dark'
                                        : 'text-muted'
                                        }`}
                                >
                                    <div style={{ width: '20px', textAlign: 'center', marginRight: '3px' }}>
                                        <MDBIcon icon="clipboard-list" />
                                    </div>
                                    <span>Surveys</span>
                                </li>
                            </ul>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

                {/* Results */}
                <MDBCol md="6">
                    {filtered.length === 0 ? (
                        <p className="text-muted">No results found.</p>
                    ) : (
                        filtered.map((item, index) => {
                            if (activeCategory === 'post' && item.post) {
                                return <PostCard key={item.post._id} post={item.post} />;
                            }
                            if (activeCategory === 'account' && item.account) {
                                return <AccountCard key={item.account._id} account={item.account} />;
                            }
                            if (activeCategory === 'groupQuestion' && item.groupQuestion) {
                                return <GroupQuestionCard key={item.groupQuestion._id} groupQuestion={item.groupQuestion} />;
                            }
                            if (activeCategory === 'survey' && item.survey) {
                                return <SurveyCard key={`${item.survey._id}-${index}`} surveyItem={item.survey} />;
                            }
                            return null;
                        })
                    )}
                </MDBCol>
            </MDBRow>
        </div>
    );
};

export default SearchResultsPage;
