import React, { useState, useEffect } from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import moment from 'moment';
import AccountGRAPHQL from '../../../../graphql/Account';

const SurveyCard = (props) => {
    const survey = props.surveyItem;
    const [account, setAccount] = useState(null);

    const formattedDate = survey.createdDate
        ? moment(Number(survey.createdDate)).format('DD/MM/YYYY')
        : '';

    useEffect(() => {
        async function fetchAccount() {
            try {
                const variables = { id: survey.createdBy._id };
                const res = await window.COMMON.query(AccountGRAPHQL.QUERY_ACCOUNT_BY_ID, variables);
                if (res && res.data && res.data.findAccountById) {
                    setAccount(res.data.findAccountById);
                }
            } catch (error) {
                console.error('Error fetching account data', error);
            }
        }
        fetchAccount();
    }, [survey.createdBy._id]);

    return (
        <MDBCard className="mb-3">
            <MDBCardBody>
                <MDBCardTitle style={{ fontSize: '1.25rem' }}>
                    {survey.content && survey.content.length > 0 ? survey.content[0].text : 'No Title'}
                </MDBCardTitle>
                <MDBCardText>
                    {survey.description && survey.description.length > 0 && (
                        <p className="mb-1">
                            {survey.description[0].text}
                        </p>
                    )}
                </MDBCardText>
                <MDBRow className="mb-2 justify-content-around">
                    <MDBCol className="text-muted">
                        <MDBIcon fa="true" icon="qrcode" className="fa-fw mr-1" />
                        {survey.code}
                    </MDBCol>
                    <MDBCol className="text-center text-muted">
                        {formattedDate}
                    </MDBCol>
                    <MDBCol className="text-right text-muted">
                        {survey.createdBy._id && account?.name ? account.name : 'Unknown'}
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default SurveyCard;
