import React from "react";
import { MDBCardBody } from "mdbreact";
import SharedChartRendering from "./SharedChartRendering";

const CPUDetails = ({ data, timestamps, total }) => {
  return (
    <MDBCardBody>
      <h6
        className="m-0 font-weight-bold mb-3"
        style={{ fontSize: "1rem" }}
      >{`Memory Usage`}</h6>
      <SharedChartRendering
        data={data}
        timestamps={timestamps}
        maxYAxisValue={total}
        borderColor="#095bde"
        backgroundColor="rgba(201,225,255,0.4)"
      />
      <p></p>
    </MDBCardBody>
  );
};

export default CPUDetails;
