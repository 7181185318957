import React, { useState, useRef, useEffect } from "react";
import { MDBCol, MDBIcon, MDBRow, MDBCard } from "mdbreact";
import CPUDetails from "./components/CPUDetails";
import MemoryDetails from "./components/MemoryDetails";
import DiskDetails from "./components/DiskDetails";
import SharedChartRendering from "./components/SharedChartRendering";
import Modal from "../../components/Modal";
import moment from "moment";

const tabViewList = ["Disk", "CPU", "Memory"];

const defaultStatsValue = {
  cpu: [],
  cpuSpeed: [],
  memory: [],
  disk: [],
}

const MonitoringPage = () => {
  const [stats, setStats] = useState(defaultStatsValue);
  const [timestamps, setTimestamps] = useState([]);
  const [activeTab, setActiveTab] = useState(tabViewList[0]);
  const [logsData, setLogsData] = useState([]);
  const [latestLogItem, setLatestLogItem] = useState({});

  const resetData = () => {
    setLogsData([]);
    setStats(defaultStatsValue)
    setTimestamps([]);
    setLatestLogItem({});
  }

  const fetchLogData = async () => {
    window.showLoading();
    try {
      const logsData = await window.COMMON.httpRequest({
        url: `${process.env.REACT_APP_MONITORING_URL}/logs/env`,
        method: "GET",
        requireToken: true,
        handleError: () => {},
      });
      if(logsData?.length > 0) {
        setLogsData(logsData);
      } else {
        resetData()
      }
    } catch (error) {
      window.COMMON.showErrorLogs("MonitoringPage.fetchLogData", error);
    } finally {
      window.hideLoading();
    }
  };

  const getChartDataByTab = (tab) => {
    const map = {
      [tabViewList[0]]: stats.disk,
      [tabViewList[1]]: stats.cpu,
      [tabViewList[2]]: stats.memory,
    };
    return map[tab];
  };

  const getChartStyleByTab = (tab) => {
    const borderColorMap = {
      [tabViewList[0]]: "#638a23",
      [tabViewList[1]]: "#095bde",
      [tabViewList[2]]: "#006f91",
    };

    const backgroundColorMap = {
      [tabViewList[0]]: "rgba(216,244,181,0.4)a23",
      [tabViewList[1]]: "rgba(195,236,250,0.4)",
      [tabViewList[2]]: "rgba(195,236,250,0.4)",
    };

    return {
      borderColor: borderColorMap[tab],
      backgroundColor: backgroundColorMap[tab],
    };
  };

  const renderBriefByTab = (tab) => {
    let logMetadata = {};
    if (tab === tabViewList[0]) {
      logMetadata = latestLogItem.disk;
    }
    if (tab === tabViewList[1]) {
      logMetadata = latestLogItem.cpu;
    }
    if (tab === tabViewList[2]) {
      logMetadata = latestLogItem.ram;
    }

    if (logMetadata?.unit === "%") {
      return (
        <span style={{ fontSize: "0.9rem" }}>
          {logMetadata?.usage?.toFixed(1)}%{" "}
        </span>
      );
    }
    return (
      <span style={{ fontSize: "0.9rem" }}>
        {logMetadata?.usage?.toFixed(1)} /{" "}
        {logMetadata?.total?.toFixed(1)} GB{" "}
      </span>
    );
  };

  useEffect(() => {
    fetchLogData();

    const fetchStateInterval = setInterval(() => {
      fetchLogData();
    }
    // refresh every 1 minute
    , 60 * 1000);

    return () => clearInterval(fetchStateInterval);
  }, []);

  // {
  //   "_id": "67cf9561542ac2c5d183860e",
  //   "serverId": "10.0.0.10",
  //   "cpu": {
  //     "total": 11,
  //     "usage": 19,
  //     "unit": "%",
  //     "_id": "67cfab4e34f8ef58898638d0"
  //   },
  //   "ram": {
  //     "total": 19.33,
  //     "usage": 19.23,
  //     "unit": "GB",
  //     "_id": "67cfab4e34f8ef58898638d1"
  //   },
  //   "disk": {
  //     "total": 494.38,
  //     "usage": 409.18,
  //     "unit": "GB",
  //     "_id": "67cfab4e34f8ef58898638d2"
  //   },
  //   "date": "2025-03-11T01:44:01.008Z",
  //   "createdAt": "2025-03-11T01:44:01.009Z",
  //   "updatedAt": "2025-03-11T01:44:01.009Z",
  //   "__v": 0
  // }
  useEffect(() => {
    const dataToShow = [...logsData].reverse().slice(-10);
    const stats = {
      cpu: [],
      cpuSpeed: [],
      memory: [],
      disk: [],
    };
    dataToShow.forEach((item) => {
      stats.cpu.push(item.cpu?.usage);
      stats.memory.push(item.ram?.usage);
      stats.disk.push(item.disk?.usage);
    });

    setStats(stats);
    setTimestamps(dataToShow.map((item) => moment(item.createdAt).format("HH:mm DD/MM/YYYY")));

    const lastItem = dataToShow[dataToShow.length - 1];
    if (lastItem) {
      setLatestLogItem(lastItem);
    }
  }, [logsData, activeTab]);

  return (
    <div className="ml-3 wow fadeIn" data-wow-delay="0.2s">
      <MDBRow>

        <MDBCol className="px-2" size="12">
          {/* Summary */}
          <div className="bg-white rounded-lg p-3 mb-2">
            <div
              className="d-flex align-items-center justify-content-around"
              style={{ gap: "16px" }}
            >
              {tabViewList.map((tab) => (
                <div
                  key={"monitor-item" + "-" + tab}
                  className={
                    "p-2 list-category text-center rounded-lg flex-row" +
                    (activeTab === tab ? " category-active" : "")
                  }
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                  }}
                  onClick={() => setActiveTab(tab)}
                >
                  {/* MiniChart */}
                  <div>
                    <SharedChartRendering
                      data={getChartDataByTab(tab)}
                      timestamps={timestamps}
                      isMini={true}
                      borderColor={getChartStyleByTab(tab).borderColor}
                      backgroundColor={getChartStyleByTab(tab).backgroundColor}
                    />
                  </div>

                  {/* Brief Info */}
                  <div style={{ textAlign: "left" }}>
                    <h6
                      className="font-weight-bold"
                      style={{ fontSize: "1rem" }}
                    >
                      {tab}
                    </h6>

                    {renderBriefByTab(tab)}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Render chart */}
          <MDBRow>
            <MDBCol md="12">
              <MDBCard>
                <div
                  style={{
                    padding: "1% 0% 19% 1%",
                  }}
                >
                  {activeTab === tabViewList[0] && (
                    <DiskDetails
                      data={stats.disk}
                      timestamps={timestamps}
                      total={latestLogItem?.disk?.total}
                    />
                  )}
                  {activeTab === tabViewList[1] && (
                    <CPUDetails data={stats.cpu} timestamps={timestamps} />
                  )}
                  {activeTab === tabViewList[2] && (
                    <MemoryDetails
                      data={stats.memory}
                      timestamps={timestamps}
                      total={latestLogItem?.memory?.total}
                    />
                  )}
                </div>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default MonitoringPage;
