import { MDBInput } from 'mdbreact'
import React, { useEffect, useImperativeHandle, useState } from 'react'

const AssociationDataDetail = ({ dataReport = {}, dataKeys, allDependencies, onChangeData }, ref) => {
    const [dataUpdateReport, setDataUpdateReport] = useState({})

    useEffect(() => {
        if (!window.COMMON.isEmpty(dataReport)) {
            setDataUpdateReport(dataReport)
        }
    }, [dataReport])

    useEffect(() => {
        if (!window.COMMON.isEmpty(dataUpdateReport)) {
            const isChanged = window.COMMON.isObjectsDifferent(dataReport, dataUpdateReport)
            onChangeData(isChanged)
        }
    }, [dataUpdateReport])

    const createKeysForReport = (data) => {
        if (window.COMMON.isEmpty(data)) return
        let listKeysToCols = []
        Object.entries(data).forEach((item) => {
            const [key, name] = item
            listKeysToCols.push({ key, name })
        })

        const keysConfig = allDependencies.map((dependency) => {
            const {
                ref: { dataKey: refKey, name: refName },
                parent: { dataKey: parentKey, name: parentName }
            } = dependency
            return [
                {
                    key: parentKey,
                    name: parentName
                },
                {
                    key: refKey,
                    name: refName
                }
            ]
        })
        const configs = [...listKeysToCols, ...keysConfig.flat()]
        return configs
    }

    const handleChangeDataReport = (event) => {
        const { name, value } = event.target
        setDataUpdateReport((prev) => ({ ...prev, [name]: value }))
    }

    useImperativeHandle(ref, () => ({
        updateDataReport: async (callback = () => {}) => {
            const diffKeys = window.COMMON.isObjectsDifferent(dataReport, dataUpdateReport, true)
            if (window.COMMON.isEmpty(diffKeys)) {
                return
            }
            const dataForUpdate = diffKeys.reduce((acc, key) => {
                return { ...acc, [key]: dataUpdateReport[key] }
            }, {})

            try {
                window.showLoading()
                const result = await window.COMMON.httpRequest({
                    url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report/update-report`,
                    method: 'PATCH',
                    input: {
                        data: {
                            filter: {
                                _id: dataReport._id
                            },
                            dataUpdate: dataForUpdate
                        }
                    },
                    requireToken: true,
                    handleError: () => {
                        window.COMMON.showErrorLogs('AssociationDataDetail.updateDataReport')
                    }
                })
                window.hideLoading()
                const { status, metadata } = result
                if (status === 200) {
                    callback(metadata)
                    window.COMMON.showMessage('success', 'MSG_CODE_002', window.I18N('MSG_CODE_002'))
                }
            } catch (error) {
                console.error('🚀 ~ updateDataReport: ~ error:', error)
            }
        },
        deleteDataReport: async (callback = () => {}) => {
            try {
                window.showLoading()
                const result = await window.COMMON.httpRequest({
                    url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report/delete-report`,
                    method: 'PATCH',
                    input: {
                        data: {
                            _id: dataReport._id
                        }
                    },
                    requireToken: true,
                    handleError: () => {
                        window.COMMON.showErrorLogs('AssociationDataDetail.deleteDataReport')
                    }
                })
                window.hideLoading()
                const { status, metadata } = result
                if (status === 200) {
                    callback(metadata)
                    window.COMMON.showMessage('success', 'MSG_CODE_003', window.I18N('MSG_CODE_003'))
                }
            } catch (error) {
                console.error('🚀 ~ deleteDataReport: ~ error:', error)
            }
        }
    }))

    return (
        <div className="d-flex align-items-center gap-y-2" style={{ flexWrap: 'wrap' }}>
            {createKeysForReport(dataKeys.reportDefaultKeys).map((item, index) => (
                <div key={index} className="w-33 mt-2 px-2">
                    <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                        {window.I18N(`${item.key}`) !== item.key ? window.I18N(`${item.key}`) : item.name}
                    </h6>
                    <MDBInput
                        style={{ margin: 0 }}
                        outline
                        type="text"
                        name={item.key}
                        containerClass="w-full m-0 mt-2"
                        value={dataUpdateReport[item.key] || ''}
                        onChange={(e) => handleChangeDataReport(e)}
                        maxLength="100"
                        pattern="\S(.*\S)?"
                    />
                </div>
            ))}
        </div>
    )
}

export default React.forwardRef(AssociationDataDetail)
