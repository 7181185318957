import { MDBInput } from 'mdbreact'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import ReactSelect from 'react-select'

const AssociationDepsUpdateForm = (
    { onlyUpdateDependent = false, titleDeps = '', dataForEdit, dataDepsSelect = [], onChangedData },
    ref
) => {
    const [data, setData] = useState({})

    const handleChangeCode = (event) => {
        const { name, value } = event.target
        setData((prev) => ({ ...prev, [name]: value }))
    }

    const handleChangeSelect = (data) => {
        setData((prev) => ({ ...prev, dependent: data }))
    }

    const handleUpdateReportDeps = async () => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category-deps/update-category-deps`,
                method: 'PATCH',
                input: {
                    data: {
                        filter: {
                            _id: data.childId
                        },
                        data: onlyUpdateDependent
                            ? { code: data.code }
                            : {
                                  parent: data.dependent.value,
                                  code: data.code
                              }
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationConfigs.handleCheckImportDataConfigs')
                }
            })

            const { status, metadata } = result
            if (status === 200) {
                window.COMMON.showMessage('success', 'MSG_CODE_003', window.I18N('MSG_CODE_003'))
                window.COMMON.hideModal('#modal-confirm')
                window.hideLoading()
                return metadata
            }
            return result
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (!window.COMMON.isEmpty(dataForEdit)) {
            setData((prev) => ({
                ...prev,
                dependent: { label: dataForEdit.parentCode, value: dataForEdit.parentId },
                code: dataForEdit.code,
                childId: dataForEdit.childId
            }))
        }
    }, [dataForEdit])

    useEffect(() => {
        if (!window.COMMON.isEmpty(data)) {
            const isChangedData = () => {
                const dataCompare = {
                    parentCode: data?.dependent?.label,
                    code: data?.code,
                    childId: data?.childId,
                    parentId: dataForEdit.parentId
                }

                const isChanged = window.COMMON.isObjectsDifferent(dataForEdit, dataCompare)
                return isChanged
            }
            onChangedData(isChangedData())
        }
    }, [data])

    useImperativeHandle(ref, () => ({
        updateReportDeps: async (callback = () => {}) => {
            const result = await handleUpdateReportDeps()
            if (result) {
                callback()
            }
        }
    }))

    return (
        <div>
            {!onlyUpdateDependent && (
                <div>
                    <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                        {titleDeps}
                    </h6>
                    <ReactSelect
                        value={data.dependent}
                        options={dataDepsSelect}
                        className="md-form m-0 mt-2"
                        placeholder={window.I18N('choose_option')}
                        onChange={handleChangeSelect}
                    />
                </div>
            )}
            <div className="w-full mt-4">
                <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                    {`${window.I18N('code')} *`}
                </h6>
                <MDBInput
                    style={{ margin: 0 }}
                    outline
                    type="text"
                    name="code"
                    containerClass="w-full m-0 mt-2"
                    value={data.code}
                    onChange={(e) => handleChangeCode(e)}
                    maxLength="100"
                    pattern="\S(.*\S)?"
                    required
                />
            </div>
        </div>
    )
}

export default React.forwardRef(AssociationDepsUpdateForm)
