import React, { useState, useEffect } from 'react';
import { MDBCard, MDBCardBody } from 'mdbreact';
import moment from 'moment';
import ItemQA from '../../../components/ItemQA';
import QuestionGRAPHQL from '../../../../graphql/Question';

const GroupQuestionCard = ({ groupQuestion, language = 'en' }) => {
    const [showItemQA, setShowItemQA] = useState(false);
    const [dataQA, setDataQA] = useState([]);
    const [dataFeedback, setDataFeedback] = useState({});
    const formattedDate = groupQuestion.createdDate
        ? moment(Number(groupQuestion.createdDate)).format('DD/MM/YYYY HH:mm:ss')
        : '';

    useEffect(() => {
        if (showItemQA) {
            const fetchQAData = async () => {
                try {
                    const params = { groupId: groupQuestion._id };
                    const resultQuestion = await window.COMMON.query(QuestionGRAPHQL.QUERY_QUESTION, params);
                    const qaList = [];
                    if (resultQuestion && resultQuestion.data) {
                        resultQuestion.data.getQuestions.forEach(obj => {
                            qaList.push({
                                uuid: obj._id,
                                group: groupQuestion._id,
                                question: {
                                    _id: obj._id,
                                    question: window.COMMON.getDataWithLanguage(obj, 'question'),
                                    introduction: window.COMMON.getDataWithLanguageWithDefaultValue(obj, 'introduction', null),
                                    category: window.COMMON.getDataWithLanguageWithDefaultValue(obj, 'category', null),
                                    standardLowOfCategory: obj.standardLowOfCategory,
                                    standardOfCategory: obj.standardOfCategory,
                                    type: obj.type,
                                    code: obj.code,
                                    important: obj.important,
                                    createdDate: obj.createdDate
                                },
                                answers: []
                            });
                        });
                    }

                    const resultAnswer = await window.COMMON.query(QuestionGRAPHQL.QUERY_ANSWER_QUESTION, params);
                    if (resultAnswer && resultAnswer.data) {
                        resultAnswer.data.getAnswerQuestions.forEach(answerObj => {
                            const qa = qaList.find(item => item.uuid === answerObj.question);
                            if (qa) {
                                qa.answers.push({
                                    _id: answerObj._id,
                                    content: window.COMMON.getDataWithLanguage(answerObj, 'content'),
                                    code: answerObj.code,
                                    parentCode: answerObj.parentCode,
                                    question: answerObj.question
                                });
                            }
                        });
                    }
                    setDataQA(qaList);

                    if (groupQuestion.status) {
                        const paramsFeedback = { task: groupQuestion._id };
                        const resultFeedback = await window.COMMON.query(QuestionGRAPHQL.QUERY_FEEDBACK_BY_TASK, paramsFeedback);
                        const feedbackObj = {};
                        if (resultFeedback && resultFeedback.data && resultFeedback.data.findAnswerByTask) {
                            const feedbackData = window.COMMON.getValueFromAttr(resultFeedback.data.findAnswerByTask, 'feedback', []);
                            feedbackData.forEach(item => {
                                const obj = {
                                    answerText: item.answerText,
                                    note: item.note,
                                    documents: item.documents
                                };
                                if (item.question.type === 'RADIO_GRID' || item.question.type === 'CHECKBOX_GRID') {
                                    obj.count = 0;
                                    obj.answers = {};
                                    item.answers.forEach(element => {
                                        if (element.parentCode !== null) {
                                            if (!obj.answers[element.parentCode]) {
                                                obj.answers[element.parentCode] = [];
                                                obj.count++;
                                            }
                                            obj.answers[element.parentCode].push(element._id);
                                        }
                                    });
                                } else {
                                    obj.answers = [];
                                    item.answers.forEach(element => {
                                        obj.answers.push(element._id);
                                    });
                                }
                                feedbackObj[item.question._id] = obj;
                            });
                        }
                        setDataFeedback(feedbackObj);
                    }
                } catch (error) {
                    window.COMMON.showErrorLogs('GroupQuestionCard.fetchQAData');
                }
            };

            fetchQAData();
        }
    }, [groupQuestion, showItemQA]);

    const handleToggleQA = () => {
        if (!showItemQA) {
            setShowItemQA(prev => !prev);
        }
    };

    return (
        <MDBCard className="mb-3" key={groupQuestion._id}>
            <MDBCardBody onClick={handleToggleQA} style={{ cursor: 'pointer' }}>
                {showItemQA ? (
                    <ItemQA
                        group={groupQuestion}
                        dataFeedback={dataFeedback}
                        data={dataQA}
                        language={language}
                        isSelfAssessment={false}
                        groupData={groupQuestion}
                        isCompany={true}
                        handleSubmit={(feedbackData) => {
                            console.log('Submit feedback:', feedbackData);
                        }}
                        handleClose={() => {
                            console.log('Close ItemQA');
                            setShowItemQA(false);
                        }}
                    />
                ) : (
                    <>
                        <div className="mb-3">
                            <h6 className="font-weight-bold mb-0">
                                {groupQuestion.name && groupQuestion.name[0].text
                                    ? groupQuestion.name[0].text
                                    : 'No Title'}
                            </h6>
                            <small className="text-muted">{formattedDate}</small>
                        </div>
                        <div className="mb-3">
                            {groupQuestion.description &&
                                groupQuestion.description.length > 0 &&
                                groupQuestion.description.map((desc, index) => (
                                    <p key={index} className="mb-1">
                                        {desc.text}
                                    </p>
                                ))}
                        </div>
                        {groupQuestion.hashtag && groupQuestion.hashtag.length > 0 && (
                            <div className="mb-3">
                                {groupQuestion.hashtag.map((tag, index) => (
                                    <span key={index} className="badge badge-primary mr-1">
                                        {tag.text}
                                    </span>
                                ))}
                            </div>
                        )}
                        {groupQuestion.image && (
                            <div
                                style={{
                                    width: '100%',
                                    borderRadius: '4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginBottom: '15px'
                                }}
                            >
                                <img
                                    src={groupQuestion.image}
                                    alt={
                                        groupQuestion.name && groupQuestion.name[0].text
                                            ? groupQuestion.name[0].text
                                            : 'group question'
                                    }
                                    style={{
                                        maxWidth: '100%',
                                        objectFit: 'contain',
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}
            </MDBCardBody>
        </MDBCard>
    );
};

export default GroupQuestionCard;
