import React from "react";
import { MDBCardBody } from "mdbreact";
import SharedChartRendering from "./SharedChartRendering";

const CPUDetails = ({ data, timestamps, total, }) => {
  return (
    <MDBCardBody>
      <h6
        className="m-0 font-weight-bold mb-3"
        style={{ fontSize: "1rem" }}
      >{`Disk Usage`}</h6>
      <SharedChartRendering
        data={data}
        timestamps={timestamps}
        maxYAxisValue={total}
        borderColor="#638a23"
        backgroundColor="rgba(216,244,181,0.4)"
      />
      <p></p>
    </MDBCardBody>
  );
};

export default CPUDetails;
