import React, { useState, useCallback, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { MDBRow, MDBCol, MDBInput, MDBCardImage, MDBBtn, MDBIcon } from 'mdbreact';

import Panel from '../../components/Panel';
import Table from '../../components/Table';
import InputFile from '../../components/InputFile';
import ImportExcel from '../../components/ImportExcel';
import ExportExcel from '../../components/ExportExcel';
import Tab from '../../components/Tab';
import Modal from '../../components/Modal';

import RewardGRAPHQL from '../../../graphql/Reward';
import { useTracking } from 'react-tracking';
import eventName from '../../../common/events';
import SelectOrganization from '../../components/SelectOrganization/SelectOrganization';

function RewardPage(props) {
  const { trackEvent } = useTracking();
  const dataTabs = [
    { icon: 'fa-th', name: window.I18N('reward_category')},
    { icon: 'fa-th', name: window.I18N('reward') }
  ];

  const configCategory = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('icon'), '10%', '', '', 'image', 'IMAGE'),
    window.COMMON.createCol(window.I18N('category_name'), '35%', '', '', 'nameLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('code'), '10%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_by'), '20%', '', '', 'createdBy.name', 'TEXT'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
  ];
  const config = [
    window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
    window.COMMON.createCol(window.I18N('icon'), '10%', '', '', 'image', 'IMAGE'),
    window.COMMON.createCol(window.I18N('reward'), '25%', '', '', 'nameLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('code'), '10%', '', '', 'code', 'TEXT'),
    window.COMMON.createCol(window.I18N('category_name'), '20%', '', '', 'categoryLanguage', 'TEXT'),
    window.COMMON.createCol(window.I18N('status'), '10%', '', '', 'status', 'STATUS'),
    window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdDate', 'TEXT'),
    window.COMMON.createCol(window.I18N('action'), '10%', '', '', '2', 'BUTTON')
  ];
  const language = useRef();
  const [object, setObject] = useState({ index: -1, indexTab: 0, item: null });
  const [data, setData] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const [dataImport, setDataImport] = useState({});
  const [dataSave, setDataSave] = useState({});
  const [select, setSelect] = useState({});
  const [options, setOptions] = useState({
    category: []
  });

  const selectOrganizationRef = useRef()

  const handleRenderTabs = () => {
    return window.COMMON.isSuperAdmin() ? dataTabs : [dataTabs[1]]
  }

  useLayoutEffect(() => {
    if (!props.language.code) {
      return;
    }
    language.current = props.language.code;
    setTimeout(() => {
      getDataCategory();
      if (object.indexTab === 1 || !window.COMMON.isSuperAdmin()) {
        getDataReward();
      }    
    }, 100);
    // eslint-disable-next-line
  }, [props.language]);

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const changeTab = (index) => {
    if (index === 0) {
      setTimeout(() => getDataCategory(), 500);
    } else {
      setTimeout(() => getDataReward(), 500);
    }
    object.indexTab = index;
    setObject(object);
  };

  const handleChange = (event) => {
    if (event.target.className.indexOf('input-number') > -1) {
      const value = parseInt(event.target.value);
      if(!window.COMMON.validateNumberInput(value)){
        return
      }
      dataSave[event.target.name] = value
    } else {
      const text = event.target.value;
      if(!window.COMMON.validateTextInput(text)){
        return
      }
      dataSave[event.target.name] = text
    }
    setDataSave({ ...dataSave });
  };

  const handleChangeWithLanguage = (event) => {
    const { value } = event.target
    if(!window.COMMON.validateTextInput(value)){
      return
    }
    window.COMMON.setValueWithLanguage(dataSave, event.target.name, language.current, value);
    setDataSave({ ...dataSave });
  };

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    dataSave[attr] = event.value;
    setSelect(select);
    setDataSave({ ...dataSave });
  };

  const handleFile = (file) => {
    dataSave.file = file;
    dataSave.image = window.COMMON.createBlobImage(file);
    setDataSave({ ...dataSave });
  };

  const handleFileImport = (dataImport) => {
    window.COMMON.showModal('#modal-import');
    const colNames = ['no', 'reward_code', 'redemption_code', 'amount', 'action']
    const removeDuplicatesDataImport = window.COMMON.removeDuplicatesObjectInArray(dataImport.data, 'rewardCode') 
    setDataImport({ ...dataImport, data: removeDuplicatesDataImport, config: dataImport.config.map((item, index) => ({...item, colName: window.I18N(colNames[index])})) });
  };

  // CATEGORY
  const getDataCategory = async () => {
    try {
      window.resetDataTable('table-category');
      const params = {
        status: true
      };
      const result = await window.COMMON.query(RewardGRAPHQL.QUERY_CATEGORY_REWARD, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getCategoryRewards, 'createdDate');
        data.forEach(item => {
          item.nameLanguage = window.COMMON.getValueWithLanguage(item, 'name', language.current);
        });
        options.category = window.COMMON.createDataSelectWithLanguage(data, '_id', 'name', language.current, false);
        setOptions(options);
        setDataCategory(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RewardPage.getDataCategory');
    }
    window.initDataTable('table-category');
  };

  const openCategoryDialog = useCallback((index) => {
    window.COMMON.showModal('#modal-category');
    object.index = index;
    const dataSave = { file: null };
    if (index === -1) {
      dataSave.name = [];
      dataSave.code = window.COMMON.generateCode('CR');
      dataSave.image = '';
    } else {
      const obj = dataCategory[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getDataWithLanguage(obj, 'name');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.image = window.COMMON.getValueFromAttr(obj, 'image');
    }
    document.getElementById('file-category').value = null;
    setObject(object);
    setDataSave({ ...dataSave });
  }, [object, dataCategory]);

  // eslint-disable-next-line
  const openDeleteCategoryDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteCategory;
  });

  const saveCategory = async (event) => {
    let dataFiles = [];
    try {
      if (!window.COMMON.checkFormValidation('form-category', event)) {
        return;
      }
      // TODO upload file
      if (dataSave.file) {
        dataFiles = await window.uploadFile([dataSave.file]);
        if (dataFiles.length > 0) {
          dataSave.image = window.COMMON.getNewPathFile(dataFiles[0]._id);
        }
      }
      dataSave.file = null;
      // TODO save data
      const params = {
        input: dataSave
      };
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(RewardGRAPHQL.MUTATION_ADD_CATEGORY_REWARD, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataCategory();
		  createTrackingEvent(eventName.ADD_CATEGORY_REWARD);
        }
      } else {
        const result = await window.COMMON.mutation(RewardGRAPHQL.MUTATION_EDIT_CATEGORY_REWARD, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataCategory();
		  createTrackingEvent(eventName.EDIT_CATEGORY_REWARD);
        }
      }
      window.COMMON.hideModal('#modal-category');
    } catch (error) {
      console.error("🚀 ~ saveCategory ~ error:", error)
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))           
      window.COMMON.showErrorLogs('RewardPage.saveCategory');
    }
  };

  const deleteCategory = async () => {
    try {
      const params = {
        id: dataCategory[object.index]._id
      };
      const result = await window.COMMON.mutation(RewardGRAPHQL.MUTATION_DEL_CATEGORY_REWARD, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataCategory();
        createTrackingEvent(eventName.DEL_CATEGORY_REWARD);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RewardPage.deleteCategory');
    }
  };

  // REWARD
  const getDataReward = async () => {
    const organizationId = localStorage.getItem(window.CONSTANT.COMPANY_ID)
    try {
      window.resetDataTable('table-data');
      const params = {
        organization: window.COMMON.checkValue(organizationId) ? organizationId : null
      };
      const result = await window.COMMON.query(RewardGRAPHQL.QUERY_REWARD_ALL_STATUS, params);
      if (result && result.data) {
        const data = window.COMMON.formatDate(result.data.getRewardsAllStatus, 'createdDate');
        data.forEach(item => {
          item.nameLanguage = window.COMMON.getValueWithLanguage(item, 'name', language.current);
          item.categoryLanguage = window.COMMON.getValueWithLanguage(item, 'category.name', language.current);
        });
        setData(data);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RewardPage.getDataReward');
    }
    window.initDataTable('table-data');
  };

  const showContainerReward = useCallback((index) => {
    window.COMMON.showContainerSave();
    object.index = index;
    object.item = data[index];
    const dataSave = { file: null };
    if (index === -1) {
      dataSave.name = [];
      dataSave.title = [];
      dataSave.code = window.COMMON.generateCode('R');
      dataSave.image = '';
      dataSave.point = 0;
      dataSave.limit = 0;
      dataSave.description = [];
      dataSave.category = null;
      select.category = null;
    } else {
      const obj = data[index];
      dataSave._id = window.COMMON.getValueFromAttr(obj, '_id', null);
      dataSave.name = window.COMMON.getDataWithLanguage(obj, 'name');
      dataSave.code = window.COMMON.getValueFromAttr(obj, 'code');
      dataSave.title = window.COMMON.getDataWithLanguage(obj, 'title');
      dataSave.image = window.COMMON.getValueFromAttr(obj, 'image');
      dataSave.point = window.COMMON.getValueFromAttr(obj, 'point');
      dataSave.limit = window.COMMON.getValueFromAttr(obj, 'limit');
      dataSave.description = window.COMMON.getDataWithLanguage(obj, 'description');
      dataSave.category = window.COMMON.getValueIdFromAttr(obj.category, '_id');
      select.category = window.COMMON.setDataSelect(options.category, window.COMMON.getValueIdFromAttr(obj.category, '_id'));
      selectOrganizationRef.current.setDataSelectOutSide(window.COMMON.getValueFromAttr(obj, 'appliedOrganizations'))
    }
    document.getElementById('file-reward').value = null;
    setObject(object);
    setSelect(select);
    setDataSave({ ...dataSave });
  }, [data, object, options, select]);

  // eslint-disable-next-line
  const openDeleteRewardDialog = useCallback((index) => {
    object.index = index;
    setObject(object);
    window.COMMON.showModal('#modal-delete');
    window.deleteMethod = deleteReward;
  });

  const openSaveRewardDialog = (event) => {
    const checkForm = window.COMMON.checkFormValidation('form', event);
    const checkCategory = window.COMMON.checkSelectValidation('select-category', select.category);
    if (!checkForm || !checkCategory) {
      return;
    }
    window.COMMON.showModal('#modal-save');
    window.saveMethod = saveReward;
  };

  const saveReward = async () => {
    const organizationId = localStorage.getItem(window.CONSTANT.COMPANY_ID)
    let dataFiles = [];
    try {
      // TODO upload file
      if (dataSave.file) {
        dataFiles = await window.uploadFile([dataSave.file]);
        if (dataFiles.length > 0) {
          dataSave.image = window.COMMON.getNewPathFile(dataFiles[0]._id);
        }
      }
      dataSave.file = null;
      // TODO save data
      const params = {
        input: {
          ...dataSave,
          organization: window.COMMON.checkValue(organizationId) ? organizationId : null,
          appliedOrganizations: selectOrganizationRef.current.getDataSelect()
        }
      };  
      window.COMMON.trimData(params);
      if (object.index === -1) {
        const result = await window.COMMON.mutation(RewardGRAPHQL.MUTATION_ADD_REWARD, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          getDataReward();
          selectOrganizationRef.current.resetDataSelect()
          window.COMMON.backContainerData();
		      createTrackingEvent(eventName.ADD_REWARD);
        }
      } else {
        const result = await window.COMMON.mutation(RewardGRAPHQL.MUTATION_EDIT_REWARD, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          getDataReward();
          selectOrganizationRef.current.resetDataSelect()
          window.COMMON.backContainerData();
		      createTrackingEvent(eventName.EDIT_REWARD);
        }
      }
    } catch (error) {
      console.error("🚀 ~ saveReward ~ error:", error)
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))           
      window.COMMON.showErrorLogs('RewardPage.saveReward');
    }
  };

  const updateStatusReward = async () => {
    try {
      const params = {
        id: object.item._id,
        status: !object.item.status,
      };
      const result = await window.COMMON.mutation(RewardGRAPHQL.MUTATION_UPDATE_STATUS_REWARD, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
        getDataReward();
        window.COMMON.backContainerData();
        createTrackingEvent(eventName.UPDATE_STATUS_REWARD);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RewardPage.updateStatusReward');
    }
  };

  const deleteReward = async () => {
    try {
      const params = {
        id: data[object.index]._id
      };
      const result = await window.COMMON.mutation(RewardGRAPHQL.MUTATION_DEL_REWARD, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_DELETE)) {
        getDataReward();
        createTrackingEvent(eventName.DEL_REWARD);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('RewardPage.saveReward');
    }
  };

  // IMPORT
  const deleteImportRow = useCallback((index) => {
    if (dataImport.data.length > 0) {
      dataImport.data.splice(index, 1);
    }
    setDataImport({ ...dataImport });
  }, [dataImport]);

  const importData = async () => {
    if (dataImport.data.length === 0) {
      window.COMMON.showMessage('warning', 'MSG_CODE_021', window.MSG.MSG_CODE_021);
      return;
    }
    try {
      let dataImported = [];
      for (let i = 0; i < dataImport.data.length; i++) {
        const obj = dataImport.data[i];
        const item = {
          rewardCode: obj.rewardCode,
          code: obj.code,
          seri: obj.seri,
          expiredDate: null
          // expiredDate: window.COMMON_DATE.getStrDateFilter(window.COMMON_DATE.convertStringToDate(obj.expiredDate, '/'), 1)
        };
        const itemRules = {
          rewardCode: {
            required: {
              value: true,
              message: window.I18N('required_message', { field: window.I18N('reward_code') })
            }
          },
          code: {
            required: {
              value: true,
              message: window.I18N('required_message', { field: window.I18N('redemption_code') })
            }
          },
          seri: {
            required: {
              value: true,
              message: window.I18N('required_message', { field: window.I18N('amount') })
            },
            type: {
              value: 'string',
              message: window.I18N('required_type_message', { field: window.I18N('amount'), type: window.I18N('string') })
            }
          }
        }
        if(!window.COMMON.validation(item, itemRules)){
          return
        }
        dataImported.push(item);
      }
 
      const params = {
        input: dataImported
      };
      window.COMMON.trimData(params);
      const result = await window.COMMON.mutation(RewardGRAPHQL.MUTATION_ADD_CODE_REWARD, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_IMPORT)) {
        createTrackingEvent(eventName.ADD_CODE_REWARD);
      }
      window.COMMON.hideModal('#modal-import');
    } catch (error) {
      console.error("🚀 ~ importData ~ error:", error)
      window.COMMON.showErrorLogs('RewardPage.importData');
    }
  };

  if (typeof window === 'undefined') {
    return null;
  }
  return (
    <div className="container-row">
      <Tab data={handleRenderTabs()} id="container-data" changeTab={changeTab}>
        { window.COMMON.isSuperAdmin() && <div id="tab-1" className="tab-pane fade active show">
          <div className="container-btn mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
            <MDBBtn color="primary" onClick={() => openCategoryDialog(-1)}>
              <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
            </MDBBtn>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <Table id="table-category" className="table" config={configCategory} data={dataCategory} handleRowEditClick={openCategoryDialog} handleRowDelClick={openDeleteCategoryDialog}></Table>
          </div>
        </div>}
        <div id={!window.COMMON.isSuperAdmin() ? "tab-1" : "tab-2"} className={`tab-pane fade ${!window.COMMON.isSuperAdmin() ? 'active show' : ''}`}>
          <div className="container-btn mb-2 wow fadeInDown animated" data-wow-delay="0.5s">
            <ExportExcel label={window.I18N('btn_template')} isTemplate={true} dataKey="REWARD_TEMPLATE"></ExportExcel>
            <ImportExcel label={window.I18N('btn_import')} id="file-import" dataKey="IMPORT_REWARD" handleFile={handleFileImport}></ImportExcel>
            <MDBBtn color="primary" onClick={() => showContainerReward(-1)}>
              <MDBIcon fa="true" icon="plus-circle" className="fa-fw" /> {window.I18N('btn_add')}
            </MDBBtn>
          </div>
          <div className="container-table wow fadeIn animated" data-wow-delay="1s">
            <Table id="table-data" className="table" config={config} data={data} handleRowEditClick={showContainerReward} handleRowDelClick={openDeleteRewardDialog}></Table>
          </div>
        </div>
      </Tab>

      <Panel id="container-save" title={window.I18N('create_update_data')} icon="file" style={{ display: 'none' }}>
        <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
          <MDBBtn color="" onClick={window.COMMON.backContainerData}>
            <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
          </MDBBtn>
          {
            object.index === -1 ? <></> : object.item && object.item.status ? <MDBBtn color="danger" type="button" data-toggle="modal" data-backdrop="static" data-target="#modal-save" onClick={() => { window.saveMethod = updateStatusReward; }}>
              <MDBIcon fa="true" icon="ban" className="fa-fw" /> {window.I18N('btn_inactive')}
            </MDBBtn> : <MDBBtn color="success" type="button" data-toggle="modal" data-backdrop="static" data-target="#modal-save" onClick={() => { window.saveMethod = updateStatusReward; }}>
              <MDBIcon fa="true" icon="check" className="fa-fw" /> {window.I18N('btn_active')}
            </MDBBtn>
          }
          <MDBBtn color="primary" type="button" onClick={openSaveRewardDialog}>
            <MDBIcon fa="true" icon="save" className="fa-fw" /> {window.I18N('btn_save')}
          </MDBBtn>
        </div>
        <div className="container-top wow fadeInUp animated" data-wow-delay="0.5s">
          <form id="form" className="needs-validation" noValidate>
            <MDBRow>
              <MDBCol md="6">
                <Select id="select-category" value={select.category} options={options.category} placeholder={window.I18N('category') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-category', 'category')} isSearchable/>
                <SelectOrganization companies={dataSave.appliedOrganizations} ref={selectOrganizationRef} />
                <MDBInput outline value={dataSave.code} name="code" onChange={handleChange} type="text" label={window.I18N('code') + ' *'} maxLength="50" pattern="\S(.*\S)?" required>
                  <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                </MDBInput>
                <MDBInput outline type="text" label={window.I18N('reward_name') + ' *'} name="name" value={window.COMMON.getValueWithLanguage(dataSave, 'name', language.current)} onChange={handleChangeWithLanguage} maxLength="100" pattern="\S(.*\S)?" required>
                  <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                </MDBInput>
                <MDBInput outline type="text" label={window.I18N('title') + ' *'} name="title" value={window.COMMON.getValueWithLanguage(dataSave, 'title', language.current)} onChange={handleChangeWithLanguage} maxLength="200" pattern="\S(.*\S)?" required>
                  <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
                </MDBInput>
                <MDBInput outline type="textarea" label={window.I18N('description')} name="description" value={window.COMMON.getValueWithLanguage(dataSave, 'description', language.current)} onChange={handleChangeWithLanguage} maxLength="5000" pattern="\S(.*\S)?"></MDBInput>
              </MDBCol>
              <MDBCol md="6">
                <MDBInput outline value={dataSave.point} name="point" onChange={handleChange} type="number" className="input-number" label={window.I18N('point_exchange') + ' *'} maxLength="20" pattern="\S(.*\S)?" required></MDBInput>
                <MDBInput outline value={dataSave.limit} name="limit" onChange={handleChange} type="number" className="input-number" label={window.I18N('limit_exchange') + ' *'} maxLength="20" pattern="\S(.*\S)?" required></MDBInput>
                <div className="text-center">
                  <MDBCardImage className="img-fluid image-logo" src={window.COMMON.setValueImage(dataSave.image)} hover overlay="white-slight"/><br></br>
                  <InputFile id="file-reward" icon="upload" handleFile={handleFile} accept="image/*" description="Image (120 x 120) pixels"></InputFile>
                </div>
              </MDBCol>
            </MDBRow>
          </form>
        </div>
      </Panel>

      <Modal id="modal-category" title={window.I18N('create_update_data')} saveEvent={saveCategory}>
        <form id="form-category" className="needs-validation" noValidate>
          <MDBRow>
            <MDBCol>
              <MDBInput outline containerClass="mt-0" value={dataSave.code} name="code" onChange={handleChange} type="text" label={window.I18N('code') + ' *'} maxLength="50" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <MDBInput outline type="text" label={window.I18N('category_name') + ' *'} name="name" value={window.COMMON.getValueWithLanguage(dataSave, 'name', language.current)} onChange={handleChangeWithLanguage} maxLength="100" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput>
              <div className="text-center">
                <MDBCardImage className="input-image-icon" src={window.COMMON.setValueImage(dataSave.image)} hover overlay="white-slight"/><br></br>
                <InputFile id="file-category" icon="upload" handleFile={handleFile} accept="image/*" description={`${window.I18N('icon')} (64 x 64) pixels`}></InputFile>
              </div>
            </MDBCol>
          </MDBRow>
        </form>
      </Modal>

      <Modal id="modal-import" className="modal-xl" title={window.I18N('import_data')} saveEvent={importData}>
        <Table id="table-import" className="table" config={dataImport.config} data={dataImport.data} handleRowDelClick={deleteImportRow} isImported={true}></Table>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  language: state.language.value
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RewardPage);