import React, { useRef } from "react";
import CustomChart from "../../../components/InsightChart/components/CustomChart";

const SharedChartRendering = ({
  data,
  timestamps,
  maxYAxisValue,
  isMini = false,
  containerStyle = {},
  borderColor = "#42a5f5",
  backgroundColor = "rgba(66, 165, 245, 0.2)",
}) => {
  const chartRef = useRef(null);
  const extendedTimestamps = [
    // ...Array(Math.max(0, placeholderCount - timestamps.length)).fill(""),
    ...timestamps,
  ];

  const dataTemplate = (data) => ({
    labels: extendedTimestamps,
    datasets: [
      {
        data: [
          // ...Array(Math.max(0, placeholderCount - data.length)).fill(null),
          ...data,
        ],
        fill: true,
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        tension: 0.1,
        pointRadius: 0, // Hide the points
        pointHoverRadius: isMini ? 0 : 10, // Hide the points
        pointHitRadius: isMini ? 0 : 50, // Increase the hover area
      },
    ],
  });

  const defaultMiniStyle = {
    position: "relative",
    height: "100px",
    width: "120px",
    border: "1px solid #ddd",
    padding: "8px 8px 0 0",
  };

  const defaultFullStyle = {
    position: "relative",
  };

  const chartOptions = {
    animation: {
      duration: 0,
    },

    scales: {
      xAxes: [
        {
          ticks: isMini
            ? { display: false }
            : {
                callback: function (value, index) {
                  return value;
                },
                maxRotation: 0,
                minRotation: 0,
                autoSkip: false,
              },
          gridLines: {
            display: !isMini,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            max: maxYAxisValue,
            display: !isMini,
          },
          gridLines: {
            display: !isMini,
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
  };

  const createInnerHTMLPopup = ({ hoverLabelIndex }) => {
    var innerHtml = (
      <div className="wow fadeIn" style={{ minWidth: "150px" }}>
        <div className="font-weight-bold d-flex flex-column justify-content-center">
          <span>Time: {timestamps[hoverLabelIndex]}</span>
          <span className="mt-2">Usage: {data[hoverLabelIndex]}</span>
        </div>
      </div>
    );
    return innerHtml;
  };

  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={
        isMini
          ? { ...defaultMiniStyle, ...containerStyle }
          : { ...defaultFullStyle, ...containerStyle }
      }
    >
      <CustomChart
        ref={chartRef}
        typeChart="Line"
        dataChart={dataTemplate(data)}
        options={chartOptions}
        createInnerHTMLPopup={createInnerHTMLPopup}
      />
    </div>
  );
};

export default SharedChartRendering;
