import React, { useState, useCallback, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon, MDBCardImage } from 'mdbreact';

import RadioBox from './RadioBox';
import InputFile from './InputFile';
import Panel from './Panel';

import AccountGRAPHQL from '../../graphql/Account';
import { useTracking } from 'react-tracking';
import eventName from '../../common/events';

const MAX_TEXT_LENGTH = 500

const AddAccount = React.memo(function AddAccount(props) {
  const history = useHistory();
  const { trackEvent } = useTracking();
  const COMPANY_TYPE = localStorage.getItem(window.CONSTANT.COMPANY_TYPE);
  const genders = [
    { value: 0, label: window.I18N('female'), icon: 'venus' },
    { value: 1, label: window.I18N('male'), icon: 'mars' }
  ];
  const maritalStatusOptions =  [
    { value: 0, label: window.I18N('single') },
    { value: 1, label: window.I18N('married') }
  ];
  const [dataSave, setDataSave] = useState({ gender: 0  });
  const [file, setFile] = useState(null);
  const [select, setSelect] = useState({});
  const [date, setDate] = useState({ date: new Date(1990, 0, 1) });
  const [options, setOptions] = useState({});

  useLayoutEffect(() => {
    initializeForm();
    // eslint-disable-next-line
  }, [props.options, props.item, props.index]);

  const initializeForm = async () => {
    const options = props.options;
    const dataSave = {};
    const select = {};
    if (props.index === -1) {
      dataSave.name = '';
      dataSave.nickname = '';
      dataSave.email = '';
      dataSave.avatar = '';
      dataSave.phone = '';
      dataSave.address = '';
      dataSave.taxCode = '';
      dataSave.bio = '';
      dataSave.university = '';
      dataSave.universityMajor = '';
      dataSave.universityPeriod = '';
      dataSave.highSchool = '';
      dataSave.highSchoolPeriod = '';
      dataSave.secondarySchool = '';
      dataSave.secondarySchoolPeriod = '';
      dataSave.gender = 0;
      date.birthday = new Date(1990, 0, 1);
      options.state = [];
      options.city = [];
      if (props.company && props.company.code === window.CONSTANT.INFLUENCER) {
        select.companies = [];
        select.unions = [];
      }
      select.role = options.role && options.role.length > 0 ? options.role[0] : null;
      select.nation = null;
      select.state = null;
      select.city = null;
      select.income = null;
      select.maritalStatus = null;
      select.educationBackground = null;
    } else {
      dataSave.name = window.COMMON.getValueFromAttr(props.item, 'name');
      dataSave.nickname = window.COMMON.getValueFromAttr(props.item, 'nickname');
      dataSave.email = window.COMMON.getValueFromAttr(props.item, 'email');
      dataSave.avatar = window.COMMON.getValueFromAttr(props.item, 'avatar');
      dataSave.phone = window.COMMON.getValueFromAttr(props.item, 'phone');
      dataSave.address = window.COMMON.getValueFromAttr(props.item, 'address');
      dataSave.taxCode = window.COMMON.getValueFromAttr(props.item, 'taxCode');
      dataSave.bio = window.COMMON.getValueFromAttr(props.item, 'bio');
      dataSave.university = window.COMMON.getValueFromAttr(props.item, 'university');
      dataSave.universityMajor = window.COMMON.getValueFromAttr(props.item, 'universityMajor');
      dataSave.universityPeriod = window.COMMON.getValueFromAttr(props.item, 'universityPeriod');
      dataSave.highSchool = window.COMMON.getValueFromAttr(props.item, 'highSchool');
      dataSave.highSchoolPeriod = window.COMMON.getValueFromAttr(props.item, 'highSchoolPeriod');
      dataSave.secondarySchool = window.COMMON.getValueFromAttr(props.item, 'secondarySchool');
      dataSave.secondarySchoolPeriod = window.COMMON.getValueFromAttr(props.item, 'secondarySchoolPeriod');
      dataSave.gender = window.COMMON.getValueFromAttr(props.item, 'gender');
      date.birthday = window.COMMON_DATE.convertStringToDate(window.COMMON.getValueFromAttr(props.item, 'birthday'), '/');
      select.role = window.COMMON.setDataSelect(options.role, window.COMMON.getValueFromAttr(props.item, 'accountType'));
      if (window.COMMON.getValueFromAttr(props.item, 'accountType') === 'SUPER_ADMIN') {
        select.role = window.COMMON.getObjectInArrs('SUPER_ADMIN', options.role, 'code');
      }
      if (props.company && props.company.code === window.CONSTANT.INFLUENCER) {
        select.companies = window.COMMON.setDataMultiSelect(options.companies, window.COMMON.getValueFromAttr(props.item, 'companies'), '_id');
        select.unions = window.COMMON.setDataMultiSelect(options.unions, window.COMMON.getValueFromAttr(props.item, 'unions'), '_id');
      }
      select.nation = window.COMMON.setDataSelect(options.nation, window.COMMON.getValueFromAttr(props.item, 'nation._id'));
      if (select.nation) {
        options.state = await window.COMMON.getDataLocation(select.nation.code);
        select.state = window.COMMON.setDataSelect(options.state, window.COMMON.getValueFromAttr(props.item, 'state._id'));
        if (select.state) {
          options.city = await window.COMMON.getDataLocation(select.state.code);
          select.city = window.COMMON.setDataSelect(options.city, window.COMMON.getValueFromAttr(props.item, 'city._id'));
        }
      }
      select.income = window.COMMON.setDataSelect(options.income, window.COMMON.getValueFromAttr(props.item, 'income'));
      select.maritalStatus = window.COMMON.setDataSelect(maritalStatusOptions, window.COMMON.getValueFromAttr(props.item, 'maritalStatus'));
      select.educationBackground = window.COMMON.setDataSelect(options.educationBackground, window.COMMON.getValueFromAttr(props.item, 'educationBackground'));
    }
    setFile(null);
    setOptions(options);
    setSelect(select);
    setDate(date);
    setDataSave({ ...dataSave });
    document.getElementById('file-avatar').value = null;
  };

  const createTrackingEvent = (event) => {
    return trackEvent({
      name: event,
      createdAt: new Date().toISOString()
    });
  };

  const handleChange = (event) => {
    const { value, name } = event.target
    if(value.startsWith(' ') || value.length >= MAX_TEXT_LENGTH){
      return
    }
    setDataSave((prev) => ({ ...prev, [name]: value }));
  };

  const handleRadio = useCallback((value) => {
    dataSave.gender = value;
    setDataSave({ ...dataSave });
  }, [dataSave]);

  const handleFile = (file) => {
    dataSave.avatar = window.COMMON.createBlobImage(file);
    setFile(file);
    setDataSave({ ...dataSave });
  };

  const handleSelect = (event, id, attr) => {
    window.COMMON.checkSelectValidation(id, event);
    select[attr] = event;
    if (attr === 'nation' || attr === 'state') {
      getDataLocationOptions(attr, event.code);
    } else {
      setSelect({ ...select }); 
    }
  };

  const getDataLocationOptions = async (attr, parent) => {
    if (attr === '-1') {
      options.nation = await window.COMMON.getDataLocation(parent);
      options.state = [];
      options.city = [];
      select.nation = null;
      select.state = null;
      select.city = null;
    } else if (attr === 'nation') {
      options.state = await window.COMMON.getDataLocation(parent);
      options.city = [];
      select.state = null;
      select.city = null;
    } else if (attr === 'state') {
      options.city = await window.COMMON.getDataLocation(parent);
      select.city = null;
    }
    setOptions(options);
    setSelect({ ...select });
  };

  const handleDatePicker = (event, attr) => {
    if(event === null){
      return
    }
    setDate((prev) => ({ ...prev, [attr]: event }))
  };

  const checkIsIndividual = () => {
    return select.role && select.role.code === window.CONSTANT.INDIVIDUAL;
  };

  const checkEnableReset = () => {
    return window.COMMON.checkRoleIsSystem() || (!window.COMMON.checkRoleIsSystem() && props.company && COMPANY_TYPE === props.company.code);
  };

  const checkEnableSave = () => {
    return window.COMMON.checkRoleIsSystem() || (props.company && COMPANY_TYPE === props.company.code) || (props.index === window.CONSTANT.VALUE_PROFILE);
  };

  const getPackageIdOfCompany = (paramId) => {
    const allCompany = JSON.parse(localStorage.getItem(window.CONSTANT.DATA_ALL_COMPANY));
    return allCompany.find(el => el._id === paramId)?.information.package._id;
  };

  const openSaveAccountDialog = async (event) => {
    window.COMMON.trimData(dataSave);
    setDataSave({...dataSave})
    const checkForm = window.COMMON.checkFormValidation('form', event);
    const checkNation = window.COMMON.checkSelectValidation('select-nation', select.nation);
    const checkCity = window.COMMON.checkSelectValidation('select-city', select.city);
    const checkState = window.COMMON.checkSelectValidation('select-state', select.state);
    let checkRole = true;
    if (props.index !== window.CONSTANT.VALUE_PROFILE) { 
      checkRole = window.COMMON.checkSelectValidation('select-role', select.role);
    }    
    if (!checkForm || !checkNation || !checkCity || !checkState || !checkRole) {
      return;
    }
    window.COMMON.showModal('#modal-save');
    window.saveMethod = saveAccount;
  };

  const saveAccount = async () => {
    let dataFiles = [];
    try {
      if (props.company && props.company.code === window.CONSTANT.COMPANY) {
        dataSave.company = props.company.value;
      } else if (props.company && props.company.code === window.CONSTANT.UNION) {
        dataSave.union = props.company.value;
      } else if (props.company && props.company.code === window.CONSTANT.INFLUENCER) {
        dataSave.influencer = props.company.value;
      }
      dataSave.birthday = date.birthday.toString();
      dataSave.email = dataSave.email.toLowerCase();
      const keys = Object.keys(select);
      for (let i = 0; i < keys.length; i++) {
        const attr = keys[i];
        if (attr !== 'role') {
          dataSave[attr] = window.COMMON.getDataSelect(select[attr], attr === 'companies' || attr === 'unions');
        }
      }
      // TODO upload file
      if (file) {
        dataFiles = await window.uploadFile([file]);
        if (dataFiles.length > 0) {
          dataSave.avatar = window.COMMON.getNewPathFile(dataFiles[0]._id);
        }
      }
      setFile(null);
      // TODO save data
      const params = {
        input: {
          /* role: select.role.code !== window.CONSTANT.INDIVIDUAL ? select.role.image : null,
          accountType: select.role.code, */
          individual: dataSave
        }
      };
      if (props.index === window.CONSTANT.VALUE_PROFILE) {
        params.input.role = props.item.role?._id;
        params.input.accountType = props.item.accountType;
      } else {
        params.input.role = select.role.code !== window.CONSTANT.INDIVIDUAL ? select.role.image : null;
        params.input.accountType = select.role.code;
      }
      window.COMMON.trimData(params);
      if (props.index === -1) {
        const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_ADD_ACCOUNT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_INSERT)) {
          const paramPackage = {
            input: {
              organizationIds: params.input.individual.company ?
                params.input.individual.company :
                params.input.individual.influencer,
              packageId: ''
            }
          };
          if (window.COMMON.checkRoleIsSystem()) {
            paramPackage.input.packageId = getPackageIdOfCompany(paramPackage.input.organizationIds);
          } else {
            paramPackage.input.packageId = localStorage.getItem(window.CONSTANT.PACKAGE_ID);
          }
          const resultSetPackage = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_SET_PACKAGE, paramPackage, false);
          if ( window.COMMON.checkResultData(resultSetPackage, window.CONSTANT.MODE_UPDATE, false)) {
            createTrackingEvent(eventName.SET_PACKAGE);
          }
          window.COMMON.backContainerData();
          if (props.callback) {
            props.callback();
          }
          createTrackingEvent(eventName.ADD_ACCOUNT);
        } 
      } else {
        params.input._id = props.item._id;
        const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_EDIT_ACCOUNT, params);
        if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
          window.COMMON.backContainerData();
          if (props.callback) {
            props.callback();
          }
          createTrackingEvent(eventName.EDIT_ACCOUNT);
        }
      }
    } catch (error) {
      window.UPLOAD_API.deleteMultiFile(dataFiles.map(item => item._id))           
      window.COMMON.showErrorLogs('AddAccount.saveAccount');
    }
  };

  const updateStatus = async () => {
    try {
      const params = {
        id: props.item._id,
        status: !props.item.status
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_UPDATE_STATUS_ACCOUNT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_STATUS)) {
        window.COMMON.backContainerData();
        if (props.callback) {
          props.callback();
        }
        createTrackingEvent(eventName.UPDATE_STATUS_ACCOUNT);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AddAccount.updateStatus');
    }
  };

  const resetPassword = async () => {
    try {
      const params = {
        id: props.item._id
      };
      const result = await window.COMMON.mutation(AccountGRAPHQL.MUTATION_RESET_PASSWORD_ACCOUNT, params);
      if (window.COMMON.checkResultData(result, window.CONSTANT.MODE_UPDATE)) {
        createTrackingEvent(eventName.RESET_PASSWORD_ACCOUNT);
      }
    } catch (error) {
      window.COMMON.showErrorLogs('AddAccount.resetPassword');
    }
  };


  if (typeof window === 'undefined') {
    return null;
  }
  return <Panel id="container-save" title={window.I18N('create_update_data')} icon="file" style={{ display: 'none' }}>
    <div className="container-btn wow fadeInDown animated" data-wow-delay="0.5s">
      {
        props.index !== window.CONSTANT.VALUE_PROFILE ? <MDBBtn color="" onClick={window.COMMON.backContainerData}>
          <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
        </MDBBtn> : <MDBBtn color="" onClick={() => history.push('/dashboard')}>
          <MDBIcon fa="true" icon="arrow-left" className="fa-fw" /> {window.I18N('btn_back')}
        </MDBBtn>
      }
      {
        props.index > -1 && props.index !== window.CONSTANT.VALUE_PROFILE && checkEnableReset() ? <>
          <MDBBtn color="success" type="button" className={props.index > -1 && !props.item?.status ? '' : 'd-none'} data-toggle="modal" data-backdrop="static" data-target="#modal-save" onClick={() => { window.saveMethod = updateStatus; }}>
            <MDBIcon fa="true" icon="check" className="fa-fw" /> {window.I18N('btn_active')}
          </MDBBtn>
          <MDBBtn color="danger" type="button" className={props.index > -1 && props.item?.status ? '' : 'd-none'} data-toggle="modal" data-backdrop="static" data-target="#modal-save" onClick={() => { window.saveMethod = updateStatus; }}>
            <MDBIcon fa="true" icon="ban" className="fa-fw" /> {window.I18N('btn_inactive')}
          </MDBBtn>
        </> : <></>
      }
      {
        props.index > -1 && props.index !== window.CONSTANT.VALUE_PROFILE && checkEnableReset() ? <MDBBtn color="" type="button" className={props.index > -1 ? '' : 'd-none'} onClick={resetPassword}>
          <MDBIcon fa="true" icon="lock" className="fa-fw" /> {window.I18N('btn_reset_password')}
        </MDBBtn> : <></>
      }
      {
        checkEnableSave() ? <MDBBtn color="primary" type="button" onClick={openSaveAccountDialog}>
          <MDBIcon fa="true" icon="save" className="fa-fw" /> {window.I18N('btn_save')}
        </MDBBtn> : <></>
      }
    </div>
    
    <div className="container-top wow fadeInUp animated" data-wow-delay="0.5s">
      <form id="form" className="needs-validation" noValidate>
        <MDBRow>
          <MDBCol md="6">
            <h6 className="mb-0"><small className="font-weight-bold black-text">{window.I18N('account_information')}</small></h6>
            <MDBInput outline type="text" label={window.I18N('full_name') + ' *'} name="name" value={dataSave.name} onChange={props.index === -1 || window.COMMON.checkRoleIsSystemOrOrganization() ||(props.index === window.CONSTANT.VALUE_PROFILE) ? handleChange: () => {}} maxLength="100" pattern="\S(.*\S)?" required>
              <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
            </MDBInput>
            {props.index === -1 || (!checkIsIndividual() && COMPANY_TYPE !== window.CONSTANT.INFLUENCER) || (props.index === window.CONSTANT.VALUE_PROFILE) ? 
              <MDBInput outline className='text-lowercase' disabled={props.index === window.CONSTANT.VALUE_PROFILE} type="email" label={`${window.I18N('email')} ${checkIsIndividual() ? '' : '*'}`} name="email" value={dataSave.email} onChange={props.index !== window.CONSTANT.VALUE_PROFILE?handleChange:() => {}} maxLength="100" pattern="[^\s@]+@[^\s@]+\.[^\s@]+" required={checkIsIndividual() ? false : true}>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_030')}</div>
              </MDBInput> : null}
            {props.index === -1 || (!checkIsIndividual() && COMPANY_TYPE !== window.CONSTANT.INFLUENCER) || (props.index === window.CONSTANT.VALUE_PROFILE) ? 
              <MDBInput outline type="number" className="input-number" disabled={props.index === window.CONSTANT.VALUE_PROFILE} label={window.I18N('phone_number') + ' *'} name="phone" value={dataSave.phone} onChange={props.index !== window.CONSTANT.VALUE_PROFILE?handleChange:() => {}} maxLength="20" pattern="\S(.*\S)?" required>
                <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
              </MDBInput> : null}
            <MDBRow>
              <MDBCol md="6">
                <RadioBox data={genders} value={dataSave.gender} handleChange={handleRadio}></RadioBox>
              </MDBCol>
              <MDBCol md="6">
                <div className="input-group input-group-transparent">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                    </span>
                  </div>
                  <DatePicker calendarClassName="12" placeholderText={window.I18N('birthday')} className="form-control" dateFormat="dd/MM/yyyy" selected={date.birthday} onChange={(event) => handleDatePicker(event, 'birthday')} showYearDropdown showMonthDropdown/>
                </div>
              </MDBCol>
            </MDBRow>
            <div className="text-center">
              <MDBCardImage className="image-logo mt-3" src={window.COMMON.setValueImage(dataSave.avatar)} hover overlay="white-slight"/><br></br>
              <InputFile id="file-avatar" icon="upload" handleFile={handleFile} accept="image/*" description="Image (120 x 120) pixels"></InputFile>
            </div>
          </MDBCol>
          <MDBCol md="6">
            <h6 className="mb-0"><small className="font-weight-bold black-text">{window.I18N('general_information')}</small></h6>
            {
              props.company && props.company.code === window.CONSTANT.INFLUENCER ? <>
                <Select id="select-companies" value={select.companies} options={options.companies} placeholder={window.I18N('companies')} className="md-form" onChange={(event) => handleSelect(event, 'select-companies', 'companies')} isMulti isSearchable closeMenuOnSelect={false}/>
                <Select id="select-unions" value={select.unions} options={options.unions} placeholder={window.I18N('unions')} onChange={(event) => handleSelect(event, 'select-unions', 'unions')} isMulti isSearchable closeMenuOnSelect={false}/>
              </> : <></>
            }
            {
              props.index !== window.CONSTANT.VALUE_PROFILE ? <Select id="select-role" value={select.role} options={options.role} placeholder={window.I18N('role') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-role', 'role')} isSearchable/>  :
                <></>
            }               
            <MDBInput outline value={dataSave.address} name="address" onChange={handleChange} type="text" label={window.I18N('address_number') + ' *'} maxLength="200" pattern="\S(.*\S)?" required>
              <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
            </MDBInput>
            <Select id="select-nation" value={select.nation} options={options.nation} placeholder={window.I18N('country') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-nation', 'nation')} isSearchable/>
            <Select id="select-state" value={select.state} options={options.state} placeholder={window.I18N('state_city') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-state', 'state')} isSearchable/>
            <Select id="select-city" value={select.city} options={options.city} placeholder={window.I18N('city_district') + ' *'} className="md-form" onChange={(event) => handleSelect(event, 'select-city', 'city')} isSearchable/>
            <MDBInput outline type="text" label={window.I18N('tax_id')} name="taxCode" value={dataSave.taxCode} onChange={handleChange} maxLength="20">
              <div className="invalid-feedback">{window.I18N('MSG_CODE_029')}</div>
            </MDBInput>
            <MDBInput outline type="textarea" name="bio" label={window.I18N('bio')} value={dataSave.bio} onChange={handleChange} maxLength="5000"></MDBInput>
            <h6 className="mb-0"><small className="font-weight-bold black-text">{window.I18N('other_information')}</small></h6>
            <Select id="select-income" value={select.income} options={options.income} placeholder={window.I18N('income')} className="md-form" onChange={(event) => handleSelect(event, 'select-income', 'income')} isSearchable/>
            <Select id="select-marital-status" value={select.maritalStatus} options={maritalStatusOptions} placeholder={window.I18N('marital_status')} className="md-form" onChange={(event) => handleSelect(event, 'select-marital-status', 'maritalStatus')} isSearchable/>
            <Select id="select-education-background" value={select.educationBackground} options={options.educationBackground} placeholder={window.I18N('education_background')} className="md-form" onChange={(event) => handleSelect(event, 'select-education-background', 'educationBackground')} isSearchable/>
            <MDBInput outline value={dataSave.university} name="university" onChange={handleChange} type="text" label={window.I18N('university')} maxLength="200" pattern="\S(.*\S)?"></MDBInput>
            <MDBInput outline value={dataSave.universityMajor} name="universityMajor" onChange={handleChange} type="text" label={window.I18N('universityMajor')} maxLength="200" pattern="\S(.*\S)?"></MDBInput>
            <MDBInput outline value={dataSave.universityPeriod} name="universityPeriod" onChange={handleChange} type="text" label={window.I18N('university_year')} maxLength="200" pattern="\S(.*\S)?"></MDBInput>
            <MDBInput outline value={dataSave.highSchool} name="highSchool" onChange={handleChange} type="text" label={window.I18N('high_school')} maxLength="200" pattern="\S(.*\S)?"></MDBInput>
            <MDBInput outline value={dataSave.highSchoolPeriod} name="highSchoolPeriod" onChange={handleChange} type="text" label={window.I18N('high_school_year')} maxLength="200" pattern="\S(.*\S)?"></MDBInput>
            <MDBInput outline value={dataSave.secondarySchool} name="secondarySchool" onChange={handleChange} type="text" label={window.I18N('secondary_school')} maxLength="200" pattern="\S(.*\S)?"></MDBInput>
            <MDBInput outline value={dataSave.secondarySchoolPeriod} name="secondarySchoolPeriod" onChange={handleChange} type="text" label={window.I18N('secondary_school_year')} maxLength="200" pattern="\S(.*\S)?"></MDBInput>
          </MDBCol>
        </MDBRow>
      </form>
    </div>
  </Panel>;
});

export default AddAccount;