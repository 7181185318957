import React from 'react';
import { MDBCard, MDBCardBody } from 'mdbreact';

const AccountCard = ({ account }) => {
    return (
        <MDBCard className="mb-3" key={account._id}>
            <MDBCardBody >
                <div className="d-flex align-items-center">
                    {account.avatar ? (
                        <img
                            src={account.avatar}
                            alt={account.name}
                            style={{
                                width: '80px',
                                height: '80px',
                                borderRadius: '50%',
                                marginRight: '10px',
                            }}
                        />
                    ) : (
                        <div
                            style={{
                                width: '80px',
                                height: '80px',
                                borderRadius: '50%',
                                backgroundColor: '#ccc',
                                marginRight: '10px',
                            }}
                        />
                    )}
                    <div>
                        <h6 className="font-weight-bold mb-1">{account.name || 'No Name'}</h6>
                        <small className="text-muted">{account.accountType}</small><br />
                        <small className="text-muted">{account.address}</small>
                    </div>
                </div>
            </MDBCardBody>
        </MDBCard>
    );
};

export default AccountCard;
