import { MDBBtn, MDBCol, MDBIcon, MDBInput, MDBRow } from 'mdbreact'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import ImportExcel from '../../../../components/ImportExcel'
import Table from '../../../../components/Table'
import ReactSelect from 'react-select'
import Modal from '../../../../components/Modal'
import AssociationDepsUpdateForm from './AssociationDepsUpdateForm'
import { useDebounce } from '../../../../../hooks/useSearchDebounce'
import RadioBox from '../../../../components/RadioBox'
import AssociationAddDepsForm from './AssociationAddDepsForm'
import Pagination from '../../../../components/Pagination'

const DEPENDENT_TAB = 0
const REFERENCE_TAB = 1
const DELAY_SEARCH_DEBOUNCED = 500 // ms

const INIT_PAGINATION = {
    page: 1,
    pageSize: 10,
    totalPages: 0,
    totalDeps: 0,
    currentPage: 0
}

const AssociationConfigsData = ({ keysReportCategory, dependencies, onGetListDependencies = () => {} }, ref) => {
    const activeCategoryIds = keysReportCategory?.find((item) => item?.active)?.dataId?.split('-')

    const createDataTabDeps = () => {
        if (!window.COMMON.isEmpty(keysReportCategory)) {
            const [dependent, reference] = keysReportCategory.find((item) => item.active)?.name.split('-')
            return [
                { value: DEPENDENT_TAB, label: dependent, icon: 'database' },
                { value: REFERENCE_TAB, label: reference, icon: 'cogs' }
            ]
        }
        return []
    }
    const [currentTab, setCurrentTab] = useState(DEPENDENT_TAB)
    const [optionsSelectDependencies, setOptionsSelectDependencies] = useState([])
    const [dataSelectDependencies, setDataSelectDependencies] = useState({})
    const [cacheDataSelectDependencies, setCacheDataSelectDependencies] = useState({})
    const [dataImport, setDataImport] = useState({
        config: {},
        data: [],
        file: null
    })
    const [dataForEditDeps, setDataForEditDeps] = useState({})
    const [isChangedDataEditDeps, setIsChangedDataEditDeps] = useState(false)
    const [dataTableDeps, setDataTableDeps] = useState([])
    const [pagination, setPagination] = useState(INIT_PAGINATION)
    const [searchReportDepsByRefCode, setSearchReportDepsByRefCode] = useState('')

    const debouncedSearch = useDebounce(searchReportDepsByRefCode, DELAY_SEARCH_DEBOUNCED)

    const associationDepsAddFormRef = useRef()
    const associationDepsUpdateFormRef = useRef()

    const handleChangePage = (index) => {
        setPagination((prev) => ({ ...prev, page: prev.currentPage + 1, currentPage: index }))
    }

    const handleChangeDepsTab = (value) => {
        setCurrentTab(value)
    }

    const createDataSelect = (data) => {
        return data.map((deps) => {
            return {
                label: deps.code,
                value: deps.code
            }
        })
    }

    useEffect(() => {
        if (!window.COMMON.isEmpty(dataTableDeps) && window.COMMON.isEmpty(dependencies)) {
            setOptionsSelectDependencies([])
        }
    }, [keysReportCategory])

    useEffect(() => {
        if (!window.COMMON.isEmpty(dependencies)) {
            const dataSelect = createDataSelect(dependencies)
            const [firstElement] = dataSelect
            setOptionsSelectDependencies(dataSelect)
            if (!window.COMMON.isEmpty(cacheDataSelectDependencies)) {
                setDataSelectDependencies(cacheDataSelectDependencies)
                return
            }
            setDataSelectDependencies(firstElement)
            setCacheDataSelectDependencies(firstElement)
        }
    }, [dependencies])

    const handleCheckImportDataConfigs = async ({ dataImport }) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category-deps/check-data-import`,
                method: 'POST',
                input: {
                    data: {
                        dataImport
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationConfigs.handleCheckImportDataConfigs')
                }
            })

            window.hideLoading()
            return result
        } catch (error) {
            console.error('🚀 ~ handleCheckImportDataConfigs ~ error:', error)
        }
    }

    const handleFileImportConfigs = async (dataImport) => {
        // TODO: Import config
        try {
            const category = activeCategoryIds
            const { metadata: dataChecked, status } = await handleCheckImportDataConfigs({
                dataImport: dataImport.data.map((item) => ({
                    ...item,
                    data: window.COMMON.formatTitleCase(item.data),
                    category
                }))
            })
            setDataImport({
                ...dataImport,
                data: dataChecked
            })
            if (status === 200) {
                window.COMMON.showModal('#modal-import-deps')
            }
        } catch (error) {
            console.error('🚀 ~ handleFileImportConfigs ~ error:', error)
        }
    }

    const handleImportDataConfigs = async ({ dataImport }) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category-deps/import-report-categories-deps`,
                method: 'POST',
                input: {
                    data: {
                        dataImport
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationConfigs.handleImportDataConfigs')
                }
            })

            window.hideLoading()
            return result
        } catch (error) {
            console.error('🚀 ~ handleImportDataConfigs ~ error:', error)
        }
    }

    const importDataConfigs = async () => {
        try {
            const configs = {}
            for (let i = 0; i < dataImport.data.length; i++) {
                const data = dataImport.data[i]
                if (configs[data.key]) {
                    configs[data.key].push(data.data)
                } else {
                    configs[data.key] = [data.data]
                }
            }
            const [parentKeyId, childrenKeyId] = activeCategoryIds
            const dataForImportDB = []
            for (const key in configs) {
                const itemImportDB = {
                    code: key,
                    category: parentKeyId,
                    children: window.COMMON.removeDuplicateElements(configs[key]).map((item) => ({
                        code: window.COMMON.formatTitleCase(item),
                        category: childrenKeyId
                    }))
                }
                dataForImportDB.push(itemImportDB)
            }
            const result = await handleImportDataConfigs({
                dataImport: dataForImportDB
            })
            if (result.status === 201) {
                window.COMMON.showMessage('success', 'MSG_CODE_016', window.I18N('MSG_CODE_016'))
                window.COMMON.hideModal('#modal-import-deps')
                const [dependentId] = activeCategoryIds
                await onGetListDependencies({
                    category: dependentId
                })
            }
        } catch (error) {
            console.error('🚀 ~ importDataConfigs ~ error:', error)
        }
    }

    const handleConfirmImport = () => {
        window.COMMON.showModal('#modal-save')
        window.saveMethod = importDataConfigs
    }

    const createConfigTable = () => {
        const [dependent, reference] = keysReportCategory.find((key) => key.active)?.name.split('-') || ['', '']
        if (currentTab === DEPENDENT_TAB) {
            return [
                window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
                window.COMMON.createCol(window.I18N(`${dependent}`), '15%', '', '', 'code', 'TEXT'),
                window.COMMON.createCol(window.I18N('created_date'), '15%', '', '', 'createdAt', 'TEXT'),
                window.COMMON.createCol(
                    window.I18N('created_by'),
                    '20%',
                    '',
                    '',
                    'createdBy.avatar,createdBy.name',
                    'IMAGE_TEXT'
                ),
                window.COMMON.createCol(window.I18N('updated_date'), '15%', '', '', 'updatedAt', 'TEXT'),
                window.COMMON.createCol(
                    window.I18N('updated_by'),
                    '20%',
                    '',
                    '',
                    'updatedBy.avatar,updatedBy.name',
                    'IMAGE_TEXT'
                ),
                window.COMMON.createCol(window.I18N('action'), '10%', '', '', '3', 'BUTTON')
            ]
        }
        return [
            window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
            window.COMMON.createCol(window.I18N(`${dependent}`), '13%', '', '', 'parentCode', 'TEXT'),
            window.COMMON.createCol(window.I18N(`${reference}`), '13%', '', '', 'code', 'TEXT'),
            window.COMMON.createCol(window.I18N('created_date'), '10%', '', '', 'createdAt', 'TEXT'),
            window.COMMON.createCol(
                window.I18N('created_by'),
                '20%',
                '',
                '',
                'createdBy.avatar,createdBy.name',
                'IMAGE_TEXT'
            ),
            window.COMMON.createCol(window.I18N('updated_date'), '10%', '', '', 'updatedAt', 'TEXT'),
            window.COMMON.createCol(
                window.I18N('updated_by'),
                '20%',
                '',
                '',
                'updatedBy.avatar,updatedBy.name',
                'IMAGE_TEXT'
            ),
            window.COMMON.createCol(window.I18N('action'), '8%', '', '', '3', 'BUTTON')
        ]
    }

    const getListDependenciesWithParent = async ({ parentId, search }) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category-deps/get-list-with-parent`,
                method: 'GET',
                input: {
                    params: {
                        filter: {
                            parent: parentId,
                            search: search.trim()
                        },
                        page: pagination.currentPage + 1 || 1,
                        pageSize: pagination.pageSize || 10
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationConfigsData.getListDependenciesWithParent')
                }
            })

            window.hideLoading()
            if (result.status === 200) {
                const paginationSet = window.COMMON.isEmpty(result.metadata) ? INIT_PAGINATION : result.metadata.meta
                setPagination((prev) => ({ ...prev, ...paginationSet }))
                return result.metadata
            }
            return { data: [] }
        } catch (error) {
            console.error('🚀 ~ getListDependenciesWithParent ~ error:', error)
        }
    }

    const createDataTable = async () => {
        if (window.COMMON.isEmpty(dataSelectDependencies)) return
        if (currentTab === REFERENCE_TAB) {
            const { value: parentCode } = dataSelectDependencies
            const foundParentDeps = dependencies.find((deps) => deps.code === parentCode)
            if (!window.COMMON.isEmpty(foundParentDeps)) {
                const childrens = await getListDependenciesWithParent({
                    parentId: foundParentDeps._id,
                    search: searchReportDepsByRefCode
                })
                const dataSet = childrens.data.map((child) => {
                    return {
                        childId: child._id,
                        parentCode,
                        code: child.code,
                        createdAt: window.COMMON_DATE.getFormatDate(child.createdAt),
                        updatedAt: window.COMMON_DATE.getFormatDate(child.updatedAt),
                        createdBy: child.createdBy,
                        updatedBy: child.updatedBy
                    }
                })
                setDataTableDeps(dataSet)
                return dataSet
            }
        } else {
            const dataSet = dependencies.map((dependent) => {
                return {
                    childId: dependent._id,
                    code: dependent.code,
                    createdAt: window.COMMON_DATE.getFormatDate(dependent.createdAt),
                    updatedAt: window.COMMON_DATE.getFormatDate(dependent.updatedAt),
                    createdBy: dependent.createdBy,
                    updatedBy: dependent.updatedBy
                }
            })

            if (!window.COMMON.isEmpty(searchReportDepsByRefCode)) {
                const filteredDataSet = dataSet.filter((item) =>
                    item.code.toLowerCase().includes(searchReportDepsByRefCode.toLowerCase())
                )
                setDataTableDeps(filteredDataSet)
                return
            }
            setDataTableDeps(dataSet)
            return dataSet
        }
        setDataTableDeps([])
        return []
    }

    const handleChangeSelectDeps = (value) => {
        setDataSelectDependencies(value)
        setCacheDataSelectDependencies(value)
        setPagination((prev) => ({ ...prev, currentPage: 0 }))
    }

    const handleDeleteReportDeps = async (id) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category-deps/delete-category-deps`,
                method: 'DELETE',
                input: {
                    data: {
                        id
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationConfigs.handleCheckImportDataConfigs')
                }
            })

            const { status } = result
            if (status === 200) {
                window.COMMON.showMessage('success', 'MSG_CODE_003', window.I18N('MSG_CODE_003'))
                setDataTableDeps([])
                setCacheDataSelectDependencies({})
                if (currentTab === REFERENCE_TAB) {
                    await createDataTable()
                } else {
                    const [dependentId] = activeCategoryIds
                    const { metadata } = await onGetListDependencies({
                        category: dependentId,
                        isResetSelectDeps: false
                    })
                    if (window.COMMON.isEmpty(metadata)) {
                        setDataSelectDependencies({})
                        setOptionsSelectDependencies([])
                    }
                }
                window.COMMON.hideModal('#modal-delete')
            }
            window.hideLoading()
            return result
        } catch (error) {
            console.error('🚀 ~ handleDeleteReportDeps ~ error:', error)
        }
    }

    const handleDeleteExistedDeps = (indexClick) => {
        const cloneDataImport = window.COMMON.deepCopyArray(dataImport.data)
        cloneDataImport.splice(indexClick, 1)
        setDataImport((prev) => ({
            ...prev,
            data: cloneDataImport
        }))
    }

    const handleClickDelRow = (index) => {
        const { childId } = dataTableDeps[index]
        window.COMMON.showModal('#modal-delete')
        window.deleteMethod = () => {
            handleDeleteReportDeps(childId)
        }
    }

    const handleClickEditRow = (index) => {
        const foundParentDeps = dependencies.find((item) => item.code === dataSelectDependencies.value)
        const { childId, code, parentCode } = dataTableDeps[index]
        setDataForEditDeps({
            code,
            parentId: foundParentDeps?._id,
            parentCode,
            childId
        })
        window.COMMON.showModal('#update-deps-modal')
    }

    const handleEditDeps = () => {
        window.COMMON.showModal('#modal-confirm')
        window.confirmMethod = () => {
            associationDepsUpdateFormRef.current.updateReportDeps(async () => {
                if (currentTab === REFERENCE_TAB) {
                    await createDataTable()
                } else {
                    setCacheDataSelectDependencies({})
                    const [dependentId] = activeCategoryIds
                    await onGetListDependencies({
                        category: dependentId,
                        isResetSelectDeps: false
                    })
                }
                window.COMMON.hideModal('#update-deps-modal')
            })
        }
    }

    const handleAddDeps = () => {
        const isValidForm = associationDepsAddFormRef.current.validData()
        if (isValidForm) {
            window.COMMON.showModal('#modal-confirm')
            window.confirmMethod = () => {
                associationDepsAddFormRef.current.submitForm(async () => {
                    const [dependentId] = activeCategoryIds
                    await onGetListDependencies({
                        category: dependentId,
                        isResetSelectDeps: false
                    })
                    window.COMMON.hideModal('#add-deps-modal')
                })
            }
        }
    }

    const createDataDepsSelect = () => {
        return dependencies.map((dependency) => {
            return {
                label: dependency.code,
                value: dependency._id
            }
        })
    }

    const handleSearchReportDepsByRefCode = (e) => {
        const { value } = e.target
        if (window.COMMON.validateTextInput(value)) {
            setSearchReportDepsByRefCode(value)
        }
    }

    const handleOpenModalAddDeps = () => {
        window.COMMON.showModal('#add-deps-modal')
    }

    const handleCloseModalAddDeps = () => {
        const hasData = associationDepsAddFormRef.current.validForResetForm()
        if (hasData) {
            window.COMMON.showModal('#modal-confirm')
            window.closeConfirmMethod = () => window.COMMON.showModal('#add-deps-modal')
            window.confirmMethod = () => {
                associationDepsAddFormRef.current.resetForm()
            }
        }
    }

    useEffect(() => {
        if (!window.COMMON.isEmpty(dependencies) || !window.COMMON.isEmpty(searchReportDepsByRefCode)) {
            createDataTable()
        }
    }, [dataSelectDependencies, dependencies, debouncedSearch, currentTab, pagination.currentPage])

    useImperativeHandle(ref, () => ({
        resetCacheSelect: () => {
            setCacheDataSelectDependencies({})
            setPagination(INIT_PAGINATION)
        }
    }))

    return (
        <>
            <MDBRow className="mt-3 wow fadeIn" data-wow-delay="0.3s">
                <MDBCol className="w-100" md="12">
                    <div className="w-100 p-3 bg-white rounded-lg">
                        <div className="d-flex align-items-center justify-content-between">
                            <h6
                                className="wow fadeInLeft"
                                data-wow-delay="0.3s"
                                style={{ fontSize: '0.9rem', fontWeight: 700 }}
                            >
                                <MDBIcon fa="true" icon="th-list" className="fa-fw" />{' '}
                                {window.I18N('table_dependency_reference')}
                            </h6>
                            <div
                                className="d-flex align-items-center"
                                style={{
                                    gap: '8px'
                                }}
                            >
                                <div className="wow fadeInRight" data-wow-delay="0.3s">
                                    <RadioBox
                                        className="m-0"
                                        data={createDataTabDeps()}
                                        value={currentTab}
                                        handleChange={handleChangeDepsTab}
                                    />
                                </div>
                                <div
                                    className="d-flex align-items-center wow fadeInRight"
                                    data-wow-delay="0.3s"
                                    style={{ gap: '8px' }}
                                >
                                    <ImportExcel
                                        label={window.I18N('btn_import')}
                                        classNameButton="m-0"
                                        id="file-import-deps"
                                        dataKey={'IMPORT_ASSOCIATION_DATA_CONFIG'}
                                        handleFile={handleFileImportConfigs}
                                        disabled={window.COMMON.isEmpty(keysReportCategory)}
                                    />
                                    <MDBBtn
                                        color="primary"
                                        className={`btn-sm m-0`}
                                        onClick={handleOpenModalAddDeps}
                                        style={{ padding: '8px 16px' }}
                                        disabled={window.COMMON.isEmpty(keysReportCategory)}
                                    >
                                        <MDBIcon fa="true" icon="plus" className="fa-fw" />{' '}
                                        {window.I18N('add_manually')}
                                    </MDBBtn>
                                </div>
                            </div>
                        </div>
                        {!window.COMMON.isEmpty(optionsSelectDependencies) ? (
                            <div className="w-100 mt-3 p-2 wow fadeIn" data-wow-delay="0.3s">
                                <div
                                    className="w-100 d-flex align-items-center justify-content-start"
                                    style={{ gap: '8px' }}
                                >
                                    {currentTab === REFERENCE_TAB && (
                                        <div style={{ width: '300px' }}>
                                            <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                                                {keysReportCategory.find((key) => key.active)?.name.split('-')[0]}
                                            </h6>
                                            <ReactSelect
                                                value={dataSelectDependencies}
                                                options={optionsSelectDependencies}
                                                className="w-100 md-form m-0 mt-2"
                                                placeholder={window.I18N('choose_option')}
                                                onChange={handleChangeSelectDeps}
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                                            {window.I18N('search')}
                                        </h6>
                                        <MDBInput
                                            type="text"
                                            outline
                                            value={searchReportDepsByRefCode}
                                            containerClass="m-0 mt-2"
                                            hint={window.I18N('search')}
                                            onChange={(e) => handleSearchReportDepsByRefCode(e)}
                                            maxLength="500"
                                            pattern="\S(.*\S)?"
                                            required
                                        />
                                    </div>
                                </div>
                                <div
                                    className="mt-3 custom-scrollbar"
                                    style={{
                                        minHeight: '300px',
                                        maxHeight: '650px',
                                        overflowY: 'overlay'
                                    }}
                                >
                                    <div className="p-2 wow fadeIn" data-wow-delay="0.3s">
                                        {!window.COMMON.isEmpty(dataTableDeps) ? (
                                            <Table
                                                itemKey={'childId'}
                                                id="table-dependencies"
                                                className="table table-striped p-2"
                                                config={createConfigTable()}
                                                data={dataTableDeps}
                                                handleRowDelClick={handleClickDelRow}
                                                handleRowEditClick={handleClickEditRow}
                                            />
                                        ) : (
                                            <div
                                                className="w-100 d-flex align-items-center justify-content-center"
                                                style={{ height: '100px' }}
                                            >
                                                {window.I18N('empty_data')}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="w-100 d-flex align-items-center justify-content-center"
                                style={{ height: '100px' }}
                            >
                                {window.I18N('empty_data')}
                            </div>
                        )}
                    </div>
                </MDBCol>
            </MDBRow>
            {currentTab === REFERENCE_TAB && (
                <div className="w-full mt-2 p-3 bg-white rounded-lg wow fadeIn" data-wow-delay="0.3s">
                    <Pagination
                        total={pagination.totalDeps}
                        pageSize={pagination.pageSize}
                        currentPage={pagination.currentPage}
                        changePage={handleChangePage}
                    />
                </div>
            )}
            <Modal
                id="modal-import-deps"
                className="modal-xl"
                title={window.I18N('import_data')}
                saveEvent={handleConfirmImport}
                saveBtnProps={{
                    disabled: !window.COMMON.isEmpty(dataImport.data.filter((item) => item.isExisted))
                }}
            >
                <Table
                    id="table-import"
                    className="table"
                    config={dataImport.config}
                    data={dataImport.data}
                    handleRowDelClick={handleDeleteExistedDeps}
                    isImported={true}
                ></Table>
            </Modal>
            <Modal
                saveBtnProps={{
                    style: {
                        width: '100%'
                    },
                    outline: false
                }}
                title={window.I18N('add_data')}
                id="add-deps-modal"
                saveEvent={handleAddDeps}
                handleCloseModal={handleCloseModalAddDeps}
            >
                <AssociationAddDepsForm
                    ref={associationDepsAddFormRef}
                    keysReportCategory={keysReportCategory}
                    checkDataDB={handleCheckImportDataConfigs}
                />
            </Modal>
            <Modal
                saveBtnProps={{
                    style: {
                        width: '100%'
                    },
                    outline: false,
                    disabled: !isChangedDataEditDeps
                }}
                title={window.I18N('update_data')}
                id="update-deps-modal"
                classModalContent="h-75"
                saveEvent={handleEditDeps}
            >
                <AssociationDepsUpdateForm
                    onlyUpdateDependent={currentTab === DEPENDENT_TAB}
                    ref={associationDepsUpdateFormRef}
                    onChangedData={setIsChangedDataEditDeps}
                    dataForEdit={dataForEditDeps}
                    titleDeps={keysReportCategory.find((key) => key.active)?.name?.split('-')[0]}
                    dataDepsSelect={createDataDepsSelect()}
                />
            </Modal>
        </>
    )
}

export default React.forwardRef(AssociationConfigsData)
