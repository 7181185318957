import React, { useEffect, useState } from 'react'
import RadioBox from '../../../../components/RadioBox'
import Table from '../../../../components/Table'
import Pagination from '../../../../components/Pagination'

const ASSOCIATION_CONFIG_TAB = 0
const ASSOCIATION_DATA_TAB = 1

const INIT_PAGINATION = {
    page: 1,
    pageSize: 10,
    totalPages: 0,
    totalReports: 0,
    currentPage: 0
}

const HistoryData = ({ dataKeys, filter }) => {
    const CONFIG_TABLE_DELETED_DEPS = [
        window.COMMON.createCol(window.I18N('no'), '5%', '', '', '', 'INDEX'),
        window.COMMON.createCol(window.I18N('deleted_at'), '15%', '', '', 'updatedAt', 'TEXT'),
        window.COMMON.createCol(
            window.I18N('deleted_by'),
            '20%',
            '',
            '',
            'updatedBy.avatar,updatedBy.name',
            'IMAGE_TEXT'
        ),
        window.COMMON.createCol(window.I18N('code'), '15%', '', '', 'code', 'TEXT'),
        window.COMMON.createCol(window.I18N('category'), '15%', '', '', 'category.name', 'TEXT')
    ]

    const DATA_TABS = [
        { value: ASSOCIATION_CONFIG_TAB, label: window.I18N('configs'), icon: 'cogs' },
        { value: ASSOCIATION_DATA_TAB, label: window.I18N('association_data'), icon: 'database' }
    ]
    const [tab, setTab] = useState(ASSOCIATION_CONFIG_TAB)

    const [dataDeletedReports, setDateDeletedReports] = useState([])
    const [dataDeletedDeps, setDataDeletedDeps] = useState([])
    const [pagination, setPagination] = useState(INIT_PAGINATION)
    const [configDataTableReports, setConfigDataTableReports] = useState([])

    const handleChangeTab = (index) => {
        setPagination(INIT_PAGINATION)
        setTab(index)
    }
    const handleChangePage = (index) => {
        setPagination((prev) => ({ ...prev, page: prev.currentPage + 1, currentPage: index }))
    }

    const createConfigTableReports = (data) => {
        if (window.COMMON.isEmpty(dataKeys) || window.COMMON.isEmpty(data)) return
        const [firstReport] = data
        const { mappingFields } = firstReport
        const keyForTableReports = Object.keys(dataKeys.reportDefaultKeys)
        const nameForTableReports = Object.values(dataKeys.reportDefaultKeys)
        const keysDeps = Object.keys(mappingFields)
        const keysName = [
            ...nameForTableReports.slice(0, 12),
            ...keysDeps.map((key) => window.COMMON.splitCamelCase(key)),
            ...nameForTableReports.slice(12)
        ]
        const mergeKeys = [...keyForTableReports.slice(0, 12), ...keysDeps, ...keyForTableReports.slice(12)]
        const colsReport = mergeKeys.map((key, index) =>
            window.COMMON.createCol(window.I18N(`${keysName[index]}`), 'auto', '', '', `${key}`, 'TEXT')
        )
        const customColsReport = [
            window.COMMON.createCol(window.I18N('no'), '1%', '', '', '', 'INDEX'),
            window.COMMON.createCol(window.I18N('deleted_at'), '2%', '', '', 'updatedAt', 'TEXT'),
            window.COMMON.createCol(
                window.I18N('deleted_by'),
                'auto',
                '',
                '',
                'updatedBy.avatar,updatedBy.name',
                'IMAGE_TEXT'
            ),
            ...colsReport
        ]
        setConfigDataTableReports(customColsReport)
    }

    const getDeletedReports = async (page = null, pageSize = null) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report/get-deleted-reports`,
                method: 'GET',
                input: {
                    params: {
                        page,
                        pageSize: pageSize ? pageSize : pagination.pageSize,
                        ...filter
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationManagementPage.handleGetReports')
                }
            })
            window.hideLoading()
            const { status, metadata } = result
            if (status === 200) {
                const modifiedDataReports = result.metadata.reports?.map((item) => ({
                    ...item,
                    date: window.COMMON_DATE.formatStringToDate(item.date),
                    updatedAt: window.COMMON_DATE.formatStringToDate(item.updatedAt, 'DD/MM/YYYY - HH:mm'),
                    ...item.mappingFields
                }))
                setDateDeletedReports(modifiedDataReports)
                createConfigTableReports(modifiedDataReports)
                setPagination((prev) => ({
                    ...prev,
                    totalPages: metadata.meta.totalPages,
                    totalReports: metadata.meta.totalReports
                }))
            }
            return result
        } catch (error) {
            console.error('🚀 ~ getDeletedReports ~ error:', error)
        }
    }

    const getDeletedDeps = async (page = null, pageSize = null) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category-deps/get-list-deps-deleted`,
                method: 'GET',
                input: {
                    params: {
                        page,
                        pageSize: pageSize ? pageSize : pagination.pageSize,
                        ...filter
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationManagementPage.getDeletedDeps')
                }
            })
            window.hideLoading()
            const { status, metadata } = result
            if (status === 200) {
                const modifiedDataDeps = result.metadata.data?.map((item) => ({
                    ...item,
                    createdAt: window.COMMON_DATE.formatStringToDate(item.createdAt),
                    updatedAt: window.COMMON_DATE.formatStringToDate(item.updatedAt, 'DD/MM/YYYY - HH:mm')
                }))
                setDataDeletedDeps(modifiedDataDeps)
                setPagination((prev) => ({
                    ...prev,
                    totalPages: metadata.meta.totalPages,
                    totalReports: metadata.meta.totalDeps
                }))
            }
            return result
        } catch (error) {
            console.error('🚀 ~ getDeletedDeps ~ error:', error)
        }
    }

    useEffect(() => {
        if (!window.COMMON.isEmpty(filter.fromDate) && !window.COMMON.isEmpty(filter.toDate)) {
            const page = pagination.currentPage + 1
            tab === ASSOCIATION_DATA_TAB ? getDeletedReports(page) : getDeletedDeps(page)
        }
    }, [tab, filter, dataKeys, pagination.currentPage])

    return (
        <div className="mt-2 p-3 bg-white rounded-lg wow fadeIn" data-wow-delay="0.3s">
            <div className="w-full d-flex align-item-center justify-content-end">
                <div className="h-100 d-flex align-items-center">
                    <RadioBox className="m-0" data={DATA_TABS} value={tab} handleChange={handleChangeTab} />
                </div>
            </div>
            {tab === ASSOCIATION_DATA_TAB ? (
                !window.COMMON.isEmpty(dataDeletedReports) ? (
                    <div className="mt-2">
                        <div
                            className="mt-3 w-full custom-scrollbar wow fadeIn"
                            data-wow-delay="0.3s"
                            style={{ overflow: 'overlay' }}
                        >
                            <div style={{ width: '800%' }}>
                                <Table
                                    id="table-deleted-report"
                                    className="table table-striped p-2"
                                    config={configDataTableReports}
                                    data={dataDeletedReports}
                                />
                            </div>
                        </div>
                        <div className="w-full mt-2 p-3 bg-white rounded-lg wow fadeIn" data-wow-delay="0.3s">
                            <Pagination
                                total={pagination.totalReports}
                                pageSize={pagination.pageSize}
                                currentPage={pagination.currentPage}
                                changePage={handleChangePage}
                            />
                        </div>
                    </div>
                ) : (
                    <div
                        className="mt-2 w-100 d-flex align-items-center justify-content-center"
                        style={{ height: '100px' }}
                    >
                        {window.I18N('empty_data')}
                    </div>
                )
            ) : !window.COMMON.isEmpty(dataDeletedDeps) ? (
                <div className="mt-2">
                    <div
                        className="mt-3 w-full custom-scrollbar wow fadeIn"
                        data-wow-delay="0.3s"
                        style={{ overflow: 'overlay' }}
                    >
                        <div>
                            <Table
                                id="table-deleted-deps"
                                className="table table-striped p-2"
                                config={CONFIG_TABLE_DELETED_DEPS}
                                data={dataDeletedDeps}
                            />
                        </div>
                    </div>
                    <div className="w-full mt-2 p-3 bg-white rounded-lg wow fadeIn" data-wow-delay="0.3s">
                        <Pagination
                            total={pagination.totalReports}
                            pageSize={pagination.pageSize}
                            currentPage={pagination.currentPage}
                            changePage={handleChangePage}
                        />
                    </div>
                </div>
            ) : (
                <div
                    className="mt-2 w-100 d-flex align-items-center justify-content-center"
                    style={{ height: '100px' }}
                >
                    {window.I18N('empty_data')}
                </div>
            )}
        </div>
    )
}

export default HistoryData
