import { FormControl, FormControlLabel, FormGroup, Switch } from '@material-ui/core'
import { MDBInput } from 'mdbreact'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import ReactSelect from 'react-select'

const AddKeysForm = React.forwardRef(({ isEdit = false, editKey = '', keysReportCategory }, ref) => {
    const [data, setData] = useState({
        parent: {
            name: 'Dependent Field',
            key: window.COMMON.toCamelCase('Dependent Field'),
            isFilter: true
        },
        child: {
            name: 'Reference Field',
            key: window.COMMON.toCamelCase('Reference Field'),
            isFilter: true
        },
        refReportField: {}
    })
    const [dataKeys, setDataKeys] = useState([])

    const fetchDataKeysReport = async () => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report/get-keys`,
                method: 'GET',
                input: {},
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AddKeysForm.fetchDataKeysReport')
                }
            })
            const dataKeysSelect = Object.entries(result.metadata.reportDefaultKeys).map((item) => {
                const [key, name] = item
                return {
                    label: name,
                    value: key
                }
            })
            setDataKeys(dataKeysSelect)
            handleSelectRefReportField(dataKeysSelect[0])
            window.hideLoading()
            return result
        } catch (error) {
            console.error('🚀 ~ fetchDataKeysReport ~ error:', error)
        }
    }

    useEffect(() => {
        fetchDataKeysReport()
    }, [])

    const handleChangeKeys = (e, key) => {
        const { value, name } = e.target
        if (window.COMMON.validateTextInput(value, 30))
            setData((prev) => ({
                ...prev,
                [name]: { ...data[name], [key]: value, key: window.COMMON.toCamelCase(value) }
            }))
    }

    const handleSelectRefReportField = (data) => {
        setData((prev) => ({ ...prev, refReportField: data }))
    }

    const handleSelectFilter = (e) => {
        const { name } = e.target
        setData((prev) => ({
            ...prev,
            [name]: { ...prev[name], isFilter: !prev[name].isFilter }
        }))
    }

    const generateSelectFilters = () => {
        const { parent, child } = data
        if (window.COMMON.isEmpty(parent.name) || window.COMMON.isEmpty(child.name)) {
            return null
        }
        return (
            <div className="mt-4">
                <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                    {window.I18N('specify_filter')}
                </h6>
                <div className="p-2">
                    {[parent, child].map((item, index) => (
                        <div className="d-flex align-items-center justify-content-between" key={index}>
                            <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 500 }}>
                                {item.name}
                            </h6>
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        className="m-0"
                                        control={
                                            <Switch
                                                color="primary"
                                                checked={data[index === 0 ? 'parent' : 'child'].isFilter}
                                                name={index === 0 ? 'parent' : 'child'}
                                                onChange={(e) => handleSelectFilter(e)}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const getDataForm = () => {
        const result = []
        for (const key in data) {
            if (key === 'refReportField') continue
            result.push({
                name: data[key].name,
                dataKey: data[key].key,
                isFilter: data[key].isFilter,
                refReportField: key === 'child' ? data.refReportField.value : '',
                dependencyKey: key === 'child' ? '' : data.child.key
            })
        }
        return result
    }

    const resetDataForm = () => {
        setData(() => ({
            parent: {
                name: 'Dependent Field',
                key: window.COMMON.toCamelCase('Dependent Field'),
                isFilter: true
            },
            child: {
                name: 'Reference Field',
                key: window.COMMON.toCamelCase('Reference Field'),
                isFilter: true
            },
            refReportField: {}
        }))
        handleSelectRefReportField(dataKeys[0])
    }

    const handleCreateReportCategory = async () => {
        const reportCategories = getDataForm()
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category`,
                method: 'POST',
                input: {
                    data: reportCategories
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AddKeysForm.fetchDataKeysReport')
                }
            })
            window.hideLoading()
            return result
        } catch (error) {
            console.error('🚀 ~ handleCreateReportCategory ~ error:', error)
        }
    }

    const handleUpdateReportCategory = async () => {
        const reportCategories = getDataForm()
        const foundActiveKey = keysReportCategory.find((item) => item.dataKey === editKey)
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category`,
                method: 'PATCH',
                input: {
                    data: {
                        filter: foundActiveKey.dataId.split('-'),
                        data: reportCategories
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AddKeysForm.handleUpdateReportCategory')
                }
            })
            window.hideLoading()
            return result
        } catch (error) {
            console.error('🚀 ~ handleUpdateReportCategory ~ error:', error)
        }
    }

    // TODO: set initial data for edit keys
    useEffect(() => {
        if (isEdit && !window.COMMON.isEmpty(keysReportCategory) && !window.COMMON.isEmpty(editKey)) {
            const foundActiveKey = keysReportCategory.find((item) => item.dataKey === editKey)
            const dataSetEdit = {
                parent: {
                    name: foundActiveKey.name.split('-')[0].trim(),
                    key: window.COMMON.toCamelCase(foundActiveKey.dataKey.split('-')[0]).trim(),
                    isFilter: foundActiveKey.isParentFilter
                },
                child: {
                    name: foundActiveKey.name.split('-')[1].trim(),
                    key: window.COMMON.toCamelCase(foundActiveKey.dataKey.split('-')[1]).trim(),
                    isFilter: foundActiveKey.isChildFilter
                },
                refReportField: dataKeys.find((item) => item.value === foundActiveKey.refReportField)
            }
            setData((prev) => ({ ...prev, ...dataSetEdit }))
        }
    }, [isEdit, editKey, keysReportCategory])

    useImperativeHandle(ref, () => ({
        getDataForm,
        submitForm: async (type = 'create', callback = () => {}) => {
            if (type === 'create') {
                const result = await handleCreateReportCategory()
                if (result.status === 200) {
                    resetDataForm()
                    callback(result)
                }
                return result
            }

            if (type === 'edit') {
                const result = await handleUpdateReportCategory()
                if (result.status === 200) {
                    callback(result)
                }
                return result
            }
        },
        getKeysReport: () => {
            return dataKeys
        }
    }))

    return (
        <form>
            <div>
                <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                    {window.I18N('reference_to_field')} {'*'}
                </h6>
                <ReactSelect
                    value={data.refReportField}
                    options={dataKeys}
                    className="md-form m-0 mt-2"
                    placeholder={window.I18N('choose_option')}
                    onChange={handleSelectRefReportField}
                />
            </div>
            <div className="w-full mt-4">
                <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                    {window.I18N('dependent_field')} {'*'}
                </h6>
                <MDBInput
                    style={{ margin: 0 }}
                    outline
                    type="text"
                    name="parent"
                    containerClass="w-full m-0 mt-2"
                    value={data.parent.name}
                    onChange={(e) => handleChangeKeys(e, 'name')}
                    maxLength="100"
                    pattern="\S(.*\S)?"
                    required
                />
            </div>

            {/* Children field */}
            <div className="w-full mt-4">
                <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                    {window.I18N('reference_field')} {'*'}
                </h6>
                <MDBInput
                    style={{ margin: 0 }}
                    outline
                    type="text"
                    name="child"
                    containerClass="m-0 mt-2"
                    value={data.child.name}
                    onChange={(e) => handleChangeKeys(e, 'name')}
                    maxLength="100"
                    pattern="\S(.*\S)?"
                    required
                />
            </div>

            <>{generateSelectFilters()}</>
        </form>
    )
})

export default AddKeysForm
