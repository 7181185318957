const rewardEvent = {
  ADD_CATEGORY_REWARD: 'ADD_CATEGORY_REWARD',
  EDIT_CATEGORY_REWARD: 'EDIT_CATEGORY_REWARD',
  DEL_CATEGORY_REWARD: 'DEL_CATEGORY_REWARD',
  ADD_REWARD: 'ADD_REWARD',
  EDIT_REWARD: 'EDIT_REWARD',
  DEL_REWARD: 'DEL_REWARD',
  UPDATE_STATUS_REWARD: 'UPDATE_STATUS_REWARD',
  ADD_CODE_REWARD: 'ADD_CODE_REWARD'
};

export default rewardEvent;