import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import Modal from '../../../../components/Modal'
import AddKeysForm from './AddKeysForm'
import AssociationConfigsHeader from './AssociationConfigsHeader'
import AssociationConfigsData from './AssociationConfigsData'

const AssociationConfigs = (props, ref) => {
    const [keysReportCategory, setKeysReportCategory] = useState([])
    const [currentIndexKeysReportCategory, setCurrentIndexKeysReportCategory] = useState(0)
    const [dependencies, setDependencies] = useState([])

    const addKeysFormRef = useRef()
    const associationConfigDataRef = useRef()

    // Get list of dependencies
    const getListDependencies = async ({ category, isResetSelectDeps = true }) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category-deps/get-list-parent`,
                method: 'GET',
                input: {
                    params: {
                        category
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationConfigs.getListDependencies')
                }
            })

            window.hideLoading()
            if (result.status === 200) {
                setDependencies(result.metadata)
            }
            return result
        } catch (error) {
            console.error('🚀 ~ getListDependencies ~ error:', error)
        }
    }

    const getListKeysReportCategory = async (isResetSelectDeps = true) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category/find-dependencies`,
                method: 'GET',
                input: {
                    params: {}
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationConfigs.getListKeysReportCategory')
                }
            })

            window.hideLoading()
            if (result.status === 200) {
                const modifiedKeys = result.metadata.map((item, index) => ({
                    ...item,
                    active: index === currentIndexKeysReportCategory
                }))
                await getListDependencies({
                    category: modifiedKeys.find((item) => item.active)?.dataId.split('-')[0],
                    isResetSelectDeps
                })
                setKeysReportCategory(modifiedKeys)
            }
        } catch (error) {
            console.error('🚀 ~ getListKeysReportCategory ~ error:', error)
        }
    }
    useEffect(() => {
        getListKeysReportCategory()
    }, [])

    const handleSelectCategory = async (indexClick) => {
        associationConfigDataRef.current.resetCacheSelect()
        const foundIndexCurrentActive = keysReportCategory.findIndex((item) => item.active)
        if (foundIndexCurrentActive === indexClick) return
        setCurrentIndexKeysReportCategory(indexClick)
        const updateKeysReportCategory = keysReportCategory.map((item, index) => ({
            ...item,
            active: indexClick === index
        }))
        await getListDependencies({
            category: updateKeysReportCategory.find((item) => item.active)?.dataId.split('-')[0],
            isResetSelectDeps: true
        })
        setKeysReportCategory(updateKeysReportCategory)
    }

    const handleAddReportCategories = () => {
        addKeysFormRef.current.submitForm('create', () => {
            window.COMMON.showMessage('success', 'MSG_CODE_001', window.I18N('MSG_CODE_001'))
            getListKeysReportCategory()
            window.COMMON.hideModal('#add-keys-modal')
        })
    }

    const handleConfirmAddReportCategories = () => {
        window.COMMON.showModal('#modal-save')
        window.saveMethod = handleAddReportCategories
    }

    useImperativeHandle(ref, () => ({
        getKeysReport: () => addKeysFormRef.current.getKeysReport()
    }))

    return (
        <>
            <div className="mt-3 rounded-lg">
                <AssociationConfigsHeader
                    keysReportCategory={keysReportCategory}
                    onGetListKeysReportCategory={getListKeysReportCategory}
                    onResetDataConfig={() => associationConfigDataRef.current.resetCacheSelect()}
                    onSelectCategory={handleSelectCategory}
                />
                <AssociationConfigsData
                    ref={associationConfigDataRef}
                    keysReportCategory={keysReportCategory}
                    dependencies={dependencies}
                    onGetListDependencies={getListDependencies}
                />
            </div>
            <Modal
                saveBtnProps={{
                    style: {
                        width: '100%'
                    },
                    outline: false
                }}
                title={window.I18N('add_data')}
                id="add-keys-modal"
                classModalContent="height-90"
                saveEvent={handleConfirmAddReportCategories}
            >
                <AddKeysForm keysReportCategory={keysReportCategory} ref={addKeysFormRef} />
            </Modal>
        </>
    )
}

export default React.forwardRef(AssociationConfigs)
