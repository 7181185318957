import React, { useState } from 'react'
import HistoryFilter from './HistoryFilter'
import HistoryData from './HistoryData'

const AssociationHistory = ({ dataKeys }) => {
    const [filter, setFilter] = useState({
        fromDate: null,
        toDate: null
    })

    const handleGetFilter = (data) => {
        setFilter(data)
    }

    return (
        <div>
            <HistoryFilter onFilter={handleGetFilter} />
            <HistoryData dataKeys={dataKeys} filter={filter} />
        </div>
    )
}

export default AssociationHistory
