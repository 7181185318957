import React from "react";
import { MDBCardBody } from "mdbreact";
import SharedChartRendering from "./SharedChartRendering";

const CPUDetails = ({ data, timestamps }) => {
  return (
    <MDBCardBody>
      <h6
        className="m-0 font-weight-bold mb-3"
        style={{ fontSize: "1rem" }}
      >{`CPU Usage`}</h6>
      <SharedChartRendering
        data={data}
        timestamps={timestamps}
        maxYAxisValue={100}
        borderColor="#006f91"
        backgroundColor="rgba(195,236,250,0.4)"
      />
    </MDBCardBody>
  );
};

export default CPUDetails;
