import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import useClickOutSide from '../../../hooks/useClickOutSide'

const Dropdown = ({
    children,
    renderDropdown,
    clickOpenDropdown = true,
    openDropdown = true,
    isHover = false,
    dropdownStyle = {},
    onClickOutside = () => {}
}) => {
    const [isDropdown, setIsDropdown] = useState(false)
    const [position, setPosition] = useState({ top: 0, left: 0 })

    const dropdownRef = useRef()
    const dropdownContentRef = useRef()

    const handleToggleDropdown = (e) => {
        e.stopPropagation()
        setIsDropdown(!isDropdown)
    }

    const handleCloseDropdown = () => {
        setIsDropdown(false)
        onClickOutside()
    }

    const handleHoverDropdown = (e) => {
        e.stopPropagation()
        setIsDropdown(true)
    }

    const handleHoverOutsideDropdown = (e) => {
        e.stopPropagation()
        setIsDropdown(false)
    }

    useClickOutSide({
        ref: dropdownContentRef,
        handleOutside: handleCloseDropdown
    })

    useEffect(() => {
        if (openDropdown & !isHover) {
            setIsDropdown(true)
        }
    }, [openDropdown])

    // Tính vị trí dropdown khi mở
    useLayoutEffect(() => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect()
            setPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
                width: rect.width,
                style: {
                    zIndex: 10000
                }
            })
        }
    }, [isDropdown, openDropdown])

    return (
        <div
            ref={dropdownRef}
            style={{ position: 'relative', cursor: isHover ? 'pointer' : '' }}
            onClick={clickOpenDropdown ? handleToggleDropdown : () => {}}
            onMouseEnter={isHover ? handleHoverDropdown : () => {}}
            onMouseLeave={isHover ? handleHoverOutsideDropdown : () => {}}
        >
            {children}
            {isDropdown &&
                openDropdown &&
                ReactDOM.createPortal(
                    <div
                        ref={dropdownContentRef}
                        onClick={(e) => e.stopPropagation()}
                        className="bg-white rounded-lg"
                        style={{
                            width: `${position.width}px`,
                            position: 'absolute',
                            top: position.top,
                            left: position.left,
                            zIndex: position.style.zIndex,
                            boxShadow: '#00000029 0px 2px 5px 0px',
                            ...dropdownStyle
                        }}
                    >
                        {renderDropdown}
                    </div>,
                    document.getElementById('root')
                )}
        </div>
    )
}

export default Dropdown
