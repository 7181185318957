import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import Table from '../../../../components/Table'
import Pagination from '../../../../components/Pagination'
import AssociationFilter from './AssociationFilter'

import AssociationDataDetail from './AssociationDataDetail'
import Modal from '../../../../components/Modal'
import { MDBBtn, MDBIcon } from 'mdbreact'

const DEFAULT_PAGE = 1

const AssociationData = (
    { dataKeys, allDependencies, onGetFilterReports = () => {}, onUpdateReport = () => {} },
    ref
) => {
    const [dataReports, setDataReports] = useState([])
    const [dataUpdateReports, setDataUpdateReports] = useState([])
    const [dataDetailReport, setDataDetailReport] = useState({})
    const [pagination, setPagination] = useState({
        page: DEFAULT_PAGE,
        pageSize: 8,
        totalPages: 0,
        totalReports: 0,
        currentPage: 0
    })
    const [paginationUpdate, setPaginationUpdate] = useState({
        pageSize: 6,
        totalPages: 0,
        totalReports: 0,
        currentPage: 0
    })
    const [dataUpdateWithPagination, setDataUpdateWithPagination] = useState([])

    const [filters, setFilters] = useState({})

    const [isChangedDataReport, setIsChangedDataReport] = useState(false)

    const associationDataDetailRef = useRef()
    const associationFilterRef = useRef()

    const handleChangeFilters = (dataFilters = {}) => {
        setFilters(dataFilters)
    }

    //Change page
    const handleChangePageUpdate = (index) => {
        setPaginationUpdate((prev) => ({ ...prev, currentPage: index }))
    }

    const handleChangePage = (index) => {
        setPagination((prev) => ({ ...prev, page: prev.currentPage + 1, currentPage: index }))
        handleGetReports({ page: index + 1 })
    }

    const createConfigTable = (data) => {
        if (window.COMMON.isEmpty(data)) return
        let listKeysToCols = []
        Object.entries(data).forEach((item) => {
            const [key] = item
            listKeysToCols.push(window.COMMON.createCol(window.I18N(`${key}`), 'auto', '', '', key, 'TEXT'))
        })

        const keysConfig = allDependencies.map((dependency) => {
            const {
                ref: { dataKey: refKey, name: refName },
                parent: { dataKey: parentKey, name: parentName }
            } = dependency
            return [
                {
                    alignment: '',
                    attr: parentKey,
                    className: '',
                    colName: parentName,
                    type: 'TEXT',
                    width: 'auto'
                },
                {
                    alignment: '',
                    attr: refKey,
                    className: '',
                    colName: refName,
                    type: 'TEXT',
                    width: 'auto'
                }
            ]
        })
        const configs = [
            window.COMMON.createCol(window.I18N('no'), '1%', '', '', '', 'INDEX'),
            window.COMMON.createCol(window.I18N('updated_date'), '2%', '', '', 'updatedAt', 'TEXT'),
            window.COMMON.createCol(
                window.I18N('updated_by'),
                'auto',
                '',
                '',
                'updatedBy.avatar,updatedBy.name',
                'IMAGE_TEXT'
            ),
            ...listKeysToCols.slice(0, 12),
            ...keysConfig.flat(),
            ...listKeysToCols.slice(12)
        ]

        return configs
    }

    const getReports = async (page = null, pageSize = null) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report/get-reports`,
                method: 'GET',
                input: {
                    params: {
                        page,
                        pageSize: pageSize ? pageSize : pagination.pageSize,
                        ...filters
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationManagementPage.handleGetReports')
                }
            })
            window.hideLoading()
            return result
        } catch (error) {
            console.error('🚀 ~ getReports ~ error:', error)
        }
    }

    const handleGetReports = async ({ page = null, pageSize = null }) => {
        try {
            const result = await getReports(page, pageSize)
            const { status, metadata } = result
            if (status === 200) {
                const modifiedDataReports = result.metadata.reports?.map((item) => ({
                    ...item,
                    date: window.COMMON_DATE.formatStringToDate(item.date),
                    updatedAt: window.COMMON_DATE.formatStringToDate(item.updatedAt),
                    ...item.mappingFields
                }))
                setDataReports(modifiedDataReports)
                setPagination((prev) => ({
                    ...prev,
                    totalPages: metadata.meta.totalPages,
                    totalReports: metadata.meta.totalReports
                }))
            }
            return result
        } catch (error) {
            console.error('🚀 ~ handleGetReports ~ error:', error)
        }
    }

    const handleOpenDetailReport = (index) => {
        // TODO: Open modal detail report
        window.COMMON.showModal('#modal-detail-report')
        setDataDetailReport(dataReports[index])
    }

    const updateReportsWithNewDependencies = async ({ dataUpdate }) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report/update-multi-report`,
                method: 'PATCH',
                input: {
                    data: {
                        dataUpdate
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationData.updateReportsWithNewDependencies')
                }
            })
            window.hideLoading()
            const { status } = result
            if (status === 200) {
                window.COMMON.showMessage('success', 'MSG_CODE_002', window.I18N('MSG_CODE_002'))
                window.COMMON.hideModal('#modal-update-multi-report')
                handleGetReports({ page: pagination.page })
            }
            return result
        } catch (error) {
            console.error('🚀 ~ updateReportsWithNewDependencies ~ error:', error)
        }
    }

    const handleDataForUpdateWithNewDeps = async () => {
        try {
            const ALL_REPORTS = pagination.totalReports
            const DEFAULT_PAGE = pagination.currentPage + 1
            const { status, metadata } = await getReports(DEFAULT_PAGE, ALL_REPORTS)
            if (status === 200) {
                const { reports } = metadata
                onUpdateReport(
                    {
                        data: reports.map((data) => ({
                            ...data,
                            date: window.COMMON_DATE.convertToExcelDate(data.date)
                        }))
                    },
                    (dataUpdated) => {
                        const { reportDefaultKeys } = dataKeys
                        const getKeyReportDefaultKeys = Object.keys(reportDefaultKeys)
                        const configsTable = getKeyReportDefaultKeys.map((item) =>
                            window.COMMON.createCol(window.I18N(`${item}`), 'auto', '', '', `${item}`, 'TEXT')
                        )
                        setDataUpdateReports({
                            ...dataUpdated,
                            config: [
                                window.COMMON.createCol(window.I18N('no'), '1%', '', '', '', 'INDEX'),
                                ...configsTable.slice(0, 12),
                                ...dataUpdated.keysConfigTableDeps,
                                ...configsTable.slice(12),
                                window.COMMON.createCol(window.I18N('action'), '5%', '', '', '-1', 'BUTTON')
                            ]
                        })
                        window.COMMON.showModal('#modal-update-multi-report')
                    }
                )
            }
        } catch (error) {
            console.error('🚀 ~ handleDataForUpdateWithNewDeps ~ error:', error)
        }
    }

    const handleUpdateReport = () => {
        associationDataDetailRef.current.updateDataReport(() => {
            handleGetReports({ page: pagination.page })
            window.COMMON.hideModal('#modal-detail-report')
        })
    }

    const handleDeleteReport = () => {
        associationDataDetailRef.current.deleteDataReport(() => {
            handleGetReports({ page: pagination.page })
            window.COMMON.hideModal('#modal-detail-report')
        })
    }

    const handleConfirmUpdateReport = () => {
        window.COMMON.showModal('#modal-save')
        window.saveMethod = handleUpdateReport
    }

    const handleConfirmDeleteReport = () => {
        window.COMMON.showModal('#modal-delete')
        window.deleteMethod = handleDeleteReport
    }

    const handleConfirmMappingWithNewDependencies = () => {
        window.COMMON.showModal('#modal-confirm')
        window.confirmMethod = handleDataForUpdateWithNewDeps
    }

    const handleConfirmUpdateMultiReports = () => {
        window.COMMON.showModal('#modal-save')
        window.saveMethod = () => {
            const { dataForUpdate } = dataUpdateReports
            updateReportsWithNewDependencies({ dataUpdate: dataForUpdate })
        }
    }

    const handleRemoveItemUpdateReport = (indexDel) => {
        setDataUpdateReports((prev) => ({
            ...prev,
            rootData: prev.rootData.filter((_, index) => index !== indexDel),
            dataForUpdate: prev.dataForUpdate.filter((_, index) => index !== indexDel)
        }))
    }

    useEffect(() => {
        if (!window.COMMON.isEmpty(filters)) {
            handleGetReports({ page: DEFAULT_PAGE })
            setPagination((prev) => ({ ...prev, currentPage: 0 }))
            onGetFilterReports(filters)
        }
    }, [filters])

    const splitDataUpdateWithPagination = ({ page = 1, pageSize = 7 }) => {
        if (window.COMMON.isEmpty(dataUpdateReports?.rootData)) return []
        const cloneDataImport = window.COMMON.deepCopyArray(dataUpdateReports?.rootData)
        const dataSliced = cloneDataImport.slice((page - 1) * pageSize, page * pageSize)
        setDataUpdateWithPagination(dataSliced)
    }

    useEffect(() => {
        if (!window.COMMON.isEmpty(dataUpdateReports)) {
            splitDataUpdateWithPagination({
                page: paginationUpdate.currentPage + 1,
                pageSize: paginationUpdate.pageSize
            })
            setPaginationUpdate((prev) => ({
                ...prev,
                totalPages: Math.ceil(dataUpdateReports?.rootData.length / prev.pageSize),
                totalReports: dataUpdateReports?.rootData.length
            }))
        }
    }, [dataUpdateReports])

    useEffect(() => {
        if (!window.COMMON.isEmpty(dataUpdateReports?.rootData)) {
            const { currentPage, pageSize } = paginationUpdate
            splitDataUpdateWithPagination({ page: currentPage + 1, pageSize })
        }
    }, [paginationUpdate.currentPage])

    useImperativeHandle(ref, () => {
        return {
            refetchReports: async () => {
                await handleGetReports({ page: DEFAULT_PAGE })
                setPagination((prev) => ({ ...prev, currentPage: 0 }))
                onGetFilterReports(filters)
                associationFilterRef.current.refetchDataFilters()
            }
        }
    })

    return (
        <div>
            <AssociationFilter
                ref={associationFilterRef}
                dataKeys={dataKeys}
                allDependencies={allDependencies}
                onFilters={handleChangeFilters}
            />

            <div className="w-full mt-3 p-3 bg-white rounded-lg wow fadeInLeft" data-wow-delay="0.3s">
                <div className="mt-2 d-flex align-items-center justify-content-between">
                    <h6 className="m-0" style={{ fontSize: '0.9rem', fontWeight: 700 }}>
                        <MDBIcon fa="true" icon="tasks" className="fa-fw" /> {window.I18N('association_data')}
                    </h6>
                    <MDBBtn
                        color="primary"
                        className="btn"
                        disabled={window.COMMON.isEmpty(dataReports)}
                        onClick={handleConfirmMappingWithNewDependencies}
                    >
                        <MDBIcon fa="true" icon="edit" className="fa-fw" /> {window.I18N('updated_with_new_reference')}
                    </MDBBtn>
                </div>
                {!window.COMMON.isEmpty(dataReports) ? (
                    <div className="mt-3 w-full custom-scrollbar" style={{ overflow: 'overlay' }}>
                        <div style={{ width: '800%', maxHeight: '500px' }}>
                            <Table
                                id="table-import-report"
                                className="table table-striped p-2"
                                config={createConfigTable(dataKeys.reportDefaultKeys)}
                                data={dataReports}
                                clickRow={handleOpenDetailReport}
                            />
                        </div>
                    </div>
                ) : (
                    <div
                        className="mt-2 w-100 d-flex align-items-center justify-content-center"
                        style={{ height: '100px' }}
                    >
                        {window.I18N('empty_data')}
                    </div>
                )}
            </div>
            <div className="w-full mt-2 p-3 bg-white rounded-lg wow fadeIn" data-wow-delay="0.3s">
                <Pagination
                    total={pagination.totalReports}
                    pageSize={pagination.pageSize}
                    currentPage={pagination.currentPage}
                    changePage={handleChangePage}
                />
            </div>
            <Modal
                id="modal-detail-report"
                className="modal-xl"
                title={window.I18N('Thông tin chi tiết')}
                saveEvent={() => handleConfirmUpdateReport()}
                saveBtn={window.I18N('btn_edit')}
                icon="edit"
                hideReject={false}
                rejectBtn={window.I18N('btn_delete')}
                rejectEvent={() => handleConfirmDeleteReport()}
                rejectIcon="trash"
                saveBtnProps={{
                    disabled: !isChangedDataReport
                }}
            >
                <AssociationDataDetail
                    ref={associationDataDetailRef}
                    onChangeData={setIsChangedDataReport}
                    refetchReports={() => {
                        handleGetReports({ page: pagination.page })
                        window.COMMON.hideModal('#modal-delete')
                    }}
                    dataReport={dataDetailReport}
                    dataKeys={dataKeys}
                    allDependencies={allDependencies}
                />
            </Modal>
            {/* Modal for update multiple reports */}
            <Modal
                id="modal-update-multi-report"
                className="modal-xl"
                title={window.I18N('update_data')}
                handleCloseModal={() => {
                    setDataUpdateReports({})
                    setDataUpdateWithPagination([])
                }}
                saveEvent={handleConfirmUpdateMultiReports}
                saveBtnProps={{
                    disabled: false
                }}
                styleBody={{
                    position: 'relative'
                }}
            >
                <>
                    <div style={{ width: '800%' }}>
                        <Table
                            id="table-import-report"
                            className="table"
                            itemKey={'_id'}
                            config={dataUpdateReports.config}
                            data={dataUpdateWithPagination}
                            handleRowDelClick={handleRemoveItemUpdateReport}
                            isImported={true}
                        />
                    </div>
                    <div
                        className="w-full mt-2 p-3 bg-white rounded-lg wow fadeIn"
                        data-wow-delay="0.3s"
                        style={{
                            position: 'sticky',
                            bottom: 0,
                            left: '20px'
                        }}
                    >
                        <Pagination
                            total={paginationUpdate.totalReports}
                            pageSize={paginationUpdate.pageSize}
                            currentPage={paginationUpdate.currentPage}
                            changePage={handleChangePageUpdate}
                        />
                    </div>
                </>
            </Modal>
        </div>
    )
}

export default React.forwardRef(AssociationData)
