import moment from 'moment'

const CommonDate = {
    addDays(date, days) {
        const result = new Date(date)
        result.setDate(result.getDate() + days)
        return result
    },
    addDaysFromNow(days) {
        const result = new Date()
        result.setDate(result.getDate() + days)
        return result
    },
    addMonths(date, months) {
        const result = new Date(date)
        result.setMonth(result.getMonth() + months)
        return result
    },
    addMonthsFromNow(months) {
        const result = new Date()
        result.setMonth(result.getMonth() + months)
        return result
    },
    addYearsFromNow(years) {
        const result = new Date()
        result.setFullYear(result.getFullYear() + years)
        return result
    },
    addYearsFromCustomDate(date, years) {
        const result = new Date(date)
        result.setFullYear(result.getFullYear() + years)
        return result
    },
    compareWithNow(date, isTime = false, isHours = false) {
        let now = new Date()
        const valueDate = new Date(date.toString())
        if (!isTime) {
            now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
            valueDate.setHours(0)
            valueDate.setMinutes(0)
            valueDate.setSeconds(0)
        }
        if (isHours) {
            now = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                new Date().getHours(),
                0,
                0
            )
        }
        const compareDate = new Date(valueDate.toString())
        if (now.getTime() === compareDate.getTime()) {
            return 0
        }
        if (now.getTime() > compareDate.getTime()) {
            return window.CONSTANT.TIME_IN_PAST
        }
        return window.CONSTANT.TIME_IN_FUTURE
    },
    getStrDateFilter(date, mode) {
        if (mode === -1) {
            date.setHours(0, 0, 0, 0)
        } else {
            date.setHours(23, 59, 59, 0)
        }
        return date.toString()
    },
    getDiffDate(start, end) {
        const diffTime = Math.abs(end - start)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays
    },
    getFormatDate(date, format = 'DD/MM/YYYY') {
        if (!date) {
            return ''
        }
        return moment(date).format(format)
    },
    formatDate(value, format = 'DD/MM/YYYY') {
        if (!value) {
            return ''
        }
        const date = new Date(+value)
        return moment(date).format(format)
    },
    formatDateJS(value) {
        return (
            ('0' + value.getDate()).slice(-2) +
            '/' +
            ('0' + (value.getMonth() + 1)).slice(-2) +
            '/' +
            value.getFullYear()
        )
    },
    formatStringToDate(value, format = 'DD/MM/YYYY') {
        if (!value) {
            return ''
        }
        const date = new Date(value)
        return moment(date).format(format)
    },
    convertStringToDate(str, char) {
        const arrs = str.split(char)
        if (arrs.length > 2) {
            const date = new Date(Number(arrs[2]), Number(arrs[1]) - 1, Number(arrs[0]))
            return date
        }
        return new Date()
    },
    daysInMonth(date) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    },
    getDateFollowPastDate({ pastDays = '7', mode = 1, convertStrDateFilter = true }) {
        let newDate = null
        if (!pastDays.includes('year') && !isNaN(pastDays)) {
            const daysNumber = Number(pastDays)
            //1-7 = -6 ==> get date on previous 6 days, seventh day is current day
            newDate = this.addDaysFromNow(1 - daysNumber)
        } else {
            const yearArray = pastDays.split(' ')
            const yearNumber = yearArray[0]
            newDate = new Date()
            newDate.setFullYear(newDate.getFullYear() - yearNumber)
        }

        if (newDate !== null && convertStrDateFilter === true) {
            return this.getStrDateFilter(newDate, mode)
        }
        return newDate
    },
    roundToNextInterval(date, interval) {
        const ms = 1000 * 60 * interval
        return new Date(Math.ceil(date.getTime() / ms) * ms)
    },
    parseDate(dateTimeStr, format) {
        // Split the format string into an array (e.g., ['dd', 'mm', 'yyyy', 'hh', 'MM', 'ss'])
        const formatParts = format.split(/[- :]/)

        // Split the input date-time string into an array
        const dateTimeParts = dateTimeStr.split(/[- :]/)

        // Initialize default values for day, month, year, hours, minutes, and seconds
        let day,
            month,
            year,
            hours = 0,
            minutes = 0,
            seconds = 0

        // Iterate through the format parts to assign the corresponding values from the input string
        formatParts.forEach((part, index) => {
            const value = parseInt(dateTimeParts[index], 10) // Convert to integer

            // Match each format part and assign the value to the appropriate variable
            switch (part) {
                case 'dd':
                    day = value
                    break
                case 'mm':
                    month = value - 1 // Subtract 1 because JavaScript months are 0-based
                    break
                case 'yyyy':
                    year = value
                    break
                case 'hh':
                    hours = value
                    break
                case 'MM':
                    minutes = value
                    break
                case 'ss':
                    seconds = value
                    break
            }
        })

        // Create and return a new Date object with the parsed values
        return new Date(year, month, day, hours, minutes, seconds)
    },
    isValidDate(date) {
        // Check if the input is a valid Date object
        if (date instanceof Date && !isNaN(date.getTime())) {
            return true
        }

        // Check if the input is a valid timestamp (number or string representing a number)
        if (typeof date === 'number' || (typeof date === 'string' && !isNaN(Number(date)))) {
            const timestamp = new Date(Number(date)).getTime()
            return !isNaN(timestamp) // Ensure the timestamp is valid
        }

        return false // Return false if it's neither a valid Date object nor a valid timestamp
    },
    formatAgoTime(timestamp, language = 'VI') {
        const now = new Date()
        const diff = now - new Date(timestamp) // Difference in milliseconds
        const second = 1000
        const minute = second * 60
        const hour = minute * 60
        const day = hour * 24
        const month = day * 30 // 30 days in a month as approximation
        const year = month * 12

        const elapsedYears = Math.floor(diff / year)
        const elapsedMonths = Math.floor(diff / month)
        const elapsedDays = Math.floor(diff / day)
        const elapsedHours = Math.floor(diff / hour)
        const elapsedMinutes = Math.floor(diff / minute)
        const elapsedSeconds = Math.floor(diff / second)

        // Language selection for messages
        const messages = {
            VI: {
                justNow: 'Vừa mới',
                years: 'năm trước',
                months: 'tháng trước',
                days: 'ngày trước',
                hours: 'giờ trước',
                minutes: 'phút trước',
                seconds: 'giây trước'
            },
            EN: {
                justNow: 'Just now',
                years: 'years ago',
                months: 'months ago',
                days: 'days ago',
                hours: 'hours ago',
                minutes: 'minutes ago',
                seconds: 'seconds ago'
            }
        }

        const selectedLang = messages[language] || messages.vi

        if (elapsedYears > 0) {
            return `${elapsedYears} ${selectedLang.years}`
        } else if (elapsedMonths > 0) {
            return `${elapsedMonths} ${selectedLang.months}`
        } else if (elapsedDays > 0) {
            return `${elapsedDays} ${selectedLang.days}`
        } else if (elapsedHours > 0) {
            return `${elapsedHours} ${selectedLang.hours}`
        } else if (elapsedMinutes > 0) {
            return `${elapsedMinutes} ${selectedLang.minutes}`
        } else if (elapsedSeconds > 0) {
            return `${elapsedSeconds} ${selectedLang.seconds}`
        } else {
            return selectedLang.justNow
        }
    },
    convertToISOString(dateStr) {
        // Tách ngày, tháng, năm từ chuỗi
        const [day, month, year] = dateStr.split('/')

        // Tạo Date object (Chú ý: Tháng trong JS bắt đầu từ 0 => Trừ đi 1)
        const dateObj = new Date(Date.UTC(year, month - 1, day))

        // Trả về chuỗi ISO String
        return dateObj.toISOString()
    },
    convertToExcelDate(isoString) {
        const date = new Date(isoString)
        // Bỏ giờ, chỉ lấy ngày bằng UTC để chính xác
        const year = date.getUTCFullYear()
        const month = date.getUTCMonth()
        const day = date.getUTCDate()

        const dateOnlyUTC = new Date(Date.UTC(year, month, day))

        const excelEpoch = new Date(Date.UTC(1899, 11, 30)) // Excel mốc 1899-12-30
        const diffInMs = dateOnlyUTC - excelEpoch
        const excelDate = diffInMs / (1000 * 60 * 60 * 24) // ms → ngày

        return excelDate
    }
}

export default CommonDate
