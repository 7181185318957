import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import ReactSelect from 'react-select'
import ReactDatePicker from 'react-datepicker'
import Dropdown from '../../../../components/Dropdown'
import { useDebounce } from '../../../../../hooks/useSearchDebounce'

const AssociationFilter = ({ dataKeys, allDependencies, onFilters = () => {} }, ref) => {
    const [initial, setInitial] = useState(true)

    const [dataFilters, setDataFilters] = useState([])
    const [dataFiltersWithDeps, setDataFiltersWithDeps] = useState([])

    const [filters, setFilters] = useState({})
    const [filtersWithDeps, setFiltersWithDeps] = useState({})

    const [textSearch, setTextSearch] = useState('')
    const [dataSearchReport, setDataSearchReport] = useState([])

    const debounceDataSearch = useDebounce(textSearch, 300)

    const handleSelectFilter = (value, name) => {
        // TODO: Implement logic to handle filter selection
        setFilters((prev) => ({ ...prev, [name]: value }))
    }

    const handleSelectFilterWithDeps = (value, name) => {
        setFiltersWithDeps((prev) => ({ ...prev, [name]: value }))
        handleChangeDataRefByParent(name, value)
    }

    const handleChangeDateFilter = (valueDate, name) => {
        setFilters((prev) => ({ ...prev, [name]: valueDate }))
    }

    const handleChangeDataSearch = (e) => {
        const { value } = e.target
        if (window.COMMON.validateTextInput(value)) {
            setTextSearch(value)
        }
    }

    const handleSetFilterSearch = (value) => {
        setFilters((prev) => ({ ...prev, searchFilter: { label: value, value } }))
        setTextSearch('')
    }

    const handleModifiedFilters = () => {
        let filterSet = {}
        for (const key in filters) {
            const dataSet = filters[key]
            if (dataSet.value === 'all') continue
            const [splitKey] = key.split('Filter')
            if (splitKey.includes('search') && dataSet.value === '') continue
            if (dataSet instanceof Date && ['fromDate', 'toDate'].includes(key)) {
                const filterMode = {
                    fromDate: window.CONSTANT.FROM_FILTER_DATE,
                    toDate: window.CONSTANT.TO_FILTER_DATE
                }
                filterSet[key] = window.COMMON_DATE.getStrDateFilter(dataSet, filterMode[key])
                continue
            }
            filterSet[splitKey] = dataSet.value
        }
        // Generate filter for mappingFields
        const mappingFields = {}
        for (const depsKey in filtersWithDeps) {
            const dataSet = filtersWithDeps[depsKey]
            if (dataSet.value === 'all') continue
            mappingFields[`mappingFields.${depsKey}`] = dataSet.value
        }
        return window.COMMON.isEmpty(mappingFields) ? filterSet : { ...filterSet, ...mappingFields }
    }

    const createFilters = (filters) => {
        const dataFilterSet = {}

        for (const key in filters) {
            const filter = filters[key]
            for (let i = 0; i < filter.length; i++) {
                const element = filter[i]
                if (dataFilterSet[key]) {
                    dataFilterSet[key].push({
                        label: !element._id ? window.I18N('empty') : element._id,
                        value: !element._id ? '' : element._id
                    })
                } else {
                    dataFilterSet[key] = [
                        {
                            label: window.I18N('all'),
                            value: 'all'
                        },
                        {
                            label: !element._id ? window.I18N('empty') : element._id,
                            value: !element._id ? '' : element._id
                        }
                    ]
                }
            }
        }

        const filtersSet = {}
        for (const key in dataFilterSet) {
            const [firstElement] = dataFilterSet[key]
            filtersSet[key] = firstElement
        }
        const dataDateSet = { fromDate: window.COMMON_DATE.addDaysFromNow(-365 * 2), toDate: new Date() }
        setDataFilters((prev) => ({ ...prev, ...dataFilterSet }))
        setFilters((prev) => ({ ...prev, ...dataDateSet, ...filtersSet }))
    }

    const createFiltersWithDependencies = () => {
        const filterSet = allDependencies.reduce(
            (acc, item) => {
                const { data, parent, ref } = item
                const dataFilterParent = parent.isFilter
                    ? {
                          name: parent.name,
                          data: [
                              {
                                  label: window.I18N('all'),
                                  value: 'all'
                              },
                              {
                                  label: window.I18N('empty'),
                                  value: ''
                              },
                              ...Object.keys(data).map((item) => ({ label: item, value: item }))
                          ],
                          dependencyKey: parent.dependencyKey,
                          dependencyName: ref.name
                      }
                    : null
                let dataFilterRef = {
                    name: ref.name,
                    data: [
                        {
                            label: window.I18N('all'),
                            value: 'all'
                        },
                        {
                            label: window.I18N('empty'),
                            value: ''
                        },
                        ...Object.values(data)
                            .flat(2)
                            .map((item) => ({ label: item, value: item }))
                    ]
                }

                if (!ref.isFilter) {
                    dataFilterRef = null
                }

                acc.dataFilters.push(dataFilterParent, dataFilterRef)

                return acc
            },
            {
                dataFilters: []
            }
        )
        const removeItemNull = filterSet.dataFilters.filter((item) => item)
        setDataFiltersWithDeps(removeItemNull)
        setFiltersWithDeps(
            removeItemNull.reduce((acc, item) => {
                const { name, data } = item
                acc[window.COMMON.toCamelCase(name)] = data[0]
                return acc
            }, {})
        )
    }

    const handleChangeDataRefByParent = (parentKey = '', dataParentSelect = {}) => {
        const foundParent = dataFiltersWithDeps.find((item) => window.COMMON.toCamelCase(item.name) === parentKey)
        if (!foundParent?.dependencyKey) {
            return
        }
        const foundRefIndex = dataFiltersWithDeps.findIndex((item) => item.name === foundParent.dependencyName)
        const foundRefData = dataFiltersWithDeps.find((item) => item.name === foundParent.dependencyName)
        const foundDependency = allDependencies.find((deps) => deps.ref.dataKey === foundParent.dependencyKey)

        if (!window.COMMON.isEmpty(foundDependency) && !['all', ''].includes(dataParentSelect.value)) {
            const { data } = foundDependency
            const dataChanged = [
                {
                    label: window.I18N('all'),
                    value: 'all'
                },
                {
                    label: window.I18N('empty'),
                    value: ''
                },
                ...data[dataParentSelect.value].map((item) => ({ label: item, value: item }))
            ]
            const deepCopyFilterWithDeps = window.COMMON.deepCopyArray(dataFiltersWithDeps)
            deepCopyFilterWithDeps.splice(foundRefIndex, 1, { ...foundRefData, data: dataChanged })
            setFiltersWithDeps((prev) => ({ ...prev, [foundParent.dependencyKey]: dataChanged[0] }))
            setDataFiltersWithDeps(deepCopyFilterWithDeps)
        }
    }

    const resetFilters = () => {
        const filtersSet = {}
        for (const key in dataFilters) {
            const [firstElement] = dataFilters[key]
            filtersSet[key] = firstElement
        }

        const dataDateSet = {
            fromDate: window.COMMON_DATE.addDaysFromNow(-365 * 2),
            toDate: new Date(),
            searchFilter: { label: '', value: '' }
        }
        setFilters((prev) => ({ ...prev, ...dataDateSet, ...filtersSet }))
        createFiltersWithDependencies()
    }

    const handleSearchReportsWithName = async () => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report/search`,
                method: 'GET',
                input: {
                    params: {
                        ...handleModifiedFilters(),
                        search: debounceDataSearch
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationManagementPage.handleGetReports')
                }
            })
            window.hideLoading()
            if (result.status === 200) {
                setDataSearchReport(result.metadata.map((item) => item.goodsType))
            }
            return result
        } catch (error) {
            console.error('🚀 ~ handleSearchReportsWithName ~ error:', error)
        }
    }

    const handleRenderSearchDropdown = () => {
        return (
            <div
                className="p-2 custom-scrollbar"
                style={{ maxHeight: '300px', overflowX: 'hidden', overflowY: 'overlay', overSrollBehavior: 'contain' }}
            >
                {window.COMMON.removeDuplicateElements(dataSearchReport).map((item, index) => (
                    <div
                        onClick={() => handleSetFilterSearch(item)}
                        key={index}
                        className="p-2 rounded-lg list-category"
                        style={{ textAlign: 'justify', cursor: 'pointer' }}
                    >
                        {item}
                    </div>
                ))}
            </div>
        )
    }

    const getFilters = async () => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report/get-filters`,
                method: 'GET',
                input: {},
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationManagementPage.handleGetReports')
                }
            })
            window.hideLoading()
            if (result.status === 200) {
                createFilters(result.metadata[0])
            }
            return result
        } catch (error) {
            console.error('🚀 ~ getFilters ~ error:', error)
        }
    }

    useEffect(() => {
        if (!window.COMMON.isEmpty(allDependencies)) {
            createFiltersWithDependencies()
        }
    }, [allDependencies])

    useEffect(() => {
        getFilters()
    }, [])

    useEffect(() => {
        if (!window.COMMON.isEmpty(filters) && !window.COMMON.isEmpty(filtersWithDeps) && initial) {
            onFilters(handleModifiedFilters())
            setInitial(false)
        }
    }, [filters, filtersWithDeps])

    useEffect(() => {
        if (!window.COMMON.isEmpty(textSearch)) {
            handleSearchReportsWithName()
        } else {
            setDataSearchReport([])
        }
    }, [debounceDataSearch])

    useImperativeHandle(ref, () => {
        return {
            refetchDataFilters: async () => {
                return await getFilters()
            }
        }
    })

    return (
        <div className="bg-white wow fadeInLeft" data-wow-delay="0.3s">
            <div className="w-100 mt-3 p-3 rounded-lg">
                <div className="w-100 h-100 d-flex align-items-center justify-content-start">
                    <div className="w-33 px-2 my-2">
                        <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                            {window.I18N('search_by_product_category')}
                        </h6>
                        <div className="w-100 mt-2">
                            <Dropdown
                                clickOpenDropdown={false}
                                openDropdown={!window.COMMON.isEmpty(dataSearchReport)}
                                renderDropdown={handleRenderSearchDropdown()}
                                onClickOutside={() => {
                                    setTextSearch('')
                                    setDataSearchReport([])
                                }}
                            >
                                <MDBInput
                                    type="text"
                                    outline
                                    value={textSearch || filters?.searchFilter?.value}
                                    containerClass="m-0"
                                    hint={window.I18N('search')}
                                    onChange={(e) => handleChangeDataSearch(e)}
                                    onFocus={() => handleSetFilterSearch('')}
                                    onBlur={() => handleSetFilterSearch('')}
                                    maxLength="500"
                                    pattern="\S(.*\S)?"
                                    required
                                />
                            </Dropdown>
                        </div>
                    </div>
                    <div className="flex-1 d-flex align-items-center px-2" style={{ gap: '16px' }}>
                        <div className="w-50 my-2">
                            <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                {`${window.I18N('from_date')}*`}
                            </h6>
                            <div className="input-group input-group-transparent">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                    </span>
                                </div>
                                <ReactDatePicker
                                    name="fromDate"
                                    placeholderText={window.I18N('from_date') + ' *'}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    selected={filters.fromDate}
                                    maxDate={new Date(filters.toDate)}
                                    onChange={(event) => handleChangeDateFilter(event, 'fromDate')}
                                    showYearDropdown
                                    showMonthDropdown
                                    required
                                />
                            </div>
                        </div>

                        <div className="w-50 my-2">
                            <h6 className="font-weight-bold" style={{ fontSize: '0.8rem' }}>
                                {`${window.I18N('to_date')}*`}
                            </h6>
                            <div className="input-group input-group-transparent">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                    </span>
                                </div>
                                <ReactDatePicker
                                    name="toDate"
                                    placeholderText={window.I18N('to_date') + ' *'}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy"
                                    selected={filters.toDate}
                                    minDate={new Date(filters.fromDate)}
                                    onChange={(event) => handleChangeDateFilter(event, 'toDate')}
                                    showYearDropdown
                                    showMonthDropdown
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="w-100 h-100 d-flex align-items-center justify-content-start"
                    style={{ width: '60%', flexWrap: 'wrap' }}
                >
                    {/*Render Filters  */}
                    {Object.entries(dataFilters).map((item, index) => {
                        const [name, data] = item
                        return (
                            <div key={index} className="w-33 px-2 my-2">
                                <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                                    {window.I18N(`${name.split('Filter')[0]}`)}
                                </h6>
                                <div className="mt-2 input-group input-group-transparent">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                                        </span>
                                    </div>
                                    <ReactSelect
                                        value={filters[`${name}`]}
                                        options={data}
                                        className="md-form m-0"
                                        placeholder={window.I18N('choose_option')}
                                        onChange={(value) => handleSelectFilter(value, name)}
                                    />
                                </div>
                            </div>
                        )
                    })}
                    {/* Render filter with deps */}
                    {dataFiltersWithDeps.map((item, index) => {
                        const { data, name } = item
                        const convertNameToCamelCase = window.COMMON.toCamelCase(name)
                        return (
                            <div key={index} className="w-33 px-2 my-2">
                                <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                                    {name}
                                </h6>
                                <div className="mt-2 input-group input-group-transparent">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <MDBIcon fa="true" icon="filter" className="fa-fw" />
                                        </span>
                                    </div>
                                    <ReactSelect
                                        value={filtersWithDeps[`${convertNameToCamelCase}`]}
                                        options={data}
                                        className="md-form m-0"
                                        placeholder={window.I18N('choose_option')}
                                        onChange={(value) => handleSelectFilterWithDeps(value, convertNameToCamelCase)}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="d-flex w-100 pb-3 pr-3 align-items-center justify-content-end">
                <MDBBtn color="" className="btn" onClick={resetFilters}>
                    <MDBIcon fa="true" icon="undo" className="fa-fw" /> {window.I18N('refresh_filter')}
                </MDBBtn>
                <MDBBtn color="primary" onClick={() => onFilters(handleModifiedFilters())}>
                    <MDBIcon fa="true" icon="search" className="fa-fw" /> {window.I18N('search')}
                </MDBBtn>
            </div>
        </div>
    )
}

export default React.forwardRef(AssociationFilter)
