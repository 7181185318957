import React, { useRef, useState } from 'react'
import { MDBBtn, MDBCardImage, MDBCol, MDBIcon, MDBRow } from 'mdbreact'

import Button from '../../../../components/Button'
import Dropdown from '../../../../components/Dropdown'
import Modal from '../../../../components/Modal'
import AddKeysForm from './AddKeysForm'
import { FormControl, FormControlLabel, FormGroup, Switch } from '@material-ui/core'

const AssociationConfigsHeader = ({
    keysReportCategory,
    onSelectCategory,
    onResetDataConfig = () => {},
    onGetListKeysReportCategory = () => {}
}) => {
    const [dataKeyForEditOrDelete, setDataKeysForEditOrDelete] = useState('')
    const editKeysFormRef = useRef()

    const handleOpenModalAddKeys = () => {
        window.COMMON.showModal('#add-keys-modal')
    }

    const handleOpenModalEditKeys = (key) => {
        setDataKeysForEditOrDelete(key)
        window.COMMON.showModal('#edit-keys-modal')
    }

    const handleEditReportCategories = () => {
        editKeysFormRef.current.submitForm('edit', () => {
            window.COMMON.showMessage('success', 'MSG_CODE_002', window.I18N('MSG_CODE_002'))
            setDataKeysForEditOrDelete('')
            onGetListKeysReportCategory()
            window.COMMON.hideModal('#edit-keys-modal')
        })
    }

    const handleConfirmEditReportCategories = () => {
        window.COMMON.showModal('#modal-save')
        window.saveMethod = handleEditReportCategories
    }

    const deleteReportCategories = async (foundDeleteKey) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category/delete`,
                method: 'PATCH',
                input: {
                    data: {
                        filter: foundDeleteKey.dataId.split('-')
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationConfigs.handleCheckImportDataConfigs')
                }
            })

            const { status } = result
            if (status === 200) {
                window.COMMON.showMessage('success', 'MSG_CODE_003', window.I18N('MSG_CODE_003'))
                onResetDataConfig()
                setDataKeysForEditOrDelete('')
                onGetListKeysReportCategory(false)
                window.COMMON.hideModal('#modal-delete')
            }
            window.hideLoading()
            return result
        } catch (error) {
            console.error('🚀 ~ deleteReportCategories ~ error:', error)
        }
    }

    const handleUpdateReportCategory = async ({ parentFilter, childFilter }) => {
        const foundActiveKey = keysReportCategory.find((item) => item.active)
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category`,
                method: 'PATCH',
                input: {
                    data: {
                        filter: foundActiveKey.dataId.split('-'),
                        data: [{ isFilter: parentFilter }, { isFilter: childFilter }]
                    }
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AddKeysForm.handleUpdateReportCategory')
                }
            })
            window.hideLoading()
            if (result.status === 200) {
                window.COMMON.showMessage('success', 'MSG_CODE_002', window.I18N('MSG_CODE_002'))
                onResetDataConfig()
                setDataKeysForEditOrDelete('')
                onGetListKeysReportCategory(false)
            }
            return result
        } catch (error) {
            console.error('🚀 ~ handleUpdateReportCategory ~ error:', error)
        }
    }

    const handleDeleteReportCategories = (key) => {
        window.COMMON.showModal('#modal-delete')
        setDataKeysForEditOrDelete(key)
        const foundDeleteKey = keysReportCategory.find((item) => item.dataKey === key)
        window.deleteMethod = () => {
            deleteReportCategories(foundDeleteKey)
        }
    }

    const handleSelectFilter = (e, { oldParentFilter, oldChildFilter }) => {
        const { name, checked: isFilterUpdate } = e.target
        const isUpdateParentFilter = name === 'parent' ? isFilterUpdate : oldParentFilter
        const isUpdateChildFilter = name === 'child' ? isFilterUpdate : oldChildFilter

        window.COMMON.showModal('#modal-confirm')
        window.confirmMethod = () => {
            handleUpdateReportCategory({
                parentFilter: isUpdateParentFilter,
                childFilter: isUpdateChildFilter
            })
        }
    }

    return (
        <>
            <MDBRow>
                <MDBCol className="h-full pr-0" md="4">
                    {/* Dependencies */}
                    <div
                        className="p-3 bg-white rounded-lg wow fadeInLeft"
                        data-wow-delay="0.3s"
                        style={{ minHeight: '100%', height: '100%' }}
                    >
                        <div className="d-flex align-items-center justify-content-between">
                            <h6 className="m-0" style={{ fontSize: '0.9rem', fontWeight: 700 }}>
                                <MDBIcon fa="true" icon="list" className="fa-fw" /> {window.I18N('dependent_reference')}
                            </h6>
                            {/* Add category button */}
                            <div>
                                <MDBBtn
                                    color="primary"
                                    className={`btn-sm m-0`}
                                    onClick={handleOpenModalAddKeys}
                                    style={{ padding: '6px 12px', fontSize: '12px' }}
                                >
                                    <MDBIcon fa="true" icon="plus" className="fa-fw" /> {window.I18N('btn_add')}
                                </MDBBtn>
                            </div>
                        </div>
                        {!window.COMMON.isEmpty(keysReportCategory) ? (
                            <div
                                className="mt-3 p-1 w-100 d-flex flex-column align-items-start justify-content-start gap-2 custom-scrollbar"
                                style={{ maxHeight: '180px', overflowY: 'overlay', overScrollBehavior: 'contain' }}
                            >
                                {keysReportCategory.map((item, index) => (
                                    <div
                                        key={index}
                                        className={`d-flex align-items-center rounded-lg p-2 list-category ${
                                            item.active ? ' category-active' : ''
                                        }`}
                                        style={{ width: '100%', cursor: 'pointer' }}
                                        onClick={() => {
                                            onSelectCategory(index)
                                        }}
                                    >
                                        <div className="w-100 d-flex align-items-center justify-content-between">
                                            <span>{item.name}</span>
                                            <Dropdown
                                                isHover
                                                renderDropdown={
                                                    <div
                                                        className="bg-white rounded-lg d-flex align-items-start justify-content-start flex-column"
                                                        style={{ width: '120px' }}
                                                    >
                                                        <div className="w-100">
                                                            <Button
                                                                className="w-100 m-0 justify-content-start"
                                                                type="button"
                                                                style={{ border: 0 }}
                                                                onClick={() => handleOpenModalEditKeys(item.dataKey)}
                                                            >
                                                                <MDBIcon fa="true" icon="edit" className="fa-fw" />
                                                                <span>Chỉnh sửa</span>
                                                            </Button>
                                                        </div>

                                                        <div className="w-100">
                                                            <Button
                                                                className="w-100 m-0 justify-content-start"
                                                                type="button"
                                                                style={{ border: 0 }}
                                                                onClick={() =>
                                                                    handleDeleteReportCategories(item.dataKey)
                                                                }
                                                            >
                                                                <MDBIcon fa="true" icon="trash" className="fa-fw" />
                                                                <span>Xoá</span>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <div>
                                                    <MDBIcon fa="true" icon="ellipsis-v" className="fa-fw" />
                                                </div>
                                            </Dropdown>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div
                                className="w-100 d-flex align-items-center justify-content-center"
                                style={{ height: '100px' }}
                            >
                                {window.I18N('empty_data')}
                            </div>
                        )}
                    </div>
                </MDBCol>
                {/* Depedencies infomation */}
                <MDBCol className="h-full pr-0" md="4">
                    <div className="h-100 p-3 bg-white rounded-lg wow fadeIn" data-wow-delay="0.3s">
                        <h6 className="m-0 mt-2" style={{ fontSize: '0.9rem', fontWeight: 700 }}>
                            <MDBIcon fa="true" icon="info-circle" className="fa-fw" />{' '}
                            {window.I18N('detail_information')}
                        </h6>
                        {!window.COMMON.isEmpty(keysReportCategory) ? (
                            <div
                                className="w-100 mt-2 d-flex custom-scrollbar"
                                style={{ maxHeight: '180px', overflowY: 'overlay', overScrollBehavior: 'contain' }}
                            >
                                {keysReportCategory
                                    .filter((key) => key.active)
                                    .map((item, index) => {
                                        const [parentName, childName] = item.name.split('-')
                                        return (
                                            <div className="w-100" key={index}>
                                                <div className="w-100 p-3">
                                                    <div className="w-100 m-0 d-flex align-items-center justify-content-start gap-2">
                                                        <div className="w-50 d-flex align-items-center gap-1">
                                                            <MDBIcon
                                                                fa="true"
                                                                icon="angle-double-right"
                                                                className="fa-fw"
                                                            />
                                                            {window.I18N('dependent_field')}
                                                        </div>
                                                        <div className="w-50">
                                                            <span className="font-weight-bold">{parentName}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-100 p-3">
                                                    <div className="w-100 m-0 d-flex align-items-center justify-content-start gap-2">
                                                        <div className="w-50 d-flex align-items-center gap-1">
                                                            <MDBIcon
                                                                fa="true"
                                                                icon="angle-double-right"
                                                                className="fa-fw"
                                                            />
                                                            {window.I18N('reference_field')}
                                                        </div>
                                                        <div className="w-50">
                                                            <span className="font-weight-bold">{childName}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="w-100 p-3">
                                                    <div className="w-100 m-0 d-flex align-items-center justify-content-start gap-2">
                                                        <div className="w-50 d-flex align-items-center gap-1">
                                                            <MDBIcon
                                                                fa="true"
                                                                icon="angle-double-right"
                                                                className="fa-fw"
                                                            />
                                                            {window.I18N('reference_to_field')}
                                                        </div>
                                                        <div className="w-50">
                                                            <span className="font-weight-bold">
                                                                {item.refReportFieldName}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* createdAt */}
                                                <div className="w-100 p-3">
                                                    <div className="w-100 m-0 d-flex align-items-center justify-content-start gap-2">
                                                        <div className="w-50 d-flex align-items-center gap-1">
                                                            <MDBIcon
                                                                fa="true"
                                                                icon="angle-double-right"
                                                                className="fa-fw"
                                                            />
                                                            {window.I18N('created_date')}
                                                        </div>
                                                        <div className="w-50 d-flex align-items-center justify-content-start gap-1">
                                                            <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                                            <span className="font-weight-bold">
                                                                {window.COMMON_DATE.getFormatDate(
                                                                    item.createdAt,
                                                                    'DD/MM/YYYY - HH:mm'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Creator info */}
                                                <div className="w-100 p-3">
                                                    <div className="w-100 m-0 d-flex align-items-center justify-content-start gap-2">
                                                        <div className="w-50 d-flex align-items-center gap-1">
                                                            <MDBIcon
                                                                fa="true"
                                                                icon="angle-double-right"
                                                                className="fa-fw"
                                                            />
                                                            {window.I18N('created_by')}
                                                        </div>
                                                        <div className="w-50">
                                                            <div className="d-flex align-items-center justify-content-start gap-2">
                                                                <MDBCardImage
                                                                    zoom
                                                                    cascade
                                                                    waves
                                                                    src={item.createdBy.avatar}
                                                                    className="m-0 image-icon rounded-circle"
                                                                />
                                                                <span className="font-weight-bold">
                                                                    {item.createdBy.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* createdAt */}
                                                <div className="w-100 p-3">
                                                    <div className="w-100 m-0 d-flex align-items-center justify-content-start gap-2">
                                                        <div className="w-50 d-flex align-items-center gap-1">
                                                            <MDBIcon
                                                                fa="true"
                                                                icon="angle-double-right"
                                                                className="fa-fw"
                                                            />
                                                            {window.I18N('updated_date')}
                                                        </div>
                                                        <div className="w-50 d-flex align-items-center justify-content-start gap-1">
                                                            <MDBIcon fa="true" icon="calendar-alt" className="fa-fw" />
                                                            <span className="font-weight-bold">
                                                                {window.COMMON_DATE.getFormatDate(
                                                                    item.updatedAt,
                                                                    'DD/MM/YYYY - HH:mm'
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Updated By */}
                                                <div className="w-100 p-3">
                                                    <div className="w-100 m-0 d-flex align-items-center justify-content-start gap-2">
                                                        <div className="w-50 d-flex align-items-center gap-1">
                                                            <MDBIcon
                                                                fa="true"
                                                                icon="angle-double-right"
                                                                className="fa-fw"
                                                            />
                                                            {window.I18N('updated_by')}
                                                        </div>
                                                        <div className="w-50">
                                                            <div className="d-flex align-items-center justify-content-start gap-2">
                                                                <MDBCardImage
                                                                    zoom
                                                                    cascade
                                                                    waves
                                                                    src={item.updatedBy.avatar}
                                                                    className="m-0 image-icon rounded-circle"
                                                                />
                                                                <span className="font-weight-bold">
                                                                    {item.updatedBy.name}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        ) : (
                            <div
                                className="w-100 d-flex align-items-center justify-content-center"
                                style={{ height: '100px' }}
                            >
                                {window.I18N('empty_data')}
                            </div>
                        )}
                    </div>
                </MDBCol>
                {/* Dependencies filter */}
                <MDBCol className="h-full" md="4">
                    <div className="h-100 p-3 bg-white rounded-lg wow fadeInRight" data-wow-delay="0.3s">
                        <h6 className="m-0 mt-2" style={{ fontSize: '0.9rem', fontWeight: 700 }}>
                            <MDBIcon fa="true" icon="filter" className="fa-fw" /> {window.I18N('filter')}
                        </h6>
                        {!window.COMMON.isEmpty(keysReportCategory) ? (
                            <div className="mt-3">
                                {keysReportCategory
                                    .filter((key) => key.active)
                                    .map((item, index) => {
                                        const [parentName, childName] = item.name.split('-')
                                        return (
                                            <div
                                                className="w-100 d-flex align-items-center flex-column gap-y-2"
                                                key={index}
                                            >
                                                <div className="w-100 p-3 m-0 d-flex align-items-center justify-content-between gap-2">
                                                    <div className="w-75 d-flex align-items-center gap-1">
                                                        <MDBIcon
                                                            fa="true"
                                                            icon="angle-double-right"
                                                            className="fa-fw"
                                                        />
                                                        <span>{parentName}</span>
                                                    </div>
                                                    <div className="w-50 d-flex align-items-center justify-content-end">
                                                        <div className="w-50 d-flex align-items-center justify-content-end">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <FormControl component="fieldset">
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            className="m-0"
                                                                            control={
                                                                                <Switch
                                                                                    color="primary"
                                                                                    checked={item.isParentFilter}
                                                                                    name={'parent'}
                                                                                    onChange={(e) =>
                                                                                        handleSelectFilter(e, {
                                                                                            oldParentFilter:
                                                                                                item.isParentFilter,
                                                                                            oldChildFilter:
                                                                                                item.isChildFilter
                                                                                        })
                                                                                    }
                                                                                />
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </FormControl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="w-100 p-3 m-0 d-flex align-items-center justify-content-between gap-2">
                                                    <div className="w-75 d-flex align-items-center gap-1">
                                                        <MDBIcon
                                                            fa="true"
                                                            icon="angle-double-right"
                                                            className="fa-fw"
                                                        />
                                                        <span>{childName}</span>
                                                    </div>
                                                    <div className="w-50 d-flex align-items-center justify-content-end">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <FormControl component="fieldset">
                                                                <FormGroup>
                                                                    <FormControlLabel
                                                                        className="m-0"
                                                                        control={
                                                                            <Switch
                                                                                color="primary"
                                                                                checked={item.isChildFilter}
                                                                                name={'child'}
                                                                                onChange={(e) =>
                                                                                    handleSelectFilter(e, {
                                                                                        oldParentFilter:
                                                                                            item.isParentFilter,
                                                                                        oldChildFilter:
                                                                                            item.isChildFilter
                                                                                    })
                                                                                }
                                                                            />
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        ) : (
                            <div
                                className="w-100 d-flex align-items-center justify-content-center"
                                style={{ height: '100px' }}
                            >
                                {window.I18N('empty_data')}
                            </div>
                        )}
                    </div>
                </MDBCol>
            </MDBRow>
            <Modal
                saveBtnProps={{
                    style: {
                        width: '100%'
                    },
                    outline: false
                }}
                title={window.I18N('update_data')}
                id="edit-keys-modal"
                classModalContent="height-90"
                saveEvent={handleConfirmEditReportCategories}
            >
                <AddKeysForm
                    isEdit={true}
                    editKey={dataKeyForEditOrDelete}
                    keysReportCategory={keysReportCategory}
                    ref={editKeysFormRef}
                />
            </Modal>
        </>
    )
}

export default AssociationConfigsHeader
