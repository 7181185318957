import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact'
import React, { useImperativeHandle, useState } from 'react'
import { Chip } from '@material-ui/core'

const AssociationAddDepsForm = ({ keysReportCategory, checkDataDB = () => {} }, ref) => {
    const [dataForm, setDataForm] = useState({
        dependentCode: '',
        refCode: ''
    })

    const [listRefCode, setListRefCode] = useState([])

    const handleChangeCode = (e) => {
        const { name, value } = e.target
        switch (name) {
            case 'dependentCode':
                setDataForm((prev) => ({ ...prev, dependentCode: value }))
                break
            case 'refCode':
                setDataForm((prev) => ({ ...prev, refCode: value }))
                break
            default:
                break
        }
    }

    const handleAddListRefCode = () => {
        if (window.COMMON.isEmpty(dataForm.refCode)) {
            window.COMMON.showMessage('warning', 'MSG_CODE_100', window.I18N('MSG_CODE_100'))
            return
        }
        const foundDuplicateRefCode = listRefCode.find(({ code: refCode }) => refCode === dataForm.refCode)
        if (foundDuplicateRefCode) {
            window.COMMON.showMessage('warning', 'MSG_CODE_082', window.I18N('MSG_CODE_082'))
            return
        }
        setListRefCode((prev) => [...prev, { isExisted: false, code: dataForm.refCode }])
        setDataForm((prev) => ({ ...prev, refCode: '' }))
    }

    const handleAddListRefCodeWithEnterKey = (event) => {
        if (event.key === 'Enter') {
            handleAddListRefCode()
        }
    }

    const handleDeleteRefCodeInList = (codeForDelete) => {
        setListRefCode((prev) => prev.filter((item) => item.code !== codeForDelete))
    }

    const handleAddDataConfigs = async (data) => {
        try {
            window.showLoading()
            const result = await window.COMMON.httpRequest({
                url: `${process.env.REACT_APP_FREIGHT_FORWARDING_API_URL}/report-category-deps`,
                method: 'POST',
                input: {
                    data
                },
                requireToken: true,
                handleError: () => {
                    window.COMMON.showErrorLogs('AssociationConfigs.handleImportDataConfigs')
                }
            })

            window.hideLoading()
            return result
        } catch (error) {
            console.error('🚀 ~ handleAddDataConfigs ~ error:', error)
        }
    }

    const getDataForm = () => {
        const { dependentCode } = dataForm
        const [parentKeyId, childrenKeyId] = keysReportCategory.find((item) => item.active)?.dataId.split('-')
        const data = {
            code: dependentCode,
            category: parentKeyId,
            children: window.COMMON.removeDuplicateElements(listRefCode).map(({ code: refCode }) => ({
                code: window.COMMON.formatTitleCase(refCode),
                category: childrenKeyId
            }))
        }
        return data
    }

    const handleCheckDataForAdd = async () => {
        const category = keysReportCategory.find((item) => item.active)?.dataId.split('-')
        const { dependentCode } = dataForm
        const dataForCheck = listRefCode.map(({ code: refCode }) => ({
            isExisted: false,
            key: dependentCode,
            data: refCode,
            category
        }))
        const { metadata: dataChecked, status } = await checkDataDB({
            dataImport: dataForCheck
        })
        if (status === 200) {
            setListRefCode(dataChecked.map((item) => ({ isExisted: item.isExisted, code: item.data })))
            //filter isExisted true if empty array -> data is ok
            return window.COMMON.isEmpty(dataChecked.filter((data) => data.isExisted === true))
        }
        return false
    }

    const resetForm = () => {
        setDataForm({ dependentCode: '', refCode: '' })
        setListRefCode([])
    }

    useImperativeHandle(ref, () => ({
        getData: getDataForm,
        resetForm,
        validForResetForm: () => {
            const { dependentCode } = dataForm
            const hasData = !window.COMMON.isEmpty(dependentCode) || !window.COMMON.isEmpty(listRefCode)
            return hasData
        },
        validData: (isNoti = true) => {
            const { dependentCode } = dataForm
            const isDataEmpty = window.COMMON.isEmpty(dependentCode) || window.COMMON.isEmpty(listRefCode)
            if (isDataEmpty) {
                isNoti && window.COMMON.showMessage('warning', 'MSG_CODE_010', window.I18N('MSG_CODE_010'))
                return false
            }
            return true
        },
        submitForm: async (callback = () => {}) => {
            const isValidData = await handleCheckDataForAdd()
            if (!isValidData) {
                window.COMMON.showMessage('warning', 'MSG_CODE_007', window.I18N('MSG_CODE_007'))
                return
            }
            const dataSet = getDataForm()
            const result = await handleAddDataConfigs(dataSet)
            if (result.status === 201) {
                window.COMMON.showMessage('success', 'MSG_CODE_001', window.I18N('MSG_CODE_001'))
                resetForm()
                callback()
            }
        }
    }))

    return (
        <div>
            <div className="w-full mt-2">
                <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                    {`${keysReportCategory.find((item) => item.active)?.name.split('-')[0]} *`}
                </h6>
                <MDBInput
                    style={{ margin: 0 }}
                    outline
                    type="text"
                    name="dependentCode"
                    containerClass="w-full m-0 mt-2"
                    value={dataForm.dependentCode}
                    onChange={handleChangeCode}
                    maxLength="100"
                    pattern="\S(.*\S)?"
                    required
                />
            </div>

            <div className="w-full mt-3">
                <h6 className="m-0" style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                    {`${keysReportCategory.find((item) => item.active)?.name.split('-')[1]} *`}
                </h6>
                <div className="mt-2 d-flex align-items-center justify-content-center" style={{ gap: '4px' }}>
                    <MDBInput
                        style={{ margin: 0 }}
                        outline
                        type="text"
                        name="refCode"
                        containerClass="w-full m-0"
                        value={dataForm.refCode}
                        onChange={handleChangeCode}
                        onKeyDown={handleAddListRefCodeWithEnterKey}
                        maxLength="100"
                        pattern="\S(.*\S)?"
                        required
                    />
                    <div>
                        <MDBBtn
                            color="primary"
                            className={`btn-sm m-0`}
                            onClick={handleAddListRefCode}
                            style={{ padding: '10px 12px', fontSize: '12px', width: '80px' }}
                        >
                            <MDBIcon fa="true" icon="plus" className="fa-fw" /> {window.I18N('add')}
                        </MDBBtn>
                    </div>
                </div>
            </div>

            {!window.COMMON.isEmpty(listRefCode) && (
                <div className="mt-2 p-2 w-full border rounded-lg">
                    <div
                        className="d-flex align-items-center justify-content-start"
                        style={{ flexWrap: 'wrap', gap: '6px' }}
                    >
                        {listRefCode.map((item, index) => (
                            <Chip
                                key={index}
                                label={item.code}
                                variant="outlined"
                                color={item.isExisted ? 'secondary' : 'primary'}
                                onDelete={() => handleDeleteRefCodeInList(item.code)}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default React.forwardRef(AssociationAddDepsForm)
